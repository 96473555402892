<section class="content">
	<div class="row">
		<div class="col-md-12">
			<div class="panel panel-info">
				<div class="panel-heading">
					Call Details
				</div>
				<div class="panel-body">
					<div class="row">
						<div class="col-md-12" *ngIf="!fetchingDetails && data">
							<table class="table table-bordered">
								<tbody>
									<tr>
										<th style="width: 15%;">Status</th>
										<td style="width: 85%;">
											{{data?.summary_status || "-"}}
										</td>
									</tr>
									<tr>
										<th>Summary</th>
										<td>
											{{data?.summary || "-"}}
										</td>
									</tr>
									<tr>
										<th>Case Id</th>
										<td>
											<ng-container *ngFor="let case of data.connected_event">
												<div class="case-badge">
													<div class="left">
														<a matTooltip="Case Details"
															[routerLink]="['/app/eventDetails',case.id]">
															{{case.event_id}}
														</a>
													</div>
													<div class="right">
														<i class="fa fa-unlink pull-right" matTooltip="Unlink"
															(click)="unlinkCase(case.id,data.id)"></i>
													</div>
												</div>
											</ng-container>
										</td>
									</tr>
									<tr>
										<th>Direction</th>
										<td>
											<img [src]="data.direction=='inbound'?'../assets/img/incoming-call.png':'../assets/img/outgoing-call.png'"
												matTooltip="{{data.direction=='inbound'?'Incoming':'Outgoing'}}"
												class="call-image" alt="Call Image" />
										</td>
									</tr>
									<tr>
										<th>From</th>
										<td>
											<ng-container *ngIf="data.direction=='inbound'">
												<span *ngIf="!data.vessel_name">
													{{data.customer_no_with_prefix}}{{data.caller_id_number}}
												</span>
												<span *ngIf="data.vessel_name"
													matTooltip="{{data.customer_no_with_prefix}}{{data.caller_id_number}}">
													{{data.vessel_name}}
												</span>
											</ng-container>
											<ng-container *ngIf="data.direction!='inbound'">
												<span>
													{{data.customer_no_with_prefix}}{{data.caller_id_number}}
												</span>
											</ng-container>
										</td>
									</tr>
									<tr>
										<th>To</th>
										<td>
											<ng-container *ngIf="data.direction=='inbound'">
												<span>
													{{data.call_to_number}}
												</span>
											</ng-container>
											<ng-container *ngIf="data.direction!='inbound'">
												<span *ngIf="!data.to_vessel_name">
													{{data.call_to_number}}
												</span>
												<span *ngIf="data.to_vessel_name" matTooltip="{{data.call_to_number}}">
													{{data.to_vessel_name}}
												</span>
											</ng-container>
										</td>
									</tr>
									<tr>
										<th>Date Time</th>
										<td>
											{{data.start_stamp}}
										</td>
									</tr>
									<tr>
										<th>Results</th>
										<td>
											<p *ngIf="data.call_type=='call_answered_by_agent' || data.call_type=='call_hangup_by_agent_click_to_call'"
												class="text-success mb-0">
												Call&nbsp;
												{{data.call_type=='call_answered_by_agent'?'answered':'triggered'}}
												&nbsp;by&nbsp;
												<ng-container *ngIf="data.answered_agent">
													{{splitAgentName(data.answered_agent.name)}}
												</ng-container>
											</p>
											<p *ngIf="data.call_type=='call_missed_by_agent'" class="text-danger mb-0">
												Missed call
											</p>
											<p *ngIf="data.call_type!='call_answered_by_agent' && data.call_type!='call_hangup_by_agent_click_to_call' && data.call_type!='call_missed_by_agent'"
												class="mb-0">
												{{data.call_type}}
											</p>
										</td>
									</tr>
									<tr>
										<th>Action</th>
										<td>
											<a *ngIf="data.recording_url" href="{{data.recording_url}}"
												class="btn btn-xs btn-info ml-sm" target="_blank"
												matTooltip="Recording">
												<i class="fa fa-microphone"></i>
											</a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-md-12" *ngIf="fetchingDetails">
							<i class="fa fa-spinner fa-spin"></i>
							Getting details
						</div>
					</div>
					<div class="row mt-lg">
						<div class="col-md-12">
							<a class="btn btn-sm btn-primary ml-sm" [routerLink]="['/app/eventList']">Home</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>