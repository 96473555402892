import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../api.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { concat, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, map, filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
declare var $: any;

@Component({
	selector: 'app-billing-create',
	templateUrl: './billing-create.component.html',
	styleUrl: './billing-create.component.css'
})
export class BillingCreateComponent implements OnInit, AfterViewInit, OnDestroy {
	breadcrumbList = [
		{
			path: "/app/eventList",
			name: "Home"
		},
		{
			path: "/app/billing",
			name: "Invoice"
		}
	];
	pagetitle = "Create Invoice";
	moduleForm!: FormGroup;
	showLoader = false;
	uId = null;
	customError: any = {};
	details: any = null;
	moduleAccess = JSON.parse(localStorage.getItem("userAccess")!).Invoice;

	compInput$ = new Subject<string>();
	companyListObs$!: Observable<any>;
	compLoading = false;
	minLengthTerm = 0;

	fleetInput$ = new Subject<string>();
	fleetListObs$!: Observable<any>;
	fleetLoading = false;
	minLengthTermFleet = 0;

	currencyList: any = [];
	checkAll = false;
	selectedVessels: any = [];
	savedVessels: any = [];
	vessellist: any = [];
	isListFinished: boolean = false;
	isLoading: boolean = false;
	tableparams: any = {
		page: 0,
		limit: 1000
	};
	owners: any = [];
	subscriptionStatusList: any = ["Subscribed", "Adhoc", "Out of Management"];
	formCtrlSub!: Subscription;
	searchControl = new FormControl("");

	constructor(private http: HttpClient, private activeRoute: ActivatedRoute, private formBuilder: FormBuilder, private toastr: ToastrService, private apiService: ApiService, private router: Router) { }

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.uId = routeParams["uId"] == undefined ? null : routeParams["uId"];
			this.moduleForm = this.formBuilder.group({
				company_id_id: [null, [Validators.required]],
				fleet: [null],
				owner: [null],
				/* Subscription_Date: [null], */
				Subscription_Status: [null],
				amount: [null, [Validators.required, Validators.pattern(/^[+-]?([0-9]*[.])?[0-9]+$/)]],
				invoice_no: [null, [Validators.required]],
				invoice_date: [null, [Validators.required]],
				fromDate: [null, [Validators.required]],
				toDate: [null, [Validators.required]],
				total_months: [null, [Validators.required, Validators.pattern(/^[+-]?([0-9]*[.])?[0-9]+$/)]],
				due_date: [null, [Validators.required]],
				Currency: [null, [Validators.required]],
				no_of_vessels: [null]
			});
			this.companyList();
			this.fleetList();
			this.ownerList();
			this.getCurrencyList();
			if (this.uId != null) {
				this.getDetails();
				this.breadcrumbList.push({ path: "/app/edit-bill/" + this.uId, name: "Edit" });
				this.pagetitle = "Edit Invoice";
			} else {
				this.getVesselList(false);
				this.breadcrumbList.push({ path: "/app/create-bill", name: "Create" });
			}
		});
	}

	ngAfterViewInit(): void {
		this.formCtrlSub = this.searchControl.valueChanges.pipe(debounceTime(1000)).subscribe(newValue => {
			this.getVessels();
		});
	}

	ngOnDestroy() {
		this.formCtrlSub.unsubscribe();
	}

	get f() { return this.moduleForm.controls; }

	getDetails() {
		this.apiService.getBillingDetails(this.uId).subscribe(data => {
			this.details = data;
			this.f.amount.setValue(this.details.Amount);
			this.f.Currency.setValue(this.details.Currency);
			this.f.no_of_vessels.setValue(this.details.no_of_vessels);
			this.f.invoice_no.setValue(this.details.Invoice_no);
			this.f.company_id_id.setValue(this.details.company_id ? { id: this.details.company_id, company_name: this.details.CompanyName } : null);
			this.f.invoice_date.setValue(this.getDateFromDateString(this.details.Invoice_date));
			this.f.fromDate.setValue(this.getDateFromDateString(this.details.fromDate));
			this.f.toDate.setValue(this.getDateFromDateString(this.details.toDate));
			this.f.total_months.setValue(this.details.total_months);
			this.f.due_date.setValue(this.getDateFromDateString(this.details.due_date));
			try {
				this.savedVessels = [];
				this.selectedVessels = [];
				for (let i = 0; i < this.details.data.length; i++) {
					this.savedVessels.push(this.details.data[i]);
					this.selectedVessels.push(this.details.data[i].id);
				}
			} catch (error) { }
			this.getVesselList(false);
			this.showHideNoOfVessel();
		}, (error) => {
			this.toastr.error("Something went wrong", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	invoiceDateChanged(){
		let date = this.f.invoice_date.value;
		var newDate = new Date(date.setMonth(date.getMonth() + 1));
		this.f.due_date.setValue(newDate);
	}

	calculateMonths(){
		let d1 = this.f.fromDate.value;
		let d2 = this.f.toDate.value;
		if(d1 && d2){
			let  months:any;
			months = (d2.getFullYear() - d1.getFullYear()) * 12;
			months -= d1.getMonth();
			months += d2.getMonth();
			months <= 0 ? 0 : months;
			months += 1;
			this.f.total_months.setValue(months);
		}
	}

	showNoOfVessels = false;
	showHideNoOfVessel(){
		if(this.f.company_id_id.value && (this.f.company_id_id.value.company_name == "3 Cube" || this.f.company_id_id.value.company_name == "3 Cube Medicare Pte. Ltd.")){
			this.f.no_of_vessels.setValidators([Validators.required,Validators.pattern(/^[+-]?([0-9]*[.])?[0-9]+$/)]);
			this.f.no_of_vessels.updateValueAndValidity();
			this.showNoOfVessels = true;
		}else{
			this.f.no_of_vessels.setValue(null);
			this.f.no_of_vessels.setValidators(null);
			this.f.no_of_vessels.updateValueAndValidity();
			this.showNoOfVessels = false;
		}
	}

	ownerList() {
		this.apiService.ownerList().subscribe(data => {
			this.owners = data;
			this.owners = this.owners.slice(0);
		}, (error) => { });
	}

	companyList() {
		this.companyListObs$ = concat(of([]), this.compInput$.pipe(filter(res => {
			return true;
		}), distinctUntilChanged(), debounceTime(500), tap(() => this.compLoading = true), switchMap(term => {
			let payload: any = { page: 0, limit: 200 };
			if (term) {
				payload["search"] = term;
			}
			return this.apiService.mainCompanyList(payload).pipe(catchError(() => of([])), tap((res: any) => {
				this.compLoading = false;
			}))
		})));
	}

	trackByFn(item: any) {
		return item.id;
	}

	fleetList() {
		this.fleetListObs$ = concat(of([]), this.fleetInput$.pipe(filter(res => {
			return true;
		}), distinctUntilChanged(), debounceTime(500), tap(() => this.fleetLoading = true), switchMap(term => {
			let payload: any = { page: 0, limit: 200 };
			if (term) {
				payload["search"] = term;
			}
			if (this.f.company_id_id.value) {
				payload["company_id"] = this.f.company_id_id.value.id;
			}
			return this.apiService.fleetListByCompany(payload).pipe(catchError(() => of([])), tap((res: any) => {
				this.fleetLoading = false;
			}))
		})));
	}

	trackByFnFleet(item: any) {
		return item.companyId;
	}

	companyChanged() {
		this.getVessels();
	}

	fleetChanged() {
		this.getVessels();
	}

	ownerChanged() {
		this.getVessels();
	}

	subsDateChanged() {
		this.getVessels();
	}

	subsStatusChanged() {
		this.getVessels();
	}

	getVessels() {
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.vessellist = [];
		this.getVesselList(true);
	}

	getVesselList(isSearch) {
		if (!this.isListFinished && !this.isLoading) {
			let params: any = {
				limit: this.tableparams.limit,
				page: this.tableparams.page
			}
			if (this.f.company_id_id.value) {
				params["company"] = this.f.company_id_id.value.id;
			}
			if (this.f.fleet.value) {
				params["fleet"] = this.f.fleet.value.id;
			}
			if (this.f.owner.value) {
				params["owner"] = this.f.owner.value;
			}
			/* if (this.f.Subscription_Date.value) {
				params["sub_date"] = this.GetDateStringFromPickerDate(this.f.Subscription_Date.value);
			} */
			if (this.f.Subscription_Status.value) {
				params["sub_status"] = this.f.Subscription_Status.value;
			}
			if (this.searchControl.value) {
				params["search"] = this.searchControl.value;
			}
			this.isLoading = true;
			this.apiService.get_vesseldata_by_company(params).subscribe(data => {
				this.isLoading = false;
				let vessels: any = data;
				for (let i = 0; i < vessels.length; i++) {
					if (this.selectedVessels.indexOf(vessels[i].id) >= 0) {
						vessels[i].checked = true;
					}
				}
				if (vessels.length == this.tableparams.limit) {
					this.tableparams.page += 1;
				} else {
					this.isListFinished = true;
				}
				if (isSearch) {
					this.vessellist = [];
				}
				this.vessellist = this.vessellist.concat(vessels);
			}, (error) => {
				this.isLoading = false;
				/* this.isListFinished = true; */
			});
		}
	}

	fixDate(date: any) {
		if (date == "" || date == null || date == undefined) {
			return null;
		}
		try {
			return date.split("T")[0].split("-").reverse().join("-");
		} catch (error) {
			return null;
		}
	}

	GetDateStringFromPickerDate(date) {
		if (date == "" || date == null || date == undefined) {
			return "";
		}
		var dateStr = date.toString();
		var dateObj = new Date(dateStr);
		var month = dateObj.getMonth() + 1;
		var day = dateObj.getDate();
		var year = dateObj.getFullYear();
		return year + "-" + month + "-" + day;
	}

	getCurrencyList() {
		this.apiService.currencyList().subscribe((data: any) => {
			this.currencyList = data;
		}, (error) => {
			this.currencyList = [];
			if (error.status == 401) { return }
		});
	}

	getDateFromDateString(date: any) {
		try {
			if (date != null && date !== "") {
				date = new Date(date.split("-").reverse().join("/"));
			} else {
				date = null;
			}
		} catch (error) {
			date = null;
		}
		return date;
	}

	checkUncheckAll(checked: any) {
		let len = this.vessellist.length;
		for (let i = 0; i < len; i++) {
			this.vessellist[i].checked = checked;
			this.vesselSelected(this.vessellist[i].id, checked);
		}
	}


	vesselSelected(id: any, checked: any) {
		if (checked) {
			this.selectedVessels.push(id);
		} else {
			this.selectedVessels.splice(this.selectedVessels.indexOf(id), 1);
		}
		this.selectedVessels = this.selectedVessels.filter((n: any, i: any) => this.selectedVessels.indexOf(n) === i);
		let len = this.vessellist.length;
		let checkAll = true;
		for (let i = 0; i < len; i++) {
			if (!this.vessellist[i].checked) {
				checkAll = false;
			}
		}
		this.checkAll = checkAll;
		this.moduleForm.markAsDirty();
	}

	clearCustomError() {
		Object.keys(this.f).forEach(key => {
			if (this.f[key].hasError('incorrect')) {
				try {
					delete this.f[key].errors?.incorrect;
					this.f[key].updateValueAndValidity();
				} catch (error) {

				}
			}
		});
		this.customError = {};
	}

	saveRecord() {
		this.clearCustomError();
		if (this.moduleForm.invalid) {
			this.toastr.error("Check All Feilds", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		if (!this.showNoOfVessels && this.selectedVessels.length == 0) {
			this.toastr.error("No vessel selected", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		this.showLoader = true;
		let data: any = {
			amount: this.f.amount.value,
			total_months: this.f.total_months.value,
			company_id_id: this.f.company_id_id.value ? this.f.company_id_id.value.id : null,
			invoice_no: this.f.invoice_no.value,
			invoice_date: this.getDateStringFromDate(this.f.invoice_date.value),
			fromDate: this.getDateStringFromDate(this.f.fromDate.value),
			toDate: this.getDateStringFromDate(this.f.toDate.value),
			due_date: this.getDateStringFromDate(this.f.due_date.value),
			vessel_list: this.selectedVessels,
			Currency: this.f.Currency.value,
			no_of_vessels: this.f.no_of_vessels.value
		}
		if (this.uId) {
			data["id"] = this.uId;
		}
		this.apiService.createUpdateBilling(data).subscribe(data => {
			this.showLoader = false;
			if (this.uId) {
				this.toastr.info("Invoice updated successfully.", 'Success!!!', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
			} else {
				this.toastr.info("New invoice added successfully.", 'Success!!!', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
			this.router.navigate(['/app/billing']);
		}, (error) => {
			this.showLoader = false;
			this.handleAPIError(error);
		});
	}

	getDateStringFromDate(date: any) {
		if (date == "" || date == null || date == undefined) {
			return null;
		}
		var month = date.getMonth() + 1;
		var day = date.getDate();
		var year = date.getFullYear();
		var dayStr = day < 10 ? '0' + day : day;
		var monthStr = month < 10 ? '0' + month : month;
		var fromStr = dayStr + "-" + monthStr + "-" + year;
		return fromStr;
	}

	handleAPIError(error: any) {
		let errorMsg = "Something went wrong.";
		if (error.status == 404 || error.status == 400) {
			errorMsg = error.error;
			if (typeof errorMsg == 'object') {
				for (const [key, value] of Object.entries(errorMsg)) {
					let val: any = value;
					if (this.moduleForm.controls[key]) {
						this.moduleForm.controls[key].setErrors({ incorrect: true });
						this.customError[key] = val;
						let selector = "#" + key;
						try {
							$('html, body').animate({
								scrollTop: $(selector).offset().top - 90 + 'px'
							}, 'fast');
						} catch (error) { }
						$(selector).focus();
					} else {
						this.toastr.error(val, 'Error', {
							timeOut: 3000,
							tapToDismiss: true,
							closeButton: true
						});
					}
				}
				return;
			}
		}
		this.toastr.error(errorMsg, 'Error', {
			timeOut: 3000,
			tapToDismiss: true,
			closeButton: true
		});
	}
}