import {Subscription, ReplaySubject, Subject, Observable, fromEvent, of} from 'rxjs';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  Inject,
  ElementRef,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  IterableDiffers,
  DoCheck,
  NgZone
} from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {Location} from '@angular/common';
import {ApiService} from '../api.service';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  AsyncValidatorFn
} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {ToastrService} from 'ngx-toastr';
import {FileQueueObject, FileuploadService} from '../fileupload.service';
import {timer} from 'rxjs';
import * as _ from 'lodash';

declare var $: any;
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter,
  take,
  takeUntil,
  switchMap,
  catchError,
  startWith,
  tap,
  finalize
} from "rxjs/operators";

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrl: './add-event.component.css'
})
export class AddEventComponent implements OnInit, OnDestroy {
  breadcrumbList = [
    {
      path: "/app/eventList",
      name: "Home"
    },
    {
      path: "/app/generateEvent",
      name: "Generate Event"
    }
  ];
  eventForm!: FormGroup;
  showLoader = false;
  candidate_disabled = false;
  devicesList: any = [];
  portList: any = [];
  filteredPortList!: Observable<any[]>;
  portListClosest: any = [];
  filteredPortListClosest!: Observable<any[]>;
  maxDate:any;
  ranklist: any = [];
  nationalityList: any = [];
  filteredNationality:any = [];
  mailTypeList = [{id: 1, eventType: 'With Symptoms'}, {id: 2, eventType: 'Without Symptoms'}];
  event_type:any = 1;
  groupList: any = [];
  selectedGroups: any = [];
  selectedGroupsQuestions: any = [];
  questionsList: any = [];
  selectedSymptoms: any = [];
  selectedMeds:any = {};
  differ: any;
  LoadingGIF = false;
  loadNext = false;
  moduleAccess:any;
  questionaaire = false;
  class126 = "col-sm-6"; // col-sm-12 to col-sm-6
  class123 = "col-sm-3";
  class312 = "col-sm-12";
  class612 = "col-sm-12";
  class412 = "col-sm-12";
  disablecheckboxes = false;
  spotsconfiga:any;
  spotsconfigb:any;
  pinobj:any = {};
  selectedPoints:any = [];
  selectedPointsValue:any = {};
  /* body = {msg: null}; */
  vesselDetails: any = {};
  candidateDetails: any = {};

  vitals:any = {
    /*spo2:null,
    pulse:null,
    sysbp:null,
    diabp:null,
    glucose:null,
    weight:null,
    temp:null*/
  };
  DeviceviseData = false;
  addmedicaldata = false;
  viewmedicaldata = true;
  AddDataInputs = false;
  MedicalDeviceData: any = [];
  SaveDeviceId:any = "";
  public tools: object = {
		items: [
		'Bold', 'Italic', 'Underline', 'StrikeThrough', 'CreateLink', '|',
		'UnorderedList', 'OrderedList', '|',
		'Indent', 'Outdent', '|',
		'Undo', 'Redo', '|',
		'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
		'CreateTable',
		'LowerCase', 'UpperCase', '|',
		'Formats', 'Alignments', '|',
		'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
	};

  transactionId:any = null;
  candidateId:any = null;
  vesselId:any = null;

  candidateDetails1: any = {};
  doj_disable = false;
  rank_disabled = false;
  nationality_disabled = false;
  isLoading = false;
  customError:any = {};

  constructor(
    private toastr: ToastrService,
    private apiService: ApiService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private location: Location,
    public uploader: FileuploadService,
    private formBuilder: FormBuilder,
    private zone: NgZone,
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() - 1);
  }
  ngOnDestroy(): void {
    $('[id*="richtexteditor"]').hide();;
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(queryParams => {
    });
    this.activeRoute.params.subscribe(routeParams => {
      this.transactionId = routeParams["transactionId"] == undefined || routeParams["transactionId"] == "empty" ? null : routeParams["transactionId"];
      this.candidateId = routeParams["candidateId"] == undefined || routeParams["candidateId"] == "empty" ? null : routeParams["candidateId"];
      this.vesselId = routeParams["vesselId"] == undefined || routeParams["vesselId"] == "empty" ? null : routeParams["vesselId"];
      this.moduleAccess = JSON.parse(localStorage.getItem("userAccess")!).Generate_event;
      this.eventForm = this.formBuilder.group({
        imoNo: [null, [], this.fetchVesselFromImono()],
        vesselName: [null, [Validators.required]],
        callSign: [null],
        /* captainName: [null, [Validators.pattern(/^[a-z A-Z]*$/)]], */
        arrivalPort: [null],
        etaNextPort: [null],
        closestPort: [null],
        daystoClosestPort: [null],
        /* lattitude: [null],
        longitude: [null], */
        candidate_ccid: [null, [], this.fetchCandidateFromCcid()],
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        dateOfBirth: [null],
        gender: [null, [Validators.required]],
        dateOfJoining: [null],
        dayDifference: [{value: 0, disabled: false}],
        rank: [null],
        nationality: [null],
        mailType: [null],
        emp_id: [null, [Validators.pattern(/^[a-zA-Z0-9]*$/)]],
        email: ['', [Validators.pattern(/^[a-zA-Z0-9]+[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z.]{2,5}$/)]],
        code: ['', [Validators.pattern(/^\+[1-9\s-0-9]+$/),Validators.maxLength(6),Validators.minLength(2)]],
        telephone: ['', [Validators.pattern(/^-?(0|[1-9]\d*)?$/),Validators.maxLength(10),Validators.minLength(6)]],
        address: [null],
      });
      this.f['dateOfBirth'].disable();
      this.getPortList("");
      this.filteredPortList = this.f['arrivalPort'].valueChanges
        .pipe(
          startWith(''),
          map(obj => obj ? this._filterPort(obj) : this.portList.slice())
        );
      this.getPortListClosest("");
      this.filteredPortListClosest = this.f['closestPort'].valueChanges
        .pipe(
          startWith(''),
          map(obj => obj ? this._filterPortClosest(obj) : this.portListClosest.slice())
        );
      this.getRankList();
      /* this.getNationalityList(""); */
      /* this.filteredNationality = this.f['nationality'].valueChanges
        .pipe(
          startWith(''),
          map(nationality => nationality ? this._filterNationality(nationality) : this.nationalityList.slice())
        ); */
        this.f.nationality.valueChanges.pipe(debounceTime(500),tap(() => this.isLoading = true),switchMap(value => this.apiService.nationalityList(value).pipe(finalize(() => this.isLoading = false),)))
        .subscribe((data:any) => {
          let len = data.length;
          for (let i = 0; i < len; i++) {
            if(data[i].nationality==null){
              data[i].nationality = "";
            }
          }
          this.filteredNationality = data
        });
      this.getSymptomQuestions();
      if (this.candidateId != null) {
        this.getCandidateAndVesselDetails();
      } else if (this.vesselId != null) {
        this.getVesselDetails();
      }
    });
  }

  ngAfterViewInit() {
  }

  getCandidateAndVesselDetails() {
    this.apiService.getCandidateAndVesselDetails(this.candidateId).subscribe(
      data => {
        this.candidateDetails1 = data;
        this.f['imoNo'].setAsyncValidators([]);
        this.f['imoNo'].updateValueAndValidity();
        this.f['imoNo'].setValue(this.candidateDetails1.imoNo);
        this.f['vesselName'].setValue(this.candidateDetails1.vesselName);
        this.f['callSign'].setValue(this.candidateDetails1.callSign);

        this.f['candidate_ccid'].setAsyncValidators([]);
        this.f['candidate_ccid'].updateValueAndValidity();
        this.f['candidate_ccid'].setValue(this.candidateDetails1.candidate_ccid);
        this.f['firstName'].setValue(this.candidateDetails1.firstName);
        this.f['lastName'].setValue(this.candidateDetails1.lastName);
        this.f['gender'].setValue(this.candidateDetails1.gender);
        this.f['email'].setValue(this.candidateDetails1.email);
        this.f['code'].setValue(this.candidateDetails1.code);
        this.f['telephone'].setValue(this.candidateDetails1.mobile_no);
        //this.f.nationality.setValue(this.candidateDetails1.nationality);
        if (this.candidateDetails1.nationality) {
          this.f['nationality'].setValue(this.candidateDetails1.nationality)
        }
        /*try{
          if(this.nationalityList.length>0){
            this.f.nationality.setValue(this.nationalityList.find(obj=>obj.nationality==this.candidateDetails1.nationality));
          }
        }catch(err){}*/


        try {
          var dateOfBirth = this.candidateDetails1.dateOfBirth.split("-");
          var temp = dateOfBirth[0];
          dateOfBirth[0] = dateOfBirth[2];
          dateOfBirth[2] = temp;
          dateOfBirth = dateOfBirth.join("-");
          this.f['dateOfBirth'].setValue(dateOfBirth);
        } catch (err) {
        }


        this.f['rank'].setValue(this.candidateDetails1.rank);

        this.rank_disabled = true;
        this.f['imoNo'].disable();
        this.f['vesselName'].disable();
        this.f['callSign'].disable();
        this.f['firstName'].disable();
        this.f['lastName'].disable();
        this.f['gender'].disable();
        this.candidate_disabled = true;
        this.nationality_disabled = true;
        this.vesselDetails.vesselId = this.candidateDetails1.vesselId;
        this.vesselDetails.vesselName = this.candidateDetails1.vesselName;
        this.vesselDetails.imoNo = this.candidateDetails1.imoNo;
        this.vesselDetails.callSign = this.candidateDetails1.callSign;
        this.vesselDetails.companyId = this.candidateDetails1.companyId;
        this.f['emp_id'].setValue(this.candidateDetails1.emp_id);
      },
      (error) => {
        this.rank_disabled = false;
        this.f['imoNo'].enable();
        this.f['vesselName'].enable();
        this.f['callSign'].enable();
        this.f['firstName'].enable();
        this.f['lastName'].enable();
        this.f['gender'].enable();
        this.candidate_disabled = false;
        this.doj_disable = false;
        this.nationality_disabled = false;
      }
    );
  }

  getVesselDetails() {
    this.apiService.getVesselDetails(this.vesselId).subscribe(
      data => {
        this.vesselDetails = data;
        this.vesselDetails.vesselId = this.vesselId;
        this.f['imoNo'].setAsyncValidators([]);
        this.f['imoNo'].updateValueAndValidity();
        this.f['imoNo'].setValue(this.vesselDetails.imoNo);
        this.f['vesselName'].setValue(this.vesselDetails.vesselName);
        this.f['callSign'].setValue(this.vesselDetails.callSign);
        this.f['imoNo'].disable();
        this.f['vesselName'].disable();
        this.f['callSign'].disable();
      },
      (error) => {
        this.f['imoNo'].enable();
        this.f['vesselName'].enable();
        this.f['callSign'].enable();
      }
    );
  }

  get f() {
    return this.eventForm.controls;
  }

  fetchVesselFromImono = (time: number = 500) => {
    return (input: FormControl) => {
      return timer(time).pipe(
        switchMap(() => this.apiService.fetchVesselFromImono({imoNo: input.value})),
        map((res:any) => {
          this.f['vesselName'].setValue(res["vesselName"]);
          this.f['callSign'].setValue(res["callSign"]);
          this.f['vesselName'].disable();
          this.f['callSign'].disable();
          this.vesselDetails = res;
          return null;
        }),
        catchError((e) => {
          this.f['vesselName'].setValue(null);
          this.f['callSign'].setValue(null);
          this.f['vesselName'].enable();
          this.f['callSign'].enable();
          this.vesselDetails = {};
          return of({notvalid: true});
        })
      );
    };
  };

  imoKeyup() {
    if (this.f['imoNo'].value == "" || this.f['imoNo'].value == null) {
      this.f['vesselName'].setValue(null);
      this.f['callSign'].setValue(null);
    }
  }

  getPortList(search:any) {
    this.apiService.portList(search).subscribe(
      data => {
        this.portList = data;
      },
      (error) => {
        this.toastr.error("Port Name Not Found. Please try again.", 'Error', {
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
      }
    );
  }

  private _filterPort(value: string): any[] {
    if (typeof value != 'string') {
      try {
        value = String(value["portName"]);
      } catch (err) {
        value = "";
      }

    }
    const filterValue = value.toLowerCase();
    this.getPortList(value);
    return this.portList.filter((state:any) => state.portName.toString().toLowerCase().indexOf(filterValue) > -1);
  }

  getPortText(option:any) {
    if (option != null) {
      if (option.portName != null) {
        return option.portName.toString();
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  getPortListClosest(search:any) {
    this.apiService.portList(search).subscribe(
      data => {
        this.portListClosest = data;
      },
      (error) => {
        this.toastr.error("Close Port Name Not Found. Please try again.", 'Error', {
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
      }
    );
  }

  private _filterPortClosest(value: string): any[] {
    if (typeof value != 'string') {
      try {
        value = String(value["portName"]);
      } catch (err) {
        value = "";
      }

    }
    const filterValue = value.toLowerCase();
    this.getPortListClosest(value);
    return this.portListClosest.filter((state:any) => state.portName.toString().toLowerCase().indexOf(filterValue) > -1);
  }

  getPortTextClosest(option:any) {
    if (option != null) {
      if (option.portName != null) {
        return option.portName.toString();
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  fetchCandidateFromCcid = (time: number = 500) => {
    return (input: FormControl) => {
      return timer(time).pipe(
        switchMap(() => this.apiService.fetchCandidateFromCcid({ccId: input.value})),
        map(res => {
          let candidateDetails: any = res;
          this.f['firstName'].setValue(candidateDetails.firstName);
          this.f['lastName'].setValue(candidateDetails.lastName);
          this.f['dateOfBirth'].setValue(candidateDetails.dateOfBirth);
          this.f['gender'].setValue(candidateDetails.gender);
          this.f['firstName'].disable();
          this.f['lastName'].disable();
          this.f['gender'].disable();
          this.candidate_disabled = true;
          this.candidateDetails = candidateDetails;
          return null;
        }),
        catchError((e) => {
          this.f['firstName'].setValue(null);
          this.f['lastName'].setValue(null);
          this.f['dateOfBirth'].setValue(null);
          this.f['gender'].setValue(null);
          this.f['firstName'].enable();
          this.f['lastName'].enable();
          this.f['gender'].enable();
          this.candidate_disabled = false;
          this.candidateDetails = {};
          if (input.value == null || input.value == "" || input.value == undefined) {
            return of(null);
          }
          return of({notvalid: true});
        })
      );
    };
  };

  cIdKeyup() {
    if (this.f['candidate_ccid'].value == "" || this.f['candidate_ccid'].value == null) {
      this.f['firstName'].setValue(null);
      this.f['lastName'].setValue(null);
      this.f['dateOfBirth'].setValue(null);
      this.f['gender'].setValue(null);
    }
  }

  GetDateStringFromPickerDate(date:any) {
    if (date == "" || date == null || date == undefined) {
      return null;
    }
    var dateStr = date.toString();
    var dateObj = new Date(dateStr);
    var month = dateObj.getMonth() + 1;
    var day = dateObj.getDate();
    var year = dateObj.getFullYear();
    var newDate = day + "-" + month + "-" + year;
    return newDate;
  }

  private _filterNationality(value: string): any[] {
    if (typeof value != 'string') {
      try {
        value = String(value["nationality"]);
      } catch (err) {
        value = "";
      }

    }
    const filterValue = value.toLowerCase();
    this.getNationalityList(value);
    return this.nationalityList.filter((state:any) => state.nationality.toString().toLowerCase().indexOf(filterValue) > -1);
  }

  getRankList() {
    this.apiService.rankList().subscribe(
      data => {
        this.ranklist = data;
      },
      (error) => {
        this.toastr.error("Rank Not Found. Please try again.", 'Error', {
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
      }
    );
  }

  getNationalityList(search:any) {
    this.apiService.nationalityList(search).subscribe(
      (data:any) => {
        let len = data.length;
        for (let i = 0; i < len; i++) {
          if(data[i].nationality==null){
            data[i].nationality = "";
          }
        }
        this.nationalityList = data;
        /*if(this.candidateDetails1.nationality!=null){
          this.f.nationality.setValue(this.nationalityList.find(obj=>obj.nationality==this.candidateDetails1.nationality));
        }*/
      },
      (error) => {
        this.toastr.error("Nationality not found.", 'Error', {
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
      }
    );
  }

  getNationalityText(option:any) {
    if (option != null) {
      if (option.nationality != null) {
        return option.nationality.toString();
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  resetGroupMasters() {
    this.selectedGroups = [];
    this.selectedSymptoms = [];
    this.selectedMeds = {};
    this.questionaaire = false;
    this.getSymptomQuestions();
    this.disablecheckboxes = false;
    this.questionsList = [];
    this.tempIds = [];
		this.tempGroupList = [];
  }

  tempIds:any = [];
	tempGroupList:any = [];
  convertObjToArrayGroups() {
    this.selectedGroups = [];
    let isFirstStep = false;
		if(this.tempIds.length==0){
			isFirstStep = true;
			this.tempGroupList = JSON.parse(JSON.stringify(this.groupList));
		}
    for (let [key, value] of Object.entries(this.selectedMeds)) {
      if (value == true) {
        this.selectedGroups.push(key);
        if(isFirstStep){
					this.tempIds.push(key);
				}
      }
    }
    let selectedCount = 0;
		for (let i=0;i<this.groupList.length;i++) {
			if (!this.groupList[i].hideCheckBox && this.selectedMeds[this.groupList[i].id]) {
				selectedCount++;
			}
		}
		if(selectedCount==0){
			this.toastr.error("Please select atleast 1 symptom", 'Error', {
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
    	if(selectedCount>1){
			this.toastr.error("Please select only 1 symptom", 'Error', {
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
    this.getSymptomQuestions();
  }


  showinputs(id:any) {
    this.AddDataInputs = true;
    this.SaveDeviceId = id;
  }

  SaveMedicalData() {
    if (this.vitals) {
      this.toastr.success("Vital Added Successfully.", 'Success', {
        timeOut:3000,
        tapToDismiss: true,
        closeButton: true
      });
      this.SaveDeviceId = '';
    }
  }


  isTouchEnabled() {
    return (("ontouchstart" in window)
      || (navigator.maxTouchPoints > 0)
      || (navigator.maxTouchPoints > 0));
  }

  loadHumanAnatomy() {
    this.apiService.humanAnatomyAll().subscribe(
      data => {
        let res: any = data;
        this.spotsconfiga = {"pins": res.MF};
        this.spotsconfigb = {"pins": res.MB};
        this.displayHumanAnatomy(this.spotsconfiga, this.spotsconfigb);
      },
      (error) => {
      }
    );
  }

  displayHumanAnatomy(spotsconfiga:any, spotsconfigb:any) {
    var pins_len:any = spotsconfiga.pins.length;
    if (pins_len > 0) {
      var xmlns = "http://www.w3.org/2000/svg";
      var tsvg_obj = document.getElementById("anaspotsa");
      var svg_circle;
      for (var i = 0; i < pins_len; i++) {
        svg_circle = document.createElementNS(xmlns, "circle");
        svg_circle.setAttributeNS(null, "cx", spotsconfiga.pins[i].pos_X);
        svg_circle.setAttributeNS(null, "cy", spotsconfiga.pins[i].pos_Y);
        svg_circle.setAttributeNS(null, "r", (spotsconfiga.pins[i].size / 2).toString());
        svg_circle.setAttributeNS(null, "fill", spotsconfiga.pins[i].upColor);
        svg_circle.setAttributeNS(null, "stroke", spotsconfiga.pins[i].outline);
        svg_circle.setAttributeNS(null, "stroke-width", "1");
        svg_circle.setAttributeNS(null, "id", "anaspotsa_" + i);
        svg_circle.setAttributeNS(null, "body_part", spotsconfiga.pins[i].body_part);
        if(tsvg_obj){
          tsvg_obj.appendChild(svg_circle);
        }
        this.anaspotsaAddEvent(i);
      }
    }

    var pins_len = spotsconfigb.pins.length;
    if (pins_len > 0) {
      var xmlns = "http://www.w3.org/2000/svg";
      var tsvg_obj = document.getElementById("anaspotsb");
      var svg_circle;
      for (var i = 0; i < pins_len; i++) {
        svg_circle = document.createElementNS(xmlns, "circle");
        svg_circle.setAttributeNS(null, "cx", spotsconfigb.pins[i].pos_X);
        svg_circle.setAttributeNS(null, "cy", spotsconfigb.pins[i].pos_Y);
        svg_circle.setAttributeNS(null, "r", (spotsconfigb.pins[i].size / 2).toString());
        svg_circle.setAttributeNS(null, "fill", spotsconfigb.pins[i].upColor);
        svg_circle.setAttributeNS(null, "stroke", spotsconfigb.pins[i].outline);
        svg_circle.setAttributeNS(null, "stroke-width", "1");
        svg_circle.setAttributeNS(null, "id", "anaspotsb_" + i);
        svg_circle.setAttributeNS(null, "body_part", spotsconfigb.pins[i].body_part);
        if(tsvg_obj){
          tsvg_obj.appendChild(svg_circle);
        }
        this.anaspotsbAddEvent(i);
      }
    }

  };

  anaspotsaAddEvent(id:any) {
    var obj = $("#anaspotsa_" + id);
    if (this.spotsconfiga.pins[id].enabled === true) {
      obj.attr({"cursor": "pointer"});
      obj.hover(() => {
        if (this.pinobj[this.spotsconfiga.pins[id].seq] === undefined) {
          obj.css({"fill": this.spotsconfiga.pins[id].overColor});
        } else if (this.pinobj[this.spotsconfiga.pins[id].seq] === "false") {
          obj.css({"fill": this.spotsconfiga.pins[id].overColor});
        } else if (this.pinobj[this.spotsconfiga.pins[id].seq] === "true") {
          obj.css({"fill": this.spotsconfiga.pins[id].selectedColor});
        } else {
          obj.css({"fill": this.spotsconfiga.pins[id].selectedColor});
        }
      }, () => {
        $("#anatip").hide();
        if (this.pinobj[this.spotsconfiga.pins[id].seq] === undefined) {
          obj.css({"fill": this.spotsconfiga.pins[id].overColor});
        } else if (this.pinobj[this.spotsconfiga.pins[id].seq] === "false") {
          obj.css({"fill": this.spotsconfiga.pins[id].overColor});
        } else if (this.pinobj[this.spotsconfiga.pins[id].seq] === "true") {
          obj.css({"fill": this.spotsconfiga.pins[id].selectedColor});
        } else {
          obj.css({"fill": this.spotsconfiga.pins[id].selectedColor});
        }
      });
      obj.mouseup(() => {
        if (this.spotsconfiga.pins[id].target === "new_window") {
          window.open(this.spotsconfiga.pins[id].url);
        } else if (this.spotsconfiga.pins[id].target === "same_window") {
          if (this.pinobj[this.spotsconfiga.pins[id].seq] === undefined) {
            this.selectedPoints.push(this.spotsconfiga.pins[id].seq);
            this.selectedPointsValue[this.spotsconfiga.pins[id].seq] = this.spotsconfiga.pins[id].body_part;
            this.zone.run(() => {
            });
            this.pinobj[this.spotsconfiga.pins[id].seq] = "true";
            obj.css({"fill": this.spotsconfiga.pins[id].selectedColor});
          } else if (this.pinobj[this.spotsconfiga.pins[id].seq] === "false") {
            this.selectedPoints.push(this.spotsconfiga.pins[id].seq);
            this.selectedPointsValue[this.spotsconfiga.pins[id].seq] = this.spotsconfiga.pins[id].body_part;
            this.zone.run(() => {
            });
            this.pinobj[this.spotsconfiga.pins[id].seq] = "true";
            obj.css({"fill": this.spotsconfiga.pins[id].selectedColor});
          } else if (this.pinobj[this.spotsconfiga.pins[id].seq] === "true") {
            this.remove(this.spotsconfiga.pins[id].seq);
            this.zone.run(() => {
            });
            this.pinobj[this.spotsconfiga.pins[id].seq] = "false";
            obj.css({"fill": this.spotsconfiga.pins[id].upColor});

          } else {
            this.selectedPoints.push(this.spotsconfiga.pins[id].seq);
            this.selectedPointsValue[this.spotsconfiga.pins[id].seq] = this.spotsconfiga.pins[id].body_part;
            this.zone.run(() => {
            });
            this.pinobj[this.spotsconfiga.pins[id].seq] = "true";
            obj.css({"fill": this.spotsconfiga.pins[id].selectedColor});
            this.spotsconfiga.pins[id].overColor = this.spotsconfiga.pins[id].selectedColor;
          }
          this.bindSelectedPoints();
        } else if (this.spotsconfiga.pins[id].target === "modal") {
          $(this.spotsconfiga.pins[id].url).modal("show");
        }

        localStorage.setItem('storeObject', JSON.stringify(this.pinobj));
      });

      obj.mousemove((e:any) => {
        var x = e.pageX + 10, y = e.pageY + 15;
        var tipw = $("#anatip").outerWidth(), tiph = $("#anatip").outerHeight(),
          x = (x + tipw > $(document).scrollLeft() + $(window).width()) ? x - tipw - (20 * 2) : x;
        y = (y + tiph > $(document).scrollTop() + $(window).height()) ? $(document).scrollTop() + $(window).height() - tiph - 10 : y;
        $("#anatip").css({left: x, top: y});
      });
      if (this.isTouchEnabled()) {
        obj.on("touchstart", (e:any) => {
          var touch = e.originalEvent.touches[0];
          var x = touch.pageX + 10, y = touch.pageY + 15;
          var tipw = $("#anatip").outerWidth(), tiph = $("#anatip").outerHeight(),
            x = (x + tipw > $(document).scrollLeft() + $(window).width()) ? x - tipw - (20 * 2) : x;
          y = (y + tiph > $(document).scrollTop() + $(window).height()) ? $(document).scrollTop() + $(window).height() - tiph - 10 : y;
          $("#anatip").show().html(this.spotsconfiga.pins[id].hover);
          $("#anatip").css({left: x, top: y});
        });
        obj.on("touchend", () => {
          $("#" + id).css({"fill": this.spotsconfiga.pins[id].upColor});
          if (this.spotsconfiga.pins[id].target === "new_window") {
            window.open(this.spotsconfiga.pins[id].url);
          } else if (this.spotsconfiga.pins[id].target === "same_window") {
            window.parent.location.href = this.spotsconfiga.pins[id].url;
          } else if (this.spotsconfiga.pins[id].target === "modal") {
            $(this.spotsconfiga.pins[id].url).modal("show");
          }
        });
      }
    }
  }

  anaspotsbAddEvent(id:any) {
    var obj = $("#anaspotsb_" + id);

    if (this.spotsconfigb.pins[id].enabled === true) {
      obj.attr({"cursor": "pointer"});
      obj.hover(() => {
        if (this.pinobj[this.spotsconfigb.pins[id].seq] === undefined) {
          obj.css({"fill": this.spotsconfigb.pins[id].overColor});
        } else if (this.pinobj[this.spotsconfigb.pins[id].seq] === "false") {
          obj.css({"fill": this.spotsconfigb.pins[id].overColor});
        } else if (this.pinobj[this.spotsconfigb.pins[id].seq] === "true") {
          obj.css({"fill": this.spotsconfigb.pins[id].selectedColor});
        } else {
          obj.css({"fill": this.spotsconfigb.pins[id].selectedColor});
        }
      }, () => {
        $("#anatip").hide();
        if (this.pinobj[this.spotsconfigb.pins[id].seq] === undefined) {
          obj.css({"fill": this.spotsconfigb.pins[id].overColor});
        } else if (this.pinobj[this.spotsconfigb.pins[id].seq] === "false") {
          obj.css({"fill": this.spotsconfigb.pins[id].overColor});
        } else if (this.pinobj[this.spotsconfigb.pins[id].seq] === "true") {
          obj.css({"fill": this.spotsconfigb.pins[id].selectedColor});
        } else {
          obj.css({"fill": this.spotsconfigb.pins[id].selectedColor});
        }
      });
      obj.mouseup(() => {
        if (this.spotsconfigb.pins[id].target === "new_window") {
          window.open(this.spotsconfigb.pins[id].url);
        } else if (this.spotsconfigb.pins[id].target === "same_window") {
          if (this.pinobj[this.spotsconfigb.pins[id].seq] === undefined) {
            this.selectedPoints.push(this.spotsconfigb.pins[id].seq);
            this.selectedPointsValue[this.spotsconfigb.pins[id].seq] = this.spotsconfigb.pins[id].body_part;
            this.zone.run(() => {
            });
            this.pinobj[this.spotsconfigb.pins[id].seq] = "true";
            obj.css({"fill": this.spotsconfigb.pins[id].selectedColor});
          } else if (this.pinobj[this.spotsconfigb.pins[id].seq] === "false") {
            this.selectedPoints.push(this.spotsconfigb.pins[id].seq);
            this.selectedPointsValue[this.spotsconfigb.pins[id].seq] = this.spotsconfigb.pins[id].body_part;
            this.zone.run(() => {
            });
            this.pinobj[this.spotsconfigb.pins[id].seq] = "true";
            obj.css({"fill": this.spotsconfigb.pins[id].selectedColor});
          } else if (this.pinobj[this.spotsconfigb.pins[id].seq] === "true") {
            this.remove(this.spotsconfigb.pins[id].seq);
            this.zone.run(() => {
            });
            this.pinobj[this.spotsconfigb.pins[id].seq] = "false";
            obj.css({"fill": this.spotsconfigb.pins[id].upColor});

          } else {
            this.selectedPoints.push(this.spotsconfigb.pins[id].seq);
            this.selectedPointsValue[this.spotsconfigb.pins[id].seq] = this.spotsconfigb.pins[id].body_part;
            this.zone.run(() => {
            });
            this.pinobj[this.spotsconfigb.pins[id].seq] = "true";
            obj.css({"fill": this.spotsconfigb.pins[id].selectedColor});
            this.spotsconfigb.pins[id].overColor = this.spotsconfigb.pins[id].selectedColor;
          }
          this.bindSelectedPoints();
        } else if (this.spotsconfigb.pins[id].target === "modal") {
          $(this.spotsconfigb.pins[id].url).modal("show");
        }

        localStorage.setItem('storeObject', JSON.stringify(this.pinobj));
      });

      obj.mousemove((e:any) => {
        var x = e.pageX + 10, y = e.pageY + 15;
        var tipw = $("#anatip").outerWidth(), tiph = $("#anatip").outerHeight(),
          x = (x + tipw > $(document).scrollLeft() + $(window).width()) ? x - tipw - (20 * 2) : x;
        y = (y + tiph > $(document).scrollTop() + $(window).height()) ? $(document).scrollTop() + $(window).height() - tiph - 10 : y;
        $("#anatip").css({left: x, top: y});
      });
      if (this.isTouchEnabled()) {
        obj.on("touchstart", (e:any) => {
          var touch = e.originalEvent.touches[0];
          var x = touch.pageX + 10, y = touch.pageY + 15;
          var tipw = $("#anatip").outerWidth(), tiph = $("#anatip").outerHeight(),
            x = (x + tipw > $(document).scrollLeft() + $(window).width()) ? x - tipw - (20 * 2) : x;
          y = (y + tiph > $(document).scrollTop() + $(window).height()) ? $(document).scrollTop() + $(window).height() - tiph - 10 : y;
          $("#anatip").show().html(this.spotsconfigb.pins[id].hover);
          $("#anatip").css({left: x, top: y});
        });
        obj.on("touchend", () => {
          $("#" + id).css({"fill": this.spotsconfigb.pins[id].upColor});
          if (this.spotsconfigb.pins[id].target === "new_window") {
            window.open(this.spotsconfigb.pins[id].url);
          } else if (this.spotsconfigb.pins[id].target === "same_window") {
            window.parent.location.href = this.spotsconfigb.pins[id].url;
          } else if (this.spotsconfigb.pins[id].target === "modal") {
            $(this.spotsconfigb.pins[id].url).modal("show");
          }
        });
      }
    }
  }

  bindSelectedPoints() {
    var length = this.selectedPoints.length;
    var str = "";
    for (var i = 0; i < length; ++i) {
      if (i == length - 1) {
        str += this.selectedPoints[i]
      } else {
        str += this.selectedPoints[i] + ', '
      }
    }
    $("#selectedPointsText").val(str);
  }

  getSymptomQuestions() {
    this.questionsList = [];
    this.selectedGroupsQuestions = [];
    this.apiService.symptomQuestions({module_id: 1, parent_ids: this.selectedGroups}).subscribe(
      data => {
        this.groupList = data;
        for (let j = 0; j < this.groupList.length; j++) {
          this.groupList[j].hideCheckBox = false;
          for (let i = 0; i < this.selectedGroups.length; i++) {
            if(this.groupList[j].id==this.selectedGroups[i] && this.groupList[j].parent_id==0){
              this.groupList[j].hideCheckBox = true;
            }
          }
        }
        
        var humanAnatomy = false;
        for (let [k, v] of Object.entries(this.selectedMeds)) {
          this.groupList.forEach((val:any) => {
            if (val.id == k) {
              if (this.selectedGroupsQuestions.indexOf(val.id) === -1) {
                this.selectedGroupsQuestions.push(val.questions);
                if(this.selectedSymptoms.indexOf(val.label)==-1){
									this.selectedSymptoms.push(val.label);
								}
                if(val.questions){
                  try {
                    val.questions.forEach((value:any) => {
                      if (value.input_type_data != null && value.input_type_data != undefined) {
                        var len = value.input_type_data.length;
                        for (var j = 0; j < len; j++) {
                          value.input_type_data[j].text === 'Noo' ? value.input_type_data[j].text = 'No' : value.input_type_data[j].text;
                        }
                      }
                      this.questionsList.push(value);
                    });
                  } catch (error) {}
                }
                
                if (val.questions && val.hideCheckBox==false) {
                  this.questionaaire = true;
                  this.class126 = "col-sm-6";
                  this.class123 = "col-sm-3";
                  this.class312 = "col-sm-12";
                  this.class612 = "col-sm-12";
                  this.class412 = "col-sm-12";
                  this.loadNext = false;
                  this.disablecheckboxes = true;
                  humanAnatomy = true;
                }
              }
            }
          })
        }
        ;
        if (humanAnatomy) {
          this.loadHumanAnatomy();
        }
      },
      (error) => {
        this.toastr.error("Unable To Fetch Symptoms", 'Error', {
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
      }
    );
  }

  removeGroup(id:any){
    for (let i = 0; i < this.groupList.length; i++) {
      if(this.groupList[i].id==id && this.groupList[i].parent_id==0){
        this.groupList.splice(i,1);
      }
      
    }
  }

  remove(item:any) {
    var index = this.selectedPoints.indexOf(item);
    this.selectedPoints.splice(index, 1);
    delete this.selectedPointsValue[item];
  }

  trackByQuestionId(index: number, questionObj: any): string {
    return questionObj.question_id;
  }

  mediaFiles(event:any, index:any) {
  }

  isObjectEmpty() {
    return Object.keys(this.selectedMeds).length === 0;
  }

  watchSelectedMeds() {
    var unSelectedCnt = 0;
    for (let [key, value] of Object.entries(this.selectedMeds)) {
      if (value == false) {
        unSelectedCnt = unSelectedCnt + 1;
      }
    }
    unSelectedCnt === Object.keys(this.selectedMeds).length ? this.loadNext = false : this.loadNext = true;
  }

  unselectOthers(id:any){
		for (let i=0;i<this.groupList.length;i++) {
			if (this.groupList[i].id != id && !this.groupList[i].hideCheckBox) {
				this.selectedMeds[this.groupList[i].id] = false;
			}
		}
	}

  saveEvent() {
    this.clearCustomError();
    if (this.eventForm.invalid) {
      this.toastr.warning("Please check required feilds", 'Error',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
      return;
    }
    var reqData:any = {
      "vesselId": this.vesselDetails.vesselId,
      "companyId": this.vesselDetails.companyId,
      "emailId": this.vesselDetails.emailId,
      "flagShip": this.vesselDetails.flagShip,
      "typeOfVessel": this.vesselDetails.typeOfVessel,
      "mmsiNo": this.vesselDetails.mmsiNo,
      "lastSyncDateTime": this.vesselDetails.lastSyncDateTime,
      "flagState": this.vesselDetails.flagState,
      "summary": this.vesselDetails.summary,
      "services": this.vesselDetails.services,
      "companyName": this.vesselDetails.companyName,
      "imoNo": this.f['imoNo'].value,
      "firstName": this.f['firstName'].value,
      "lastName": this.f['lastName'].value,
      "gender": this.f['gender'].value,
      "dateOfBirth": this.GetDateStringFromPickerDate(this.f['dateOfBirth'].value),
      "case_status": "Open",
      "email":this.f['email'].value,
      "code":this.f['code'].value,
      "telephone":this.f['telephone'].value,
      "address":this.f['address'].value

    }
    if (this.transactionId != null) {
      reqData["transactionId"] = this.transactionId;
    }

    if (this.f['vesselName'].value != undefined && this.f['vesselName'].value != null && this.f['vesselName'].value != "") {
      reqData["vesselName"] = this.f['vesselName'].value;
    }
    var callSignForUniqueId = "";
    if (this.f['callSign'].value != undefined && this.f['callSign'].value != null && this.f['callSign'].value != "") {
      reqData["callSign"] = this.f['callSign'].value;
      callSignForUniqueId = this.f['callSign'].value;
    }
    var dateTimestamp = new Date();
    reqData["uniqueId"] = callSignForUniqueId + dateTimestamp.getTime() + '1';
    if (this.f['candidate_ccid'].value != undefined && this.f['candidate_ccid'].value != null && this.f['candidate_ccid'].value != "") {
      reqData["candidate_ccid"] = this.f['candidate_ccid'].value;
    }


    if (this.selectedPoints.length > 0) {
      reqData["humanAnatomy"] = this.selectedPoints;
    }
    /* if (this.f.captainName.value != undefined && this.f.captainName.value != null && this.f.captainName.value != "") {
      reqData["captainName"] = this.f.captainName.value;
    } */
    if (this.f['arrivalPort'].value != undefined && this.f['arrivalPort'].value != null && this.f['arrivalPort'].value != "") {
      if (typeof this.f['arrivalPort'].value != "string") {
        reqData["arrivalPort"] = this.f['arrivalPort'].value.portName;
      } else {
        reqData["arrivalPort"] = this.f['arrivalPort'].value;
      }
    }
    if (this.f['etaNextPort'].value != undefined && this.f['etaNextPort'].value != null && this.f['etaNextPort'].value != "") {
      reqData["eta"] = this.GetDateStringFromPickerDate(this.f['etaNextPort'].value);
    }
    if (this.f['closestPort'].value != undefined && this.f['closestPort'].value != null && this.f['closestPort'].value != "") {
      if (typeof this.f['closestPort'].value != "string") {
        reqData["closestPort"] = this.f['closestPort'].value.portName;
      } else {
        reqData["closestPort"] = this.f['closestPort'].value;
      }
    }
    if (this.f['daystoClosestPort'].value != undefined && this.f['daystoClosestPort'].value != null && this.f['daystoClosestPort'].value != "") {
      reqData["daystoClosestPort"] = this.f['daystoClosestPort'].value;
    }
    /* if (this.f.lattitude.value != undefined && this.f.lattitude.value != null && this.f.lattitude.value != "") {
      reqData["lattitude"] = this.f.lattitude.value;
    }
    if (this.f.longitude.value != undefined && this.f.longitude.value != null && this.f.longitude.value != "") {
      reqData["longitude"] = this.f.longitude.value;
    } */
    if (this.f['dateOfJoining'].value != undefined && this.f['dateOfJoining'].value != null && this.f['dateOfJoining'].value != "") {
      reqData["dateOfJoining"] = this.GetDateStringFromPickerDate(this.f['dateOfJoining'].value);
    }
    if (this.f['dayDifference'].value != undefined && this.f['dayDifference'].value != null && this.f['dayDifference'].value != "") {
      reqData["dayDifference"] = this.f['dayDifference'].value;
    }
    if (this.f['rank'].value != undefined && this.f['rank'].value != null && this.f['rank'].value != "") {
      reqData["rank"] = this.f['rank'].value;
    }
    if (this.f['nationality'].value != null && this.f['nationality'].value != "") {
      reqData["nationality"] = this.f['nationality'].value;
    }
    if (this.f['emp_id'].value != null && this.f['emp_id'].value != "") {
      reqData["emp_id"] = this.f['emp_id'].value;
    }

    if (this.selectedSymptoms.length == 0) {
      this.toastr.info("Please Select at least 1 symptom.", 'Danger', {
        timeOut:3000,
        tapToDismiss: true,
        closeButton: true
      });
      return;
    }

    var whattodoDetails:any = [];
    var medicalDetails:any = [];
    for (var i = 0; i < this.questionsList.length; i++) {
      if(this.questionsList[i].input_type !== 'whattodo'){
        var medDet = {
          "Question":this.questionsList[i].question,
          "Answer":this.questionsList[i].answer,
          "question_id":this.questionsList[i].question_id,
          "symptom_name":this.questionsList[i].symptom_name,
          "symptom_id":this.questionsList[i].symptom_id
        };
        medicalDetails.push(medDet);
      }

      if (this.questionsList[i].input_type == 'whattodo') {
        var whatToDet = {
          "Question": this.questionsList[i].question,
          "Answer": this.questionsList[i].answer,
          "question_id": this.questionsList[i].question_id,
          "symptom_name": this.questionsList[i].symptom_name,
          "symptom_id": this.questionsList[i].symptom_id
        };
        whattodoDetails.push(whatToDet);
      }

    }
    this.selectedSymptoms = [];
		for (let i = 0; i < this.groupList.length; i++) {
			if(this.selectedMeds[this.groupList[i].id]==true){
				this.selectedSymptoms.push(this.groupList[i].label)
			}
		}
		for (let i = 0; i < this.tempGroupList.length; i++) {
			for (let j = 0; j < this.tempIds.length; j++) {
				if(this.tempIds[j] == this.tempGroupList[i].id){
					this.selectedSymptoms.push(this.tempGroupList[i].label)
				}
			}
		}
    reqData["symptoms"] = [...new Set(this.selectedSymptoms)];
    reqData["whatToDoDetails"] = whattodoDetails;
    reqData["medicalDetails"]=medicalDetails;
    /* var bodyText = String(this.body.msg).replace(/<[^>]+>/gm, '');
    if (this.body.msg != undefined && this.body.msg != null && this.body.msg != "" && bodyText.length > 0) {
      reqData["notes"] = this.body.msg;
    } else {
      this.toastr.error("Please enter Initial Complaint ", 'Danger', {
        timeOut:3000,
        tapToDismiss: true,
        closeButton: true
      });
      return;
    } */

    this.showLoader = true;
    this.apiService.createCustomEvent(reqData).subscribe(
      data => {
        this.showLoader = false;
        this.toastr.success("Event generated successfully.", 'Success!!!', {
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
        this.router.navigate(["/app/eventList"]);
      },
      (error) => {
        this.showLoader = false;
        this.handleAPIError(error);
      }
    );
  }

  clearCustomError(){
		Object.keys(this.f).forEach(key => {
			if(this.f[key].hasError('incorrect')){
				try {
					delete this.f[key].errors?.['incorrect'];
					this.f[key].updateValueAndValidity();
				} catch (error) {
					
				}
				
			}
		});
		this.customError = {};
	}

  handleAPIError(error:any){
		let errorMsg = "Something went wrong.";
		if(error.status==404 || error.status==400){
			errorMsg = error.error;
			if(typeof errorMsg=='object'){
				for (const [key, value] of Object.entries(errorMsg)) {
					let val:any = value;
					if(this.eventForm.controls[key]){
						this.eventForm.controls[key].setErrors({incorrect:true});
						this.customError[key] = val[0];
						let selector = "#"+key;
						try {
							$('html, body').animate({
								scrollTop: $(selector).offset().top - 90 + 'px'
							}, 'fast');
						} catch (error) {}
						$(selector).focus();
					}else{
						this.toastr.error(key+":"+val[0], 'Error',{
							timeOut:3000,
							tapToDismiss: true,
							closeButton: true
						});
					}
				}
				return;
			}
		}
		this.toastr.error(errorMsg, 'Error',{
			timeOut:3000,
			tapToDismiss: true,
			closeButton: true
		});
	}

}
