<mat-toolbar color="custom" id="main-menu-header">
	<a [routerLink]="['/app/eventList']">
		<img src="../assets/img/RMA.png" class="logo" />
	</a>
	<div class="dropdown">
		<a data-toggle="dropdown" class="dropdown-toggle mr-30 ml-30" id="applicationsId" matTooltip="Menu">
			<i class="fa fa-th menu-color"></i>
		</a>
		<ul id="bg-white" class="dropdown-menu p wd-lg" style="margin-left: 80px;">
			<div class="row ml0 mr0 mt mb text-center fixedHeightMenu">
				<div *ngIf="userTypeName!='Pharmacist'" class="col-xs-4 h100">
					<div class="pv">
						<a id="homeRouteId" [routerLink]="['/app/eventList']">
							<span class="block">
								<em class="fa fa-home fa-2x text-warning menu-color"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Home</p>
						</a>
					</div>
				</div>
				<div *ngIf="userTypeName!='Pharmacist'" class="col-xs-4 h100">
					<div class="pv">
						<a id="mailboxRouteId" [routerLink]="['/app/mailbox/folder/unlinked']">
							<span class="block">
								<em class="fa fa-envelope-o fa-2x text-primary"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Mailbox</p>
						</a>
					</div>
				</div>
				<div *ngIf="userTypeName!='Pharmacist'" class="col-xs-4 h100">
					<div class="pv">
						<a id="usersRouteId" [routerLink]="['/app/admin/listUsers']">
							<span class="block">
								<em class="fa fa-user-o fa-2x text-muted"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Users</p>
						</a>
					</div>
				</div>
				<div *ngIf="userTypeName!='Pharmacist'" class="col-xs-4 h100">
					<div class="pv">
						<a id="accessRouteId" [routerLink]="['/app/admin/listAccess']">
							<span class="block">
								<em class="fa fa-lock fa-2x text-amber"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Access</p>
						</a>
					</div>
				</div>
				<div class="col-xs-4 h100">
					<div class="pv">
						<a id="maincompanyRouteId" [routerLink]="['/app/admin/main-company-list']">
							<span class="block">
								<em class="fa fa-building-o fa-2x text-amber"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Company<br />List</p>
						</a>
					</div>
				</div>
				<div class="col-xs-4 h100">
					<div class="pv">
						<a id="companyRouteId" [routerLink]="['/app/admin/listCompany']">
							<span class="block">
								<em class="fa fa-th-large fa-2x text-amber"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Fleet<br />List</p>
						</a>
					</div>
				</div>
				<div *ngIf="userTypeName!='Pharmacist'" class="col-xs-4 h100">
					<div class="pv">
						<a id="QnRouteId" [routerLink]="['/app/questionSetup']">
							<span class="block">
								<em class="fa fa-gear fa-2x text-success"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Question<br />Master</p>
						</a>
					</div>
				</div>
				<div class="col-xs-4 h100">
					<div class="pv">
						<a id="pocRouteId" [routerLink]="['/app/admin/listPOC']">
							<span class="block">
								<em class="fa fa-book fa-2x text-info"></em>
							</span>
							<p class="text-muted mb-0 lh-20">POC</p>
						</a>
					</div>
				</div>
				<div class="col-xs-4 h100">
					<div class="pv">
						<a id="medRouteId" [routerLink]="['/app/admin/listMedicineMaster']">
							<span class="block">
								<em class="fa fa-medkit fa-2x text-default"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Medicines</p>
						</a>
					</div>
				</div>
				<div class="col-xs-4 h100">
					<div class="pv">
						<a id="seafarerRouteId" [routerLink]="['/app/admin/listSeafarer','empty','empty']">
							<span class="block">
								<em class="fa fa-list fa-2x text-success"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Seafarers</p>
						</a>
					</div>
				</div>
				<div class="col-xs-4 h100">
					<div class="pv">
						<a id="FlagStateId" [routerLink]="['/app/admin/flagstate']">
							<span class="block">
								<em class="fa fa-flag fa-2x text-success"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Flag<br />State</p>
						</a>
					</div>
				</div>
				<div class="col-xs-4 h100">
					<div class="pv">
						<a id="RanksId" [routerLink]="['/app/admin/ranks']">
							<span class="block">
								<em class="fa fa-star fa-2x text-primary"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Event<br />Ranks</p>
						</a>
					</div>
				</div>
				<div class="col-xs-4 h100">
					<div class="pv">
						<a id="NationalityId" [routerLink]="['/app/admin/nationality']">
							<span class="block">
								<em class="fa fa-flag-o fa-2x text-info"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Nationality</p>
						</a>
					</div>
				</div>
				<div class="col-xs-4 h100">
					<div class="pv">
						<a id="TemplateId" [routerLink]="['/app/admin/templates']">
							<span class="block">
								<em class="fa fa-file-text-o fa-2x text-danger"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Mail<br />Templates</p>
						</a>
					</div>
				</div>
				<div class="col-xs-4 h100">
					<div class="pv">
						<a id="OwnersId" [routerLink]="['/app/admin/owners']">
							<span class="block">
								<em class="fa fa-user-circle-o fa-2x text-primary"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Owners</p>
						</a>
					</div>
				</div>
				<div class="col-xs-4 h100">
					<div class="pv">
						<a id="OwnersId" [routerLink]="['/app/billing']">
							<span class="block">
								<em class="fa fa-rupee fa-2x text-danger"></em>
							</span>
							<p class="text-muted mb-0 lh-20">Billing</p>
						</a>
					</div>
				</div>
			</div>
		</ul>
	</div>
	<span class="text-bold fs_14 menu-color mr-30">Welcome, {{userName}}</span>
	<a [routerLink]="['/app/admin/userProfile']" class="mr-30" matTooltip="Profile">
		<i style="font-size: 18px;" class="fa fa-user-circle-o menu-color"></i>
	</a>
	<a matTooltip="SMR Form" class="mr-30" (click)="downloadSMRFiles()">
		<em class="fa fa-file-pdf-o menu-color"></em>
	</a>
	<a matTooltip="Vessel List" [routerLink]="['/app/admin/listVessels']" class="mr-30">
		<em class="fa fa-ship menu-color"></em>
	</a>
	<a matTooltip="Emergency" (click)="openEmergencyModel()" class="mr-30" style="cursor: pointer;">
		<i class="fa fa-bell text-danger" style="font-size: 20px;"></i>
	</a>
	<div class="dropdown">
		<a data-toggle="dropdown" class="dropdown-toggle mr-30" id="contactsId" matTooltip="Contacts">
			<i class="fa fa-address-book menu-color"></i>
		</a>
		<ul class="dropdown-menu p card-container" style="margin-left: 80px;width: 690px;">
			<div class="panel panel-body mb-0">
				<div class="row">
					<div class="col-lg-4 fs-12" style="padding-left: 20px;">
						<p class="text-white mb-0"><u>RMA Numbers</u></p>
						<p class="text-white mb-0">(P)+91 8069123333</p>
						<p class="text-white mb-0">(A)+91 9321792378</p>
						<p class="text-white mb-0">- - - - - -</p>
						<p class="text-white mb-0">(WA) +91 9372946505</p>
						<p class="text-white mb-0">(WA) +91 9321788322</p>
					</div>
					<div class="col-lg-4 fs-12" style="padding-left: 20px;">
						<p class="text-white mb-0"><u>Medical Emergency Support</u></p>
						<p class="text-white mb-0">Dr. Agarwal +91 9820641131</p>
						<p class="text-white mb-0">Dr. Save +91 9892255888</p>
						<p class="text-white mb-0">Dr Shetty +91 8108440444</p>
						<p class="text-white mb-0">Dr Ravani +91 9820325668</p>
						<p class="text-white mb-0">Dr Nadeem +91 8454079587</p>
					</div>
					<div class="col-lg-4 fs-12" style="padding-left: 20px;">
						<p class="text-white mb-0"><u>Other Key Contacts</u></p>
						<p class="text-white mb-0">Aniket (IT) +91 7504006655</p>
						<p class="text-white mb-0">Raj (IT) +91 7718967284</p>
						<p class="text-white mb-0">Sunil (IT) +91 7776084939</p>
						<p class="text-white mb-0">Rashmin (IT) +91 9819137930</p>
						<p class="text-white mb-0">Hartegh +91 8976781333</p>
						<p class="text-white mb-0">Ravjyot +91 9004409200</p>
					</div>
				</div>
			</div>
		</ul>
	</div>
	<a matTooltip="Handover" (click)="openHandoverModel()" class="mr-30" style="cursor: pointer;">
		<span class="menu-color" style="font-size: 14px; font-weight: 500;">My Handover</span>
	</a>
	<span class="example-spacer"></span>
	<a (click)="logout();" matTooltip="Logout" style="cursor: pointer;">
		<em class="icon-power menu-color text-bold"></em>
	</a>
</mat-toolbar>
<div class="main-content" id="page-content-container-common">
	<router-outlet></router-outlet>
</div>
<div class="footer text-center">
	<p>© {{ currYear }} - Health Cube - V{{ projectVersion }} </p>
</div>
<div class="modal fade" id="followUpModalHeadBar" role="dialog">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal"
					id="btnCloseFollowUpModelHeadBar">&times;</button>
				<h4 class="modal-title mb0">
					Scheduled Calls
					<button id="caseFollowUpRefreshBtn" (click)="caseFollowUpList()"
						class="btn btn-primary btn-xs mr pull-right" [disabled]="followupLoader">
						<i *ngIf="followupLoader" class="fa fa-spinner fa-spin mr"></i>
						Refresh
					</button>
				</h4>
			</div>
			<div class="modal-body pt0">
				<div class="table-responsive">
					<table class="table">
						<thead>
							<tr>
								<th>No</th>
								<th>CaseId</th>
								<th>Reason</th>
								<th>Created By</th>
								<th>Reminder Due At</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody *ngIf="folloUpList.length">
							<tr *ngFor="let data of folloUpList; let i = index">
								<td>{{i + 1}}</td>
								<td [routerLink]="['/app/eventDetails',data.eventId]" matTooltip="Case Details"
									style="cursor: pointer;" data-dismiss="modal">{{data.event_id}}</td>
								<td>{{data.follow_up_reason}}</td>
								<td>{{data.follow_up_by || &apos;---&apos;}}</td>
								<td>{{data.followUpTime}}</td>
								<td>
									<button class="btn btn-xs btn-success"
										(click)="doneFollowup(data.eventId,data.id,i)"
										[disabled]="data.follow_up_done_by || doneLoader==i">
										<i *ngIf="doneLoader==i" class="fa fa-spinner fa-spin mr"></i>
										Done
									</button>
								</td>
							</tr>
						</tbody>
						<tbody *ngIf="!folloUpList.length">
							<tr>
								<td colspan="6" class="text-center">Data Not Found</td>
							</tr>
						</tbody>
					</table>
					<div class="text-center">
						<button id="caseFollowUpAllBtn" [routerLink]="['/app/admin/followup']" data-dismiss="modal"
							class="btn btn-primary btn-xs">View All</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal fade" id="GlobalEmergencyModal" role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header bg-danger">
				Global Emergency
				<i style="cursor:pointer;" class="fa fa-times pull-right" data-dismiss="modal"
					(click)="closeEmergencyModel();" id="btnCloseEmergencyModel"></i>
			</div>
			<form [formGroup]="emergencyNoteForm" (ngSubmit)="submitEmergencyNote()">
				<div class="modal-body">
					<div class="form-group">
						<label>Notes</label>
						<textarea formControlName="emergencyNote" class="form-control" rows="5"
							maxlength="500"></textarea>
						<span style="color:red">
							{{emergencyNoteForm.controls['emergencyNote'].value.length||0}} / 500 characters
						</span>
					</div>
				</div>
				<div class="modal-footer">
					<button [disabled]="!emergencyNoteForm.valid" type="submit"
						class="btn btn-success btn-sm">Submit</button>
				</div>
			</form>
		</div>
	</div>
</div>
<div class="modal fade" id="handoverModal" role="dialog">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="closeHandoverModel();"
					id="btnCloseHandoverModel">&times;</button>
				<h4 class="modal-title">My Handover<i *ngIf="fetchHandoverLoader"
						class="fa fa-spinner fa-spin ml-sm"></i>
				</h4>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-md-12">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th style="width: 15%;">Case Id</th>
									<th style="width: 50%;">Notes</th>
									<th style="width: 20%;">Added On</th>
									<th style="width: 15%;">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let note of handoverList;let i = index;">
									<td>
										<a [routerLink]="['/app/eventDetails',note.eventid]"
											class="text-primary text-bold"
											matTooltip="Case Details">{{note.case_id}}</a>
									</td>
									<td>
										<p *ngIf="!note.isEdit">{{note.handover_notes}}</p>
										<input *ngIf="note.isEdit" type="text" class="form-control"
											placeholder="Enter Notes" id="handover_notes{{i}}"
											[(ngModel)]="note.handover_notes" maxlength="{{handoverMaxLength}}" />
										<p style="color:red" *ngIf="note.isEdit">
											{{note.handover_notes?.length||0}}/{{handoverMaxLength}} characters
										</p>
									</td>
									<td>
										{{GetDateStringFromPickerDate1(note.created_at)}}
									</td>
									<td>
										<button class="btn btn-xs btn-danger" matTooltip="Delete"
											(click)="deleteHandover(i)">
											<i *ngIf="deleteHandoverLoader==i" class="fa fa-spinner fa-spin"
												aria-hidden="true"></i>
											<i *ngIf="deleteHandoverLoader!=i" class="fa fa-trash"
												aria-hidden="true"></i>
										</button>
										<!-- <button class="btn btn-xs btn-primary ml-sm"
											matTooltip="{{note.isEdit?'Cancel':'Edit'}}"
											(click)="toggleEditHandover(i)">
											<i *ngIf="!note.isEdit" class="fa fa-pencil" aria-hidden="true"></i>
											<i *ngIf="note.isEdit" class="fa fa-times" aria-hidden="true"></i>
										</button>
										<button *ngIf="note.isEdit" class="btn btn-xs btn-success ml-sm"
											matTooltip="Update" (click)="updateHandover(i)"
											[disabled]="note.handover_notes?.length==0 || note.handover_notes?.length > handoverMaxLength">
											<i *ngIf="editHandoverLoader==i" class="fa fa-spinner fa-spin"
												aria-hidden="true"></i>
											<i *ngIf="editHandoverLoader!=i" class="fa fa-save" aria-hidden="true"></i>
										</button> -->
									</td>
								</tr>
								<tr *ngIf="handoverList.length==0">
									<td colspan="4" class="text-center">No notes found</td>
								</tr>
								<tr *ngIf="handoverList.length">
									<td colspan="4" class="text-center">
										<button type="button" class="btn btn-success btn-sm" (click)="sendHandover();"
											[disabled]="sendHandoverLoader">
											<i *ngIf="sendHandoverLoader" class="fa fa-spinner fa-spin mr"
												aria-hidden="true"></i>
											<i *ngIf="!sendHandoverLoader" class="fa fa-paper-plane mr"
												aria-hidden="true"></i>
											Send Handover
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<!-- <div class="row">
					<div class="col-md-10">
						<label>Add Notes:</label>
						<input type="text" class="form-control" placeholder="Enter Notes" id="new_handover_notes"
							[(ngModel)]="handoverNotes" maxlength="{{handoverMaxLength}}" />
						<p style="color:red">
							{{handoverNotes?.length||0}}/{{handoverMaxLength}} characters
						</p>
						<p style="color:red" *ngIf="handoverNotes?.length > handoverMaxLength">
							{{handoverMaxLength}} characters max
						</p>
					</div>
					<div class="col-md-2">
						<br>
						<button type="button" class="btn btn-success btn-sm mt-sm" matTooltip="Create Note"
							(click)="createHandover();"
							[disabled]="handoverNotes?.length==0 || handoverNotes?.length > handoverMaxLength">
							<i *ngIf="createHandoverLoader" class="fa fa-spinner fa-spin mr"></i>
							Add
						</button>
					</div>
				</div> -->
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-primary btn-sm" (click)="getHandoverList();"
					id="btnRefreshHandoverModel">Refresh</button>
				<button type="button" class="btn btn-danger btn-sm ml-sm" (click)="closeHandoverModel();"
					id="btnCloseHandoverModel1">Close</button>
			</div>
		</div>
	</div>
</div>