<link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">
<link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700" rel="stylesheet">
<div class="panel panel-body">
	<div class="row" *ngIf="moduleAccess=='Full' || moduleAccess=='Readonly'">
		<div class="col-md-7 card-container pr-sm">
			<div class="row">
				<div [routerLink]="['/app/mailbox/folder/unlinked']" id="linkUnlinked"
					class="text-center col-lg-3 pr-sm" style="cursor: pointer;">
					<div class="panel panel-body bg-unlinked mb-sm card-ht1">
						<h4 class="text-white mb-0">
							Un-Linked
						</h4>
						<h3 class="text-white mb-0">
							<strong>{{unlinkedCnt || 0}}</strong>
						</h3>
					</div>
				</div>
				<div class="text-center col-lg-3 pl-sm pr-sm" style="cursor: pointer;"
					(click)="tableparams.flag=3;flagOpenEvents();">
					<div class="panel panel-body bg-success mb-sm card-ht1">
						<h4 class="text-white mb-0">
							Improving
						</h4>
						<h3 class="text-white mb-0">
							<strong>{{improving_cnt || 0}}</strong>
						</h3>
					</div>
				</div>
				<div class="text-center col-lg-3 pl-sm pr-sm" style="cursor: pointer;"
					(click)="tableparams.flag=5;flagOpenEvents();">
					<div class="panel panel-body bg-primary mb-sm card-ht1">
						<h4 class="text-white mb-0">
							V. ICU
						</h4>
						<h3 class="text-white mb-0">
							<strong>{{code_blue_cnt || 0}}</strong>
						</h3>
					</div>
				</div>
				<div class="text-center col-lg-3 pl-sm" style="cursor: pointer;"
					(click)="tableparams.flag=1;flagOpenEvents();">
					<div class="panel panel-body bg-black mb-sm card-ht1">
						<h4 class="text-white mb-0">
							Unassigned
						</h4>
						<h3 class="text-white mb-0">
							<strong>{{unassigned_cnt || 0}}</strong>
						</h3>
					</div>
				</div>
			</div>
			<div class="row">
				<div [routerLink]="['/app/allEvents']" class="text-center col-lg-3 pr-sm" style="cursor: pointer;">
					<div class="panel panel-body bg-allcases mb-sm card-ht1">
						<h4 class="text-white mb-0">
							All Cases
						</h4>
						<h3 class="text-white mb-0">
							<strong>{{alleventcnt || 0}}</strong>
						</h3>
					</div>
				</div>
				<div class="text-center col-lg-3 pl-sm pr-sm" style="cursor: pointer;"
					(click)="tableparams.flag=4;flagOpenEvents();">
					<div class="panel panel-body bg-danger mb-sm card-ht1">
						<h4 class="text-white mb-0">
							Close Watch
						</h4>
						<h3 class="text-white mb-0">
							<strong>{{emergency_cnt || 0}}</strong>
						</h3>
					</div>
				</div>
				<div class="text-center col-lg-3 pl-sm pr-sm" style="cursor: pointer;"
					(click)="tableparams.flag=2;flagOpenEvents();">
					<div class="panel panel-body bg-yellow mb-sm card-ht1">
						<h4 class="text-white mb-0">
							T. Onboard
						</h4>
						<h3 class="text-white mb-0">
							<strong>{{routine_cnt || 0}}</strong>
						</h3>
					</div>
				</div>
				<div class="text-center col-lg-3 pl-sm" style="cursor: pointer;"
					(click)="tableparams.isReplied = !tableparams.isReplied;toggleNotReplied();">
					<div class="panel panel-body bg-not_replied mb-sm card-ht1">
						<h4 class="text-white mb-0">
							Not Replied
						</h4>
						<h3 class="text-white mb-0">
							<strong>{{not_replied_cnt || 0}}</strong>
						</h3>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-5 card-container pl-sm">
			<div class="row">
				<div class="text-center col-lg-6 pr-sm" style="cursor: pointer;" (click)="totalCallsClicked()">
					<div class="panel panel-body bg-info mb-sm card-ht1">
						<h4 class="text-white mb-0">
							<img src="../assets/img/telephone.png" class="call-image" alt="Call Image" />
							Total Calls Last 7 Days
						</h4>
						<h3 class="text-white mb-0">
							<strong>{{callCounts.total_calls || 0}}</strong>
						</h3>
					</div>
				</div>
				<div class="text-center col-lg-6 pl-sm" style="cursor: pointer;" (click)="answeredCallsClicked()">
					<div class="panel panel-body bg-success mb-sm card-ht1">
						<h4 class="text-white mb-0">
							<img src="../assets/img/answered-call.png" class="call-image" alt="Answered Call Image" />
							Answered Calls Last 7 Days
						</h4>
						<h3 class="text-white mb-0">
							<strong>{{callCounts.call_answered_by_agent || 0}}</strong>
						</h3>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="text-center col-lg-6 pr-sm" style="cursor: pointer;" (click)="missedCallsClicked()">
					<div class="panel panel-body bg-danger mb-sm card-ht1">
						<h4 class="text-white mb-0">
							<img src="../assets/img/missed-call.png" class="call-image" alt="Missed Call Image" />
							Missed Calls Last 7 Days
						</h4>
						<h3 class="text-white mb-0">
							<strong>{{callCounts.call_missed_by_agent || 0}}</strong>
						</h3>
					</div>
				</div>
				<div class="text-center col-lg-6 pl-sm" style="cursor: pointer;" (click)="outgoingCallsClicked()">
					<div class="panel panel-body bg-outgoing mb-sm card-ht1">
						<h4 class="text-white mb-0">
							<img src="../assets/img/outgoing-call-1.png" class="call-image" alt="Missed Call Image" />
							Outgoing Calls Last 7 Days
						</h4>
						<h3 class="text-white mb-0">
							<strong>{{callCounts.outbound || 0}}</strong>
						</h3>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="tabbable">
		<ul class="nav nav-tabs">
			<li [ngClass]="getTabClass(0)" (click)="setActiveTab(0)">
				<a href="#tab0" data-toggle="tab" target="_self">
					Open Cases ({{openEventcnt || 0}})
				</a>
			</li>
			<li [ngClass]="getTabClass(1)" (click)="setActiveTab(1)">
				<a href="#tab1" data-toggle="tab" target="_self">
					Scheduled Calls ({{followupcnt || 0}})
				</a>
			</li>
			<li [ngClass]="getTabClass(2)" (click)="setActiveTab(2)">
				<a href="#tab2" data-toggle="tab" target="_self">
					Teams Handover ({{daily_handover_cnt || 0}})
				</a>
			</li>
			<li [ngClass]="getTabClass(3)" (click)="setActiveTab(3)">
				<a href="#tab3" data-toggle="tab" target="_self">
					Reviewer's Notes ({{reviewer_notes_cnt || 0}})
				</a>
			</li>
			<li [ngClass]="getTabClass(4)" (click)="setActiveTab(4)">
				<a href="#tab4" data-toggle="tab" target="_self">
					Calls (Unlinked: {{unlinked_calls_count || 0}})
				</a>
			</li>
		</ul>
		<div class="tab-content">
			<div [ngClass]="getTabPaneClass(0)" id="tab0">
				<div *ngIf="moduleAccess=='Full' || moduleAccess=='Readonly'">
					<div class="panel panel-default">
						<div>
							<div style="padding-top: 10px;width: 100%;" class="pull-left">
								<input autofocus id="searchBox"
									placeholder="Search By Case ID, Vessel, Candidate, Fleet"
									style="font-size: 16px;margin-left: 5px;width: 20%;float: left;"
									[formControl]="searchControl" class="form-control" />
								<ng-select style="margin-left: 5px;width: 30%;float: left;" [items]="tagMaster"
									appendTo="body" [clearable]="true" [multiple]="true" placeholder="Search By Tags"
									id="tags" name="tags" [(ngModel)]="tableparams.tags"
									(change)="searchByTags()"></ng-select>
								<ng-select style="margin-left: 5px;width: 15%;float: left;" [items]="flagList"
									bindLabel="name" bindValue="id" appendTo="body" [clearable]="true"
									[multiple]="false" placeholder="Search By TRIAGE" [(ngModel)]="tableparams.flag"
									(change)="flagOpenEvents()">
									<ng-template ng-label-tmp let-item="item">
										<i [ngClass]="item.class"></i>{{item.name}}
									</ng-template>
									<ng-template ng-option-tmp let-item="item" let-search="searchTerm"
										let-index="index">
										<i [ngClass]="item.class"></i>{{item.name}}
									</ng-template>
								</ng-select>
								<div class="checkbox c-checkbox pull-left ml-sm"
									style="width:140px !important;margin-top: 10px;">
									<label>
										<input type="checkbox" id="checkMyCases" [(ngModel)]="tableparams.myCases"
											[ngModelOptions]="{standalone: true}" (change)="toggleMyCases()" />
										<span class="fa fa-check"></span>
										My Cases ({{my_cases_cnt}})
									</label>
								</div>
								<ng-toggle *ngIf="tableparams.myCases" [(ngModel)]="showIncomplete"
									[color]="{ unchecked: '#2494f2', checked: '#2494f2' }"
									[fontColor]="{checked: '#ffffff',unchecked: '#ffffff'}"
									[switchColor]="{checked: '#4caf50',unchecked: '#4caf50'}"
									[labels]="{'unchecked': 'All','checked': 'Uncomplience'}" [width]="105"
									class="pull-left ml-sm mt-sm">
								</ng-toggle>
								<div class="checkbox c-checkbox pull-left ml-sm"
									style="width:190px !important;margin-top: 10px;">
									<label>
										<input type="checkbox" id="checkisReplied"
											[(ngModel)]="tableparams.mark_for_closure"
											[ngModelOptions]="{standalone: true}" (change)="markForClosureChange()" />
										<span class="fa fa-check"></span>
										Marked for closure ({{marked_for_closure_cnt}})
									</label>
								</div>
								<div class="checkbox c-checkbox pull-left ml-sm"
									style="width:110px !important;margin-top: 10px;">
									<label>
										<input type="checkbox" id="checkisReplied" [(ngModel)]="tableparams.isReplied"
											[ngModelOptions]="{standalone: true}" (change)="toggleNotReplied()" />
										<span class="fa fa-check"></span>
										Not Replied
									</label>
								</div>
								<!-- <button *ngIf="userTypeName =='Basic Admin' || userTypeName=='Admin'" type="button"
									class="btn btn-sm btn-info ml mr pull-right"
									(click)="openModal()">Analytics</button> -->
							</div>
							<div class="table-responsive"
								style="height: 100vh;overflow-y: auto;overflow-x: hidden;width: 100%;">
								<table class="table table-striped table-bordered table-hover fixed-header-table">
									<thead>
										<tr>
											<th class="tableth_heading text-center">
												Case ID
												<i (click)="sortTableBy('id')" id="sortTableByBtnId"
													class="fa fa-sort ml" style="cursor: pointer;"></i>
											</th>
											<th class="tableth_heading text-center">
												Date Of Case
												<i (click)="sortTableBy('createdAt')" id="sortTableByBtncreatedAt"
													class="fa fa-sort ml" style="cursor: pointer;"></i>
											</th>
											<th class="tableth_heading text-center">
												Vessel Name
												<i (click)="sortTableBy('vesselName')" id="sortTableByBtnvesselName"
													class="fa fa-sort ml" style="cursor: pointer;"></i>
											</th>
											<th class="tableth_heading text-center">
												Candidate Name
												<i (click)="sortTableBy('candname')" id="sortTableByBtncandname"
													class="fa fa-sort ml" style="cursor: pointer;"></i>
											</th>
											<th class="tableth_heading text-center" *ngIf="!tableparams.myCases">
												Symptoms
											</th>
											<th class="tableth_heading text-center" *ngIf="!tableparams.myCases">
												Doctor Name
												<i (click)="sortTableBy('doctorName')" id="sortTableByBtndoctorName"
													class="fa fa-sort ml" style="cursor: pointer;"></i>
											</th>
											<th class="tableth_heading text-center" *ngIf="tableparams.myCases">
												Incomplete
											</th>
										</tr>
									</thead>
									<tbody>
										<ng-container *ngFor="let eve of eventlist; let i = index">
											<tr class="table_td_style" *ngIf="showRow(eve.uncomplienceCases)">
												<td class="text-center">
													<a matTooltip="View Case Details" [ngStyle]="set_color(eve)"
														[routerLink]="['/app/eventDetails',eve.id]">
														{{eve.eventId}}
													</a>
													<span *ngIf="eve.emailStatus=='Unread'" matTooltip="New mail"
														class="mr-sm">
														<i aria-hidden="true" class="fa fa-envelope"></i>
													</span>
													<span *ngIf="eve.isReplied==1" matTooltip="Not Replied">
														<i aria-hidden="true" style="color: #3f51b5;"
															class="fa fa-envelope"></i>
													</span>
												</td>
												<td class="text-center">{{fixDate(eve.createdAt)}}</td>
												<td class="text-uppercase">
													<i class="fa fa-info-circle mr"
														[matTooltip]="'Fleet : ' + eve.com_name"></i>
													{{eve.vesselName}}
												</td>
												<td class="text-center text-uppercase">
													{{eve.candname}}
												</td>
												<td matTooltip="{{eve.symptomsText}}" *ngIf="!tableparams.myCases">
													{{eve.symptomsText | limitTo:'20'}}
												</td>
												<td class="text-center text-uppercase" *ngIf="!tableparams.myCases">
													{{eve.doctorName || '-'}}
												</td>
												<td *ngIf="tableparams.myCases">
													<ng-container *ngIf="eve.uncomplienceCases.length">
														<ng-container
															*ngIf="eve.uncomplienceCases[0]?.communication_followup==false">
															<span class="text-danger" matTooltip="Incomplete">
																Communication Followup
															</span>
															<!-- <span
																[ngClass]="eve.uncomplienceCases[0]?.communication_followup==false?'text-danger':'text-success'"
																[matTooltip]="eve.uncomplienceCases[0]?.communication_followup==false?'Incomplete':'Complete'">
																Communication Followup
															</span> -->
															<br />
														</ng-container>
														<ng-container
															*ngIf="eve.uncomplienceCases[0]?.initial_complaints==false">
															<span class="text-danger" matTooltip="Incomplete">
																Initial Complaints
															</span>
															<br />
														</ng-container>
														<ng-container
															*ngIf="eve.uncomplienceCases[0]?.human_body==false">
															<span class="text-danger" matTooltip="Incomplete">
																Human Body
															</span>
															<br />
														</ng-container>
														<ng-container
															*ngIf="eve.uncomplienceCases[0]?.crew_status==false">
															<span class="text-danger" matTooltip="Incomplete">
																Crew Status
															</span>
															<br />
														</ng-container>
														<ng-container
															*ngIf="eve.uncomplienceCases[0]?.work_status==false">
															<span class="text-danger" matTooltip="Incomplete">
																Crew Work Status
															</span>
															<br />
														</ng-container>
														<ng-container
															*ngIf="eve.uncomplienceCases[0]?.precautions_advised==false">
															<span class="text-danger" matTooltip="Incomplete">
																Precautions Advised
															</span>
															<br />
														</ng-container>
														<ng-container
															*ngIf="eve.uncomplienceCases[0]?.doctor_notes==false">
															<span class="text-danger" matTooltip="Incomplete">
																Doctor Notes
															</span>
														</ng-container>
													</ng-container>
												</td>
											</tr>
										</ng-container>
									</tbody>
								</table>
								<div *ngIf="!eventlist.length &amp;&amp; !isLoading" class="text-center text-bold">
									Data Not Found.
								</div>
								<div *ngIf="isLoading" class="text-center text-bold">
									<i aria-hidden="true" class="fa fa-spinner fa-spin"></i> Loading more
									events.
								</div>
								<app-infinite-scroll (scrolled)="getEventList()" *ngIf="!isLoading">
								</app-infinite-scroll>
								<div *ngIf="!isLoading && !isListFinished" class="text-center text-bold">
									<button id="btnLoadMore" matTooltip="Load More" (click)="getEventList()"
										class="btn btn-primary">Load More</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="moduleAccess!='Full' && moduleAccess!='Readonly'">
					<div class="text-center">
						<h4>You don&apos;t have access to this section.</h4>
					</div>
				</div>
			</div>
			<div [ngClass]="getTabPaneClass(1)" id="tab1">
				<div class="panel panel-default">
					<div class="panel-body">
						<div class="row">
							<div class="col-sm-4">
								<input id="searchBox" placeholder="Search by caseId, call added by, call done by"
									[formControl]="searchControlFollowup" class="form-control" />
							</div>
							<div class="col-sm-4">
								<ng-select [items]="typeList" bindLabel="name" bindValue="id" appendTo="body"
									[clearable]="false" [multiple]="false" placeholder="Search By Type"
									[(ngModel)]="tableparamsFollowup.type" (change)="searchByType()">
								</ng-select>
							</div>
						</div>
						<hr class="mb-sm mt-sm" />
						<div class="table-responsive"
							style="height: 100vh;overflow-y: auto;overflow-x: hidden;width: 100%;">
							<table class="table fixed-header-table">
								<thead>
									<tr class="table_head">
										<th>No.</th>
										<th>CaseId</th>
										<th>Appt. Time</th>
										<th>Specialist</th>
										<th>Created By</th>
										<th>Call Done By</th>
										<!-- <th>Reason</th> -->
										<th>Updated At</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let data of folloUpList; let i = index" class="table_td">
										<td>{{i + 1}}</td>
										<td [routerLink]="['/app/eventDetails',data.eventId]" style="cursor: pointer;"
											matTooltip="Case Details">{{data.event_id}}</td>
										<td>{{data.followUpTime}}</td>
										<td>
											{{data.specialist_first_name}} {{data.specialist_last_name}}
											<ng-container
												*ngIf="data.specialist_type">&nbsp;({{data.specialist_type}})</ng-container>
										</td>
										<td>{{data.follow_up_by || &apos;---&apos;}}</td>
										<td>{{data.follow_up_done_by || &apos;---&apos;}}</td>
										<!-- <td>{{data.follow_up_reason || &apos;---&apos;}}</td> -->
										<td>{{data.updated_at || &apos;---&apos;}}</td>
										<td><button class="btn btn-xs btn-success"
												(click)="doneFollowup(data.eventId,data.id,i)"
												[disabled]="data.follow_up_done_by || doneLoader==i"><i
													*ngIf="doneLoader==i"
													class="fa fa-spinner fa-spin mr"></i>Done</button></td>
									</tr>
									<tr *ngIf="!folloUpList.length &amp;&amp; !isLoadingFollowup">
										<td colspan="8" class="text-center text-bold">Data Not Found.</td>
									</tr>
									<tr *ngIf="isLoadingFollowup">
										<td colspan="8" class="text-center text-bold"><i aria-hidden="true"
												class="fa fa-spinner fa-spin"></i> Loading more follow up.</td>
									</tr>
									<app-infinite-scroll (scrolled)="getFollowUpList(false)" *ngIf="!isLoadingFollowup">
									</app-infinite-scroll>
									<tr *ngIf="!isLoadingFollowup && !isListFinishedFollowup">
										<td colspan="8" class="text-center text-bold">
											<button id="btnLoadMore" matTooltip="Load More"
												(click)="getFollowUpList(false)" class="btn btn-primary">Load
												More</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div [ngClass]="getTabPaneClass(2)" id="tab2">
				<div class="panel panel-default">
					<div class="panel-body">
						<div class="table-responsive"
							style="height: 100vh;overflow-y: auto;overflow-x: hidden;width: 100%;">
							<table class="table fixed-header-table">
								<thead>
									<tr class="table_head">
										<th style="width: 15% !important;">Doctor</th>
										<th style="width: 85% !important;" colspan="2">
											<div class="row">
												<div class="col-md-2">Case Id</div>
												<div class="col-md-7">Notes</div>
												<div class="col-md-2">Added on</div>
												<div class="col-md-1"></div>
											</div>
										</th>
										<!-- <th style="width: 25% !important;">Added on</th> -->
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let data of handoverList; let i = index" class="table_td">
										<td>{{data.dr_name}}</td>
										<td colspan="2">
											<div class="row mb-sm" *ngFor="let note of data.notes; let j = index">
												<div class="col-md-2">
													<a [routerLink]="['/app/eventDetails',note.eventid]"
														class="text-primary text-bold"
														matTooltip="Case Details">{{note.case_id}}</a>
												</div>
												<div class="col-md-7">
													<ul class="mb-0">
														<li>
															{{note.handover_notes}}
														</li>
													</ul>
												</div>
												<div class="col-md-2">
													{{GetDateStringFromReviewerNoteDate(note["created_at"])}}
												</div>
												<div class="col-md-1">
													<button class="btn btn-xs btn-default pull-right"
														matTooltip="Delete" (click)="deleteHandover(i,j)">
														<i *ngIf="deleteHandoverLoaderI==i && deleteHandoverLoaderJ==j"
															class="fa fa-spinner fa-spin"></i>
														<i *ngIf="deleteHandoverLoaderI!=i || deleteHandoverLoaderJ!=j"
															class="fa fa-times text-danger"></i>
													</button>
												</div>
											</div>
											<!-- <table class="table">
												<tbody>
													<tr *ngFor="let note of data.notes; let j = index" class="table_td"
														style="background-color: #ffffff;">
														<td style="width: 70% !important;">
															<ul>
																<li>{{note.handover_notes}}</li>
															</ul>
														</td>
														<td style="width: 20% !important;">
															{{GetDateStringFromReviewerNoteDate(note.created_at)}}
														</td>
														<td style="width: 10% !important;">
															<button class="btn btn-xs btn-default" matTooltip="Delete"
																(click)="deleteHandover(i,j)">
																<i *ngIf="deleteHandoverLoaderI==i && deleteHandoverLoaderJ==j"
																	class="fa fa-spinner fa-spin"></i>
																<i *ngIf="deleteHandoverLoaderI!=i || deleteHandoverLoaderJ!=j"
																	class="fa fa-times text-danger"></i>
															</button>
														</td>
													</tr>
												</tbody>
											</table> -->
										</td>
									</tr>
									<tr *ngIf="!handoverList.length &amp;&amp; !isLoadingHandOver">
										<td colspan="3" class="text-center text-bold">Data Not Found.</td>
									</tr>
									<tr *ngIf="isLoadingHandOver">
										<td colspan="3" class="text-center text-bold"><i aria-hidden="true"
												class="fa fa-spinner fa-spin"></i> Loading more...</td>
									</tr>
									<app-infinite-scroll (scrolled)="getTeamsHandover()" *ngIf="!isLoadingHandOver">
									</app-infinite-scroll>
									<tr *ngIf="!isLoadingHandOver && !isListFinishedHandOver">
										<td colspan="3" class="text-center text-bold">
											<button id="btnLoadMore" matTooltip="Load More" (click)="getTeamsHandover()"
												class="btn btn-primary">Load
												More</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div [ngClass]="getTabPaneClass(3)" id="tab3">
				<div class="panel panel-default">
					<div class="panel-body">
						<div class="row">
							<div class="col-sm-4">
								<input id="searchBox" placeholder="Search by case id" [formControl]="searchControlQC"
									class="form-control" />
							</div>
						</div>
						<hr class="mb-sm mt-sm" />
						<div class="table-responsive"
							style="height: 100vh;overflow-y: auto;overflow-x: hidden;width: 100%;">
							<table class="table fixed-header-table">
								<thead>
									<tr class="table_head">
										<th style="width: 10% !important;">CaseId</th>
										<th style="width: 90% !important;">Notes</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let data of qcList; let i = index" class="table_td">
										<td>
											<a [routerLink]="['/app/eventDetails',data.id]"
												class="text-primary text-bold"
												matTooltip="Case Details">{{data.caseId}}</a>
										</td>
										<td>
											<ul class="mb-0">
												<li *ngFor="let note of data.notes">
													<p class="mb-0 fs-12">
														{{note.Reviewers_notes}} added by
														<strong>{{note["Added by"]}}</strong>
														on
														<strong>
															{{GetDateStringFromReviewerNoteDate(note["Added on"])}}
														</strong>
													</p>
												</li>
											</ul>
										</td>
									</tr>
									<tr *ngIf="!qcList.length &amp;&amp; !isLoadingQC">
										<td colspan="2" class="text-center text-bold">Data Not Found.</td>
									</tr>
									<tr *ngIf="isLoadingQC">
										<td colspan="2" class="text-center text-bold"><i aria-hidden="true"
												class="fa fa-spinner fa-spin"></i> Loading more...</td>
									</tr>
									<app-infinite-scroll (scrolled)="getQCList(false)" *ngIf="!isLoadingQC">
									</app-infinite-scroll>
									<tr *ngIf="!isLoadingQC && !isListFinishedQC">
										<td colspan="2" class="text-center text-bold">
											<button id="btnLoadMore" matTooltip="Load More" (click)="getQCList(false)"
												class="btn btn-primary">Load
												More</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div [ngClass]="getTabPaneClass(4)" id="tab4">
				<div class="panel panel-default">
					<div class="panel-body">
						<div class="row">
							<div class="col-sm-4">
								<input id="searchBox" placeholder="Search" [formControl]="searchControlCall"
									class="form-control" />
							</div>
							<div class="col-sm-4">
								<ng-select [items]="directionList" appendTo="body" bindLabel="name" bindValue="val"
									[clearable]="true" placeholder="Direction" [(ngModel)]="tableparamsCall.direction"
									(change)="directionFilterChanged()">
								</ng-select>
							</div>
							<div class="col-sm-4">
								<ng-select [items]="callTypeListDropdown" appendTo="body" bindLabel="name"
									bindValue="val" [clearable]="true" placeholder="Status"
									[(ngModel)]="tableparamsCall.call_type" (change)="call_typeFilterChanged()">
								</ng-select>
							</div>
						</div>
						<hr class="mb-sm mt-sm" />
						<div class="table-responsive"
							style="height: 100vh;overflow-y: auto;overflow-x: hidden;width: 100%;">
							<table class="table fixed-header-table">
								<thead>
									<tr class="table_head">
										<th style="width: 17% !important;">Case Id</th>
										<th style="width: 5% !important;"></th>
										<th style="width: 19% !important;">From</th>
										<th style="width: 19% !important;">To</th>
										<!-- <th style="width: 15% !important;">Vessel</th> -->
										<th style="width: 10% !important;">Date Time</th>
										<th style="width: 20% !important;">Results</th>
										<th style="width: 10% !important;">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let data of callList; let i = index;" class="table_td">
										<td>
											<ng-container *ngFor="let case of data.connected_event">
												<div class="case-badge">
													<div class="left">
														<a matTooltip="Case Details"
															[routerLink]="['/app/eventDetails',case.id]">
															{{case.event_id}}
														</a>
													</div>
													<div class="right">
														<i class="fa fa-unlink pull-right" matTooltip="Unlink"
															(click)="unlinkCase(case.id,data.id)"></i>
													</div>
												</div>
											</ng-container>
										</td>
										<td>
											<img [src]="data.direction=='inbound'?'../assets/img/incoming-call.png':'../assets/img/outgoing-call.png'"
												matTooltip="{{data.direction=='inbound'?'Incoming':'Outgoing'}}"
												class="call-image" alt="Call Image" />
										</td>
										<td>
											<ng-container *ngIf="data.direction=='inbound'">
												<span *ngIf="!data.vessel_name">
													{{data.customer_no_with_prefix}}{{data.caller_id_number}}
												</span>
												<span *ngIf="data.vessel_name"
													matTooltip="{{data.customer_no_with_prefix}}{{data.caller_id_number}}">
													{{data.vessel_name}}
												</span>
												<button *ngIf="!data.vessel_name" type="button"
													class="btn btn-xs btn-default"
													(click)="openVesselListModal(data.customer_no_with_prefix,data.caller_id_number)"
													matTooltip="Save number">
													<i class="fa fa-user-plus" style="color: #000000;"></i>
												</button>
												<button *ngIf="data.vessel_name" type="button"
													class="btn btn-xs btn-danger"
													(click)="unlinkVesselFromNumber(data.vessel_id,data.customer_no_with_prefix,data.caller_id_number)"
													matTooltip="Unlink Vessel">
													<i class="fa fa-unlink"></i>
												</button>
											</ng-container>
											<ng-container *ngIf="data.direction!='inbound'">
												<span>
													{{data.customer_no_with_prefix}}{{data.caller_id_number}}
												</span>
											</ng-container>
										</td>
										<td>
											<ng-container *ngIf="data.direction=='inbound'">
												<span>
													{{data.call_to_number}}
												</span>
											</ng-container>
											<ng-container *ngIf="data.direction!='inbound'">
												<span *ngIf="!data.to_vessel_name">
													{{data.call_to_number}}
												</span>
												<span *ngIf="data.to_vessel_name" matTooltip="{{data.call_to_number}}">
													{{data.to_vessel_name}}
												</span>
												<button *ngIf="!data.to_vessel_name" type="button"
													class="btn btn-xs btn-default"
													(click)="openVesselListModal(null,data.call_to_number)"
													matTooltip="Save number">
													<i class="fa fa-user-plus" style="color: #000000;"></i>
												</button>
												<button *ngIf="data.to_vessel_name" type="button"
													class="btn btn-xs btn-danger"
													(click)="unlinkVesselFromNumber(data.to_vessel_id,null,data.call_to_number)"
													matTooltip="Unlink Vessel">
													<i class="fa fa-unlink"></i>
												</button>
											</ng-container>
										</td>
										<!-- <td>
											{{data.vessel_name}}
										</td> -->
										<td>
											{{data.start_stamp}}
										</td>
										<td>
											<p *ngIf="data.call_type=='call_answered_by_agent' || data.call_type=='call_hangup_by_agent_click_to_call'"
												class="text-success mb-0">
												Call&nbsp;
												{{data.call_type=='call_answered_by_agent'?'answered':'triggered'}}
												&nbsp;by&nbsp;
												<ng-container *ngIf="data.answered_agent">
													{{splitAgentName(data.answered_agent.name)}}
												</ng-container>
											</p>
											<p *ngIf="data.call_type=='call_missed_by_agent'" class="text-danger mb-0">
												Missed call
											</p>
											<p *ngIf="data.call_type!='call_answered_by_agent' && data.call_type!='call_hangup_by_agent_click_to_call' && data.call_type!='call_missed_by_agent'"
												class="mb-0">
												{{data.call_type}}
											</p>
										</td>
										<td>
											<button type="button" class="btn btn-xs btn-primary" matTooltip="Link cases"
												(click)="openLinkCallCaseModal(data)">
												<i class="fa fa-link"></i>
											</button>
											<a *ngIf="data.recording_url" href="{{data.recording_url}}"
												class="btn btn-xs btn-info ml-sm" target="_blank"
												matTooltip="Recording">
												<i class="fa fa-microphone"></i>
											</a>
											<button *ngIf="!data.summary_status" type="button"
												class="btn btn-xs btn-success ml-sm" matTooltip="Summarize"
												(click)="summarizeCall(data.id)"
												[disabled]="data.id === summarizeLoader">
												<i class="fa fa-comment" *ngIf="data.id !== summarizeLoader"></i>
												<i class="fa fa-spinner fa-spin"
													*ngIf="data.id === summarizeLoader"></i>
											</button>
											<button *ngIf="data.summary_status" type="button"
												class="btn btn-xs btn-success ml-sm"
												matTooltip="{{data.summary_status == 'IN_PROGRESS'?'Summarizing':'View Summary'}}"
												[disabled]="data.summary_status == 'IN_PROGRESS'"
												(click)="viewSummary(data)">
												<i class="fa fa-info" *ngIf="data.summary_status != 'IN_PROGRESS'"></i>
												<i class="fa fa-spinner fa-spin"
													*ngIf="data.summary_status == 'IN_PROGRESS'"></i>
											</button>
										</td>
									</tr>
									<tr *ngIf="!callList.length &amp;&amp; !isLoadingCall">
										<td colspan="7" class="text-center text-bold">Data Not Found.</td>
									</tr>
									<tr *ngIf="isLoadingCall">
										<td colspan="7" class="text-center text-bold"><i aria-hidden="true"
												class="fa fa-spinner fa-spin"></i> Loading more...</td>
									</tr>
									<app-infinite-scroll (scrolled)="getCallList(false)" *ngIf="!isLoadingCall">
									</app-infinite-scroll>
									<tr *ngIf="!isLoadingCall && !isListFinishedCall">
										<td colspan="7" class="text-center text-bold">
											<button id="btnLoadMore" matTooltip="Load More" (click)="getCallList(false)"
												class="btn btn-primary">Load
												More</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal fade" id="dataModal" role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" (click)="clearDataModal();">&times;</button>
				<h4 class="modal-title"><b>Select Period</b></h4>
			</div>
			<div class="modal-body">
				<div class="row form-group">
					<div class="col-md-6">
						<label class="control-label">
							From Date:
							<!-- <button type="button" class="btn btn-xs btn-primary" (click)="analyticsPayload.fromDate=null;">Clear</button> -->
						</label>
						<div class="mat-datepicker-container">
							<input id="analyticsPayload_fromDate" [matDatetimepicker]="analytics_from_date_time_picker"
								[(ngModel)]="analyticsPayload.fromDate" (click)="analytics_from_date_time_picker.open()"
								placeholder="From" readonly class="form-control" matInput>
							<mat-datetimepicker-toggle matSuffix
								[for]="analytics_from_date_time_picker"></mat-datetimepicker-toggle>
						</div>
						<mat-datetimepicker type="datetime" [timeInterval]="5"
							#analytics_from_date_time_picker></mat-datetimepicker>
					</div>
					<div class="col-md-6">
						<label class="control-label">
							To Date:
							<!-- <button type="button" class="btn btn-xs btn-primary" (click)="analyticsPayload.toDate=null;">Clear</button> -->
						</label>
						<div class="mat-datepicker-container">
							<input id="analyticsPayload_toDate" [matDatetimepicker]="analytics_to_date_time_picker"
								[(ngModel)]="analyticsPayload.toDate" (click)="analytics_to_date_time_picker.open()"
								placeholder="To" readonly class="form-control" matInput>
							<mat-datetimepicker-toggle matSuffix
								[for]="analytics_to_date_time_picker"></mat-datetimepicker-toggle>
						</div>
						<mat-datetimepicker type="datetime" [timeInterval]="5"
							#analytics_to_date_time_picker></mat-datetimepicker>
					</div>
				</div>
				<div class="row form-group">
					<div class="col-md-12">
						<label class="control-label">Case Status:</label>
						<ng-select [items]="statusList" appendTo="body" bindLabel="name" bindValue="id"
							[clearable]="true" [multiple]="true" placeholder="Select" id="case_status"
							name="case_status" [(ngModel)]="analyticsPayload.case_status">
						</ng-select>
					</div>
				</div>
				<div class="row form-group">
					<div class="col-md-12">
						<label class="control-label">Assigned To:</label>
						<ng-select appendTo="body" id="doctorSearch" [items]="doctorList" bindLabel="doct_name"
							bindValue="id" [clearable]="true" [multiple]="true" placeholder="Select Doctor"
							[searchFn]="doctorCustomSearch" (search)="searchFunctionDoctor($event)"
							[(ngModel)]="analyticsPayload.doctor_id" [ngModelOptions]="{standalone: true}"></ng-select>
					</div>
				</div>
			</div>
			<div class="modal-footer text-center">
				<p *ngIf="analyticsError" style="color: maroon;">{{analyticsError}}</p>
				<button id="btnSubmit1" type="button" class="btn btn-success" (click)="getEventData()">
					<i *ngIf="showLoader" aria-hidden="true" class="fa fa-spinner fa-spin mr-sm"></i>
					Submit
				</button>
				<button id="btnDelete" type="button" class="btn btn-primary" data-dismiss="modal"
					(click)="clearDataModal()">Close</button>
			</div>
		</div>
	</div>
</div>
<div class="modal fade" id="linkCallModal" role="dialog">
	<div class="modal-dialog modal-lg" style="width: 90%;">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="clearLinkCallCaseModal();">&times;</button>
				<h4 class="modal-title"><b>Select cases to link</b></h4>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-sm-4">
						<input placeholder="Search" [formControl]="searchControlCallCase" class="form-control" />
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<div class="table-responsive"
							style="height: 100vh;overflow-y: auto;overflow-x: hidden;width: 100%;">
							<table class="table table-striped table-bordered table-hover">
								<thead>
									<tr>
										<th class="tableth_heading text-center">
											Case ID
										</th>
										<th class="tableth_heading text-center">
											Date Of Case
										</th>
										<th class="tableth_heading text-center">
											Vessel Name
										</th>
										<th class="tableth_heading text-center">
											Candidate Name
										</th>
										<th class="tableth_heading text-center">
											Symptoms
										</th>
										<th class="tableth_heading text-center">
											Doctor Name
										</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let eve of eventlistForCalls; let i = index" class="table_td_style">
										<td class="text-center">
											<div class="checkbox c-checkbox" style="width:140px !important;">
												<label [ngStyle]="set_color(eve)">
													<input type="checkbox" id="checkMyCases" [(ngModel)]="eve.checked"
														[ngModelOptions]="{standalone: true}"
														(change)="caseSelected(eve.id,eve.checked)" />
													<span class="fa fa-check"></span>
													{{eve.eventId}}
												</label>
											</div>
										</td>
										<td class="text-center">{{fixDate(eve.createdAt)}}</td>
										<td class="text-uppercase">
											<i class="fa fa-info-circle mr"
												[matTooltip]="'Fleet : ' + eve.com_name"></i>
											{{eve.vesselName}}
										</td>
										<td class="text-center text-uppercase">{{eve.candname}}</td>
										<td matTooltip="{{eve.symptomsText}}">{{eve.symptomsText | limitTo:'20'}}
										</td>
										<td class="text-center text-uppercase">{{eve.doctorName || '-'}}
										</td>
									</tr>
								</tbody>
							</table>
							<div *ngIf="!eventlistForCalls.length &amp;&amp; !isLoadingForCalls"
								class="text-center text-bold">
								Data Not Found.
							</div>
							<div *ngIf="isLoadingForCalls" class="text-center text-bold">
								<i aria-hidden="true" class="fa fa-spinner fa-spin"></i> Loading more
								events.
							</div>
							<app-infinite-scroll (scrolled)="getEventListForLinkCall(false)" *ngIf="!isLoadingForCalls">
							</app-infinite-scroll>
							<div *ngIf="!isLoadingForCalls && !isListFinishedForCalls" class="text-center text-bold">
								<button id="btnLoadMore" matTooltip="Load More" (click)="getEventListForLinkCall(false)"
									class="btn btn-primary">Load More</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer text-center">
				<button type="button" class="btn btn-success" (click)="linkCallCase()">
					<i *ngIf="linkLoader" class="fa fa-spinner fa-spin"></i>
					Link
				</button>
				<button id="btnDelete" type="button" class="btn btn-primary"
					(click)="clearLinkCallCaseModal()">Close</button>
			</div>
		</div>
	</div>
</div>
<div class="modal fade" id="vesselModal" role="dialog">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="clearVesselModal();">&times;</button>
				<h4 class="modal-title"><b>Add number to vessel</b></h4>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-sm-12" *ngIf="!fetchingVessels">
						<label><span style="color: #b40a09;">*</span>Vessel</label>
						<ng-select [items]="vessellist" bindLabel="vessel" bindValue="vessel_id" [clearable]="true"
							placeholder="Select Vessel" [(ngModel)]="selectedVessel">
						</ng-select>
					</div>
					<div class="col-sm-12" *ngIf="fetchingVessels">
						<i class="fa fa-spinner fa-spin"></i>
						Fetching vessels
					</div>
				</div>
			</div>
			<div class="modal-footer text-center">
				<button type="button" class="btn btn-success" (click)="saveContact()">
					<i *ngIf="saveContactLoader" class="fa fa-spinner fa-spin"></i>
					Save
				</button>
				<button id="btnDelete" type="button" class="btn btn-primary" (click)="clearVesselModal()">Close</button>
			</div>
		</div>
	</div>
</div>
<div class="modal fade" id="summaryModalEventList" role="dialog">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="closeSummaryModal();">&times;</button>
				<h4 class="modal-title"><b>Call Summary</b></h4>
			</div>
			<div class="modal-body">
				<table class="table table-bordered">
					<tbody>
						<tr>
							<th style="width: 15%;">Status</th>
							<td style="width: 85%;">{{summaryData?.summary_status || "-"}}</td>
						</tr>
						<tr>
							<th>Summary</th>
							<td>{{summaryData?.summary || "-"}}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="modal-footer text-center">
				<button type="button" class="btn btn-primary" (click)="closeSummaryModal()">
					Close
				</button>
			</div>
		</div>
	</div>
</div>