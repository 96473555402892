import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { init,BrowserTracing, Replay, routingInstrumentation } from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

if (environment.production) {
	/* init({
		dsn: "https://5565255246ce4034aa324af7094b3401@o4505345052246016.ingest.sentry.io/4505350570049536",
		integrations: [
			new BrowserTracing({
				tracePropagationTargets: [/^https:\/\/hc-api.3cubemedicare\.com\/api/],
				routingInstrumentation: routingInstrumentation,
			})
		],
		tracesSampleRate: 1.0
	}); */
	enableProdMode();
}else{
	/* if(window.location.href.indexOf('localhost')==-1){
		init({
			dsn: "https://5565255246ce4034aa324af7094b3401@o4505345052246016.ingest.sentry.io/4505350570049536",
			integrations: [
				new BrowserTracing({
					tracePropagationTargets: [/^https:\/\/hc-api.3cubehealth\.com\/api/],
					routingInstrumentation: routingInstrumentation,
				}),
				new Replay(),
			],
			tracesSampleRate: 1.0,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
		});
	} */
}
platformBrowserDynamic().bootstrapModule(AppModule).catch((err) => {});
