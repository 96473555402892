<section class="content">
	<div class="row">
		<div class="col-md-4 col-md-offset-4">
			<div class="panel panel-info">
				<div class="panel-heading">
					Link <strong>{{calledMobileNumber}}</strong> to vessel
				</div>
				<div class="panel-body">
					<div class="row">
						<div class="col-md-12" *ngIf="!fetchingVessels">
							<label><span style="color: #b40a09;">*</span>Vessel</label>
							<ng-select [items]="vessellist" bindLabel="vessel" bindValue="vessel_id" [clearable]="true"
								placeholder="Select Vessel" [(ngModel)]="selectedVessel">
							</ng-select>
						</div>
						<div class="col-md-12" *ngIf="fetchingVessels">
							<i class="fa fa-spinner fa-spin"></i>
							Fetching vessels
						</div>
					</div>
					<div class="row mt-lg">
						<div class="col-md-12">
							<button type="button" class="btn btn-sm btn-success" (click)="saveContact()">
								<i *ngIf="saveContactLoader" class="fa fa-spinner fa-spin"></i>
								Save
							</button>
							<a class="btn btn-sm btn-primary ml-sm" [routerLink]="['/app/eventList']">Cancel</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>