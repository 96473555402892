<header style="position: fixed;width: 100%;z-index: 410;box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);background-color:#E7F0F7">
	<nav role="navigation" class="navbar topnavbar">
		<div class="navbar-header">
			<a id="logoId" [routerLink]="userTypeName!='Pharmacist' ? ['/app/eventList']: ['/app/admin/listVessels']" class="navbar-brand ml0 mr0 pr0 pl0 pt-sm pb-sm">
				<img [src]="userTypeName == 'Pharmacist' ? '../assets/img/tech-med_i.png':'../assets/img/RMA.png'" alt="App Logo" class="brand-logo ml-sm" />
			</a>
		</div>
		<div collapse="header.headerMenuCollapsed" class="nav-wrapper collapse navbar-collapse">
			<ul class="nav navbar-nav hidden-material">
				<li class="dropdown">
					<a data-toggle="dropdown" class="dropdown-toggle" id="applicationsId" matTooltip="Menu">
						<em class="fa fa-th fa-fw menu-color"></em>
					</a>
					<ul id="bg-white" class="dropdown-menu p wd-lg">
						<div class="row ml0 mr0 mt mb text-center fixedHeightMenu">
							<div *ngIf="userTypeName!='Pharmacist'" class="col-xs-4 h100">
								<div class="pv">
									<a id="homeRouteId" [routerLink]="['/app/eventList']">
										<span class="block mb">
											<em class="fa fa-home fa-2x text-warning menu-color"></em>
										</span>
										<small class="text-muted">Home</small>
									</a>
								</div>
							</div>
							<div *ngIf="userTypeName!='Pharmacist'" class="col-xs-4 h100">
								<div class="pv">
									<a id="mailboxRouteId" [routerLink]="['/app/mailbox/folder/inbox']">
										<span class="block mb">
											<em class="fa fa-envelope-o fa-2x text-primary"></em>
										</span>
										<small class="text-muted">Mailbox</small>
									</a>
								</div>
							</div>
							<!-- <div *ngIf="userTypeName!='Pharmacist'" class="col-xs-4 h100">
								<div class="pv">
									<a id="genEveRouteId" [routerLink]="['/app/generateEvent']">
										<span class="block mb"><i style="font-size:28px;" class="fa fa-phone"></i>
										</span>
										<small class="text-muted"><i aria-hidden="true" class="fa fa-plus"></i> Event</small>
									</a>
								</div>
							</div> -->
							<div *ngIf="userTypeName!='Pharmacist'" class="col-xs-4 h100">
								<div class="pv">
									<a id="usersRouteId" [routerLink]="['/app/admin/listUsers']">
										<span class="block mb">
											<em class="fa fa-user-o fa-2x text-muted"></em>
										</span>
										<small class="text-muted">Users</small>
									</a>
								</div>
							</div>
							<div *ngIf="userTypeName!='Pharmacist'" class="col-xs-4 h100">
								<div class="pv">
									<a id="accessRouteId" [routerLink]="['/app/admin/listAccess']">
										<span class="block mb">
											<em class="fa fa-lock fa-2x text-amber"></em>
										</span>
										<small class="text-muted">Access</small>
									</a>
								</div>
							</div>
							<!-- <div *ngIf="userTypeName!='Pharmacist'" class="col-xs-4 h100">
								<div class="pv">
									<a id="scheduleRouteId" [routerLink]="['/app/assignDoctor']">
										<span class="block mb">
											<em class="fa fa-stethoscope fa-2x text-pink"></em>
										</span>
										<small class="text-muted">Schedule</small>
									</a>
								</div>
							</div> -->
							<div class="col-xs-4 h100">
								<div class="pv">
									<a id="maincompanyRouteId" [routerLink]="['/app/admin/main-company-list']">
										<span class="block mb">
											<em class="fa fa-building-o fa-2x text-amber"></em>
										</span>
										<small class="text-muted">Company List</small>
									</a>
								</div>
							</div>
							<div class="col-xs-4 h100">
								<div class="pv">
									<a id="companyRouteId" [routerLink]="['/app/admin/listCompany']">
										<span class="block mb">
											<em class="fa fa-th-large fa-2x text-amber"></em>
										</span>
										<small class="text-muted">Fleet List</small>
									</a>
								</div>
							</div>
							<div *ngIf="userTypeName!='Pharmacist'" class="col-xs-4 h100">
								<div class="pv">
									<a id="QnRouteId" [routerLink]="['/app/questionSetup']">
										<span class="block mb">
											<em class="fa fa-gear fa-2x text-success"></em>
										</span>
										<small class="text-muted">Question Master</small>
									</a>
								</div>
							</div>
							<!-- <div *ngIf="userTypeName!='Pharmacist'" class="col-xs-4 h100">
								<div class="pv">
									<a id="apptRouteId" [routerLink]="['/app/admin/listAppointments']">
										<span class="block mb">
											<em class="fa fa-calendar fa-2x text-danger"></em>
										</span>
										<small class="text-muted">Appointment List</small>
									</a>
								</div>
							</div> -->
							<div matTooltip="Point Of Contact" class="col-xs-4 h100">
								<div class="pv">
									<a id="pocRouteId" [routerLink]="['/app/admin/listPOC']">
										<span class="block mb">
											<em class="fa fa-book fa-2x text-info"></em>
										</span>
										<small class="text-muted">POC</small>
									</a>
								</div>
							</div>
							<!-- <div matTooltip="Call List" class="col-xs-4 h100">
								<div class="pv">
									<a id="callRouteId" [routerLink]="['/app/admin/listCallDetails']">
										<span class="block mb">
											<em class="fa fa-phone fa-2x text-primary"></em>
										</span>
										<small class="text-muted">Call List</small>
									</a>
								</div>
							</div> -->
							<div matTooltip="Medicine Master" class="col-xs-4 h100">
								<div class="pv">
									<a id="medRouteId" [routerLink]="['/app/admin/listMedicineMaster']">
										<span class="block mb">
											<em class="fa fa-medkit fa-2x text-default"></em>
										</span>
										<small class="text-muted">Medicines</small>
									</a>
								</div>
							</div>
							<div matTooltip="Seafarer Master" class="col-xs-4 h100">
								<div class="pv">
									<a id="seafarerRouteId" [routerLink]="['/app/admin/listSeafarer','empty','empty']">
										<span class="block mb">
											<em class="fa fa-list fa-2x text-success"></em>
										</span>
										<small class="text-muted">Seafarers</small>
									</a>
								</div>
							</div>
							<!-- <div matTooltip="HP Registrations" class="col-xs-4 h100">
								<div class="pv">
									<a id="hpRegistrationRouteId" [routerLink]="['/app/admin/hpRegistrationList']">
										<span class="block mb">
											<em class="fa fa-registered fa-2x text-danger"></em>
										</span>
										<small class="text-muted">HP Registrations</small>
									</a>
								</div>
							</div> -->
							<div matTooltip="Flag State" class="col-xs-4 h100">
								<div class="pv">
									<a id="FlagStateId" [routerLink]="['/app/admin/flagstate']">
										<span class="block mb">
											<em class="fa fa-flag fa-2x text-success"></em>
										</span>
										<small class="text-muted">Flag State</small>
									</a>
								</div>
							</div>
							<div matTooltip="Event Ranks" class="col-xs-4 h100">
								<div class="pv">
									<a id="RanksId" [routerLink]="['/app/admin/ranks']">
										<span class="block mb">
											<em class="fa fa-star fa-2x text-primary"></em>
										</span>
										<small class="text-muted">Event Ranks</small>
									</a>
								</div>
							</div>
							<div matTooltip="Nationality" class="col-xs-4 h100">
								<div class="pv">
									<a id="NationalityId" [routerLink]="['/app/admin/nationality']">
										<span class="block mb">
											<em class="fa fa-flag-o fa-2x text-info"></em>
										</span>
										<small class="text-muted">Nationality</small>
									</a>
								</div>
							</div>
							<div matTooltip="Mail Templates" class="col-xs-4 h100">
								<div class="pv">
									<a id="TemplateId" [routerLink]="['/app/admin/templates']">
										<span class="block mb">
											<em class="fa fa-file-text-o fa-2x text-danger"></em>
										</span>
										<small class="text-muted">Mail Templates</small>
									</a>
								</div>
							</div>
							<div matTooltip="Owners" class="col-xs-4 h100">
								<div class="pv">
									<a id="OwnersId" [routerLink]="['/app/admin/owners']">
										<span class="block mb">
											<em class="fa fa-user-circle-o fa-2x text-primary"></em>
										</span>
										<small class="text-muted">Owners</small>
									</a>
								</div>
							</div>
							<div matTooltip="Billing" class="col-xs-4 h100">
								<div class="pv">
									<a id="OwnersId" [routerLink]="['/app/billing']">
										<span class="block mb">
											<em class="fa fa-rupee fa-2x text-danger"></em>
										</span>
										<small class="text-muted">Billing</small>
									</a>
								</div>
							</div>
						</div>
					</ul>
				</li>
				<li class="navbar-text">
					<span class="text-bold fs_14 menu-color">Welcome, {{userName}}</span>
				</li>
				<li [routerLink]="['/app/admin/userProfile']" matTooltip="Profile" class="navbar-text" style="cursor: pointer;"><i
					style="font-size: 18px;" class="fa fa-user-circle-o menu-color"></i>
				</li>
				<!-- <li matTooltip="Scheduled Calls" class="dropdown" style="cursor: pointer;">
					<a data-toggle="modal" data-target="#followUpModalHeadBar" class="dropdown-toggle" id="followUpToggle">
						<em class="fa fa-calendar-plus-o"></em>
						<div class="label pull-right label-success">{{followupcnt || 0}}</div>
						<span class="visible-xs-inline ml">Scheduled Calls</span>
					</a>
				</li> -->
				<li matTooltip="SMR Form">
					<a href="../assets/img/SMR.pdf" download="SMR.pdf" target="_blank" id="smrFormId">
						<em class="fa fa-file-pdf-o text-bold menu-color"></em>
					</a>
				</li>
				<li matTooltip="Vessel List">
					<a id="vesselRouteId" [routerLink]="['/app/admin/listVessels']">
						<em class="fa fa-ship text-bold menu-color"></em>
					</a>
				</li>
				
				<li matTooltip="Emergency" style="cursor: pointer;">
					<a id="btnOpenEmergencyModel" (click)="openEmergencyModel()">
						<i class="fa fa-bell menu-color" style="font-size: 20px;"></i>
					</a>
				</li>
				<li matTooltip="Handover" style="cursor: pointer;">
					<a id="btnOpenHandoverModel" (click)="openHandoverModel()">
						<span class="menu-color">Handover</span>
					</a>
				</li>
			</ul>
			<ul class="nav navbar-nav hidden-material pull-right">
				<li>
					<a id="logOutId" (click)="logout();" matTooltip="Logout" style="cursor: pointer;">
						<em class="icon-power fa-fw menu-color"></em>
						<span class="visible-xs-inline ml">Logout</span>
					</a>
				</li>
			</ul>
		</div>
	</nav>
</header>
<section class="content" style="padding: 80px 10px 10px 10px;">
	<router-outlet></router-outlet>
</section>
<div class="footer">
	<p>© {{ currYear }} - Health Cube - V{{ projectVersion }} </p>
</div>
<div class="modal fade" id="followUpModalHeadBar" role="dialog">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" id="btnCloseFollowUpModelHeadBar">&times;</button>
				<h4 class="modal-title mb0">
					Scheduled Calls
					<button id="caseFollowUpRefreshBtn" (click)="caseFollowUpList()" class="btn btn-primary btn-xs mr pull-right" [disabled]="followupLoader">
						<i *ngIf="followupLoader" class="fa fa-spinner fa-spin mr"></i>
						Refresh
					</button>
				</h4>
			</div>
			<div class="modal-body pt0">
				<div class="table-responsive">
					<table class="table">
						<thead>
							<tr>
								<th>No</th>
								<th>CaseId</th>
								<th>Reason</th>
								<th>Created By</th>
								<th>Reminder Due At</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody *ngIf="folloUpList.length">
							<tr *ngFor="let data of folloUpList; let i = index">
								<td>{{i + 1}}</td>
								<td [routerLink]="['/app/eventDetails',data.eventId]" matTooltip="Case Details" style="cursor: pointer;" data-dismiss="modal">{{data.event_id}}</td>
								<td>{{data.follow_up_reason}}</td>
								<td>{{data.follow_up_by || &apos;---&apos;}}</td>
								<td>{{data.followUpTime}}</td>
								<td>
									<button class="btn btn-xs btn-success" (click)="doneFollowup(data.eventId,data.id,i)" [disabled]="data.follow_up_done_by || doneLoader==i">
										<i *ngIf="doneLoader==i" class="fa fa-spinner fa-spin mr"></i>
										Done
									</button>
								</td>
							</tr>
						</tbody>
						<tbody *ngIf="!folloUpList.length">
							<tr>
								<td colspan="6" class="text-center">Data Not Found</td>
							</tr>
						</tbody>
					</table>
					<div class="text-center">
						<button id="caseFollowUpAllBtn" [routerLink]="['/app/admin/followup']" data-dismiss="modal" class="btn btn-primary btn-xs">View All</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal fade" id="GlobalEmergencyModal" role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header bg-danger">
				Global Emergency
				<i style="cursor:pointer;" class="fa fa-times pull-right" data-dismiss="modal" (click)="closeEmergencyModel();" id="btnCloseEmergencyModel"></i>
			</div>
			<form [formGroup]="emergencyNoteForm" (ngSubmit)="submitEmergencyNote()">
				<div class="modal-body">
					<div class="form-group">
						<label>Notes</label>
						<textarea formControlName="emergencyNote" class="form-control" rows="5" maxlength="500"></textarea>
						<span style="color:red">
							{{emergencyNoteForm.controls['emergencyNote'].value.length||0}} / 500 characters
						</span>
					</div>
				</div>
				<div class="modal-footer">
					<button [disabled]="!emergencyNoteForm.valid" type="submit" class="btn btn-success btn-sm">Submit</button>
				</div>
			</form>
		</div>
	</div>
</div>
<div class="modal fade" id="handoverModal" role="dialog">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="closeHandoverModel();" id="btnCloseHandoverModel">&times;</button>
				<h4 class="modal-title">Handover<i *ngIf="fetchHandoverLoader" class="fa fa-spinner fa-spin ml-sm"></i></h4>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-md-12">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th style="width: 60%;">Notes</th>
									<th style="width: 20%;">Added On</th>
									<th style="width: 20%;">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let note of handoverList;let i = index;">
									<td>
										<p *ngIf="!note.isEdit">{{note.handover_notes}}</p>
										<input *ngIf="note.isEdit" type="text" class="form-control" placeholder="Enter Notes" id="handover_notes{{i}}" [(ngModel)]="note.handover_notes" maxlength="{{handoverMaxLength}}" />
										<p style="color:red" *ngIf="note.isEdit">
											{{note.handover_notes?.length||0}}/{{handoverMaxLength}} characters
										</p>
									</td>
									<td>
										{{GetDateStringFromPickerDate1(note.created_at)}}
									</td>
									<td>
										<button class="btn btn-xs btn-danger" matTooltip="Delete" (click)="deleteHandover(i)">
											<i *ngIf="deleteHandoverLoader==i" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
											<i *ngIf="deleteHandoverLoader!=i" class="fa fa-trash" aria-hidden="true"></i>
										</button>
										<button class="btn btn-xs btn-primary ml-sm" matTooltip="{{note.isEdit?'Cancel':'Edit'}}" (click)="toggleEditHandover(i)">
											<i *ngIf="!note.isEdit" class="fa fa-pencil" aria-hidden="true"></i>
											<i *ngIf="note.isEdit" class="fa fa-times" aria-hidden="true"></i>
										</button>
										<button *ngIf="note.isEdit" class="btn btn-xs btn-success ml-sm" matTooltip="Update" (click)="updateHandover(i)" [disabled]="note.handover_notes?.length==0 || note.handover_notes?.length > handoverMaxLength">
											<i *ngIf="editHandoverLoader==i" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
											<i *ngIf="editHandoverLoader!=i" class="fa fa-save" aria-hidden="true"></i>
										</button>
									</td>
								</tr>
								<tr *ngIf="handoverList.length==0">
									<td colspan="3" class="text-center">No notes found</td>
								</tr>
								<tr *ngIf="handoverList.length">
									<td colspan="3" class="text-center">
										<button type="button" class="btn btn-success btn-sm" (click)="sendHandover();" [disabled]="sendHandoverLoader">
											<i *ngIf="sendHandoverLoader" class="fa fa-spinner fa-spin mr" aria-hidden="true"></i>
											<i *ngIf="!sendHandoverLoader" class="fa fa-paper-plane mr" aria-hidden="true"></i>
											Send Handover
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="row">
					<div class="col-md-10">
						<label>Add Notes:</label>
						<input type="text" class="form-control" placeholder="Enter Notes" id="new_handover_notes" [(ngModel)]="handoverNotes" maxlength="{{handoverMaxLength}}" />
						<p style="color:red">
							{{handoverNotes?.length||0}}/{{handoverMaxLength}} characters
						</p>
						<p style="color:red" *ngIf="handoverNotes?.length > handoverMaxLength">
							{{handoverMaxLength}} characters max
						</p>
					</div>
					<div class="col-md-2">
						<br>
						<button type="button" class="btn btn-success btn-sm mt-sm" matTooltip="Create Note" (click)="createHandover();" [disabled]="handoverNotes?.length==0 || handoverNotes?.length > handoverMaxLength">
							<i *ngIf="createHandoverLoader" class="fa fa-spinner fa-spin mr"></i>
							Add
						</button>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-primary btn-sm" (click)="getHandoverList();" id="btnRefreshHandoverModel">Refresh</button>
				<button type="button" class="btn btn-danger btn-sm ml-sm" (click)="closeHandoverModel();" id="btnCloseHandoverModel1">Close</button>
			</div>
		</div>
	</div>
</div>