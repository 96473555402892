<ol class="breadcrumb">
	<li class="breadcrumb-item" *ngFor="let item of breadcrumbList; let i = index"
		[class.active]="i===breadcrumbList.length-1">
		<a [routerLink]="item.path" id="breadcrumbLink{{i}}" *ngIf="i!==breadcrumbList.length-1">
			<b>{{ item.name }}</b>
		</a>
		<span *ngIf="i===breadcrumbList.length-1"><b>{{ item.name }}</b></span>
	</li>
</ol>
<div class="row" *ngIf="moduleAccess=='Full' || moduleAccess == 'Readonly'">
	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
		<div class="panel panel-info">
			<div class="panel-heading">{{pagetitle}}</div>
			<div class="panel-body p-lg">
				<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="saveRecord()" #modform="ngForm">
					<div class="row mb-lg">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span>Invoice No.:</label>
							<input type="text" class="form-control" id="invoice_no" formControlName="invoice_no"
								placeholder="Invoice No." />
							<small
								*ngIf="f.invoice_no.invalid && (modform.submitted || f.invoice_no.touched || f.invoice_no.dirty)"
								style="color: maroon;">
								<span *ngIf="f.invoice_no.errors?.required">
									Required
								</span>
								<span *ngIf="f.invoice_no.errors?.incorrect">
									{{customError.invoice_no}}
								</span>
							</small>
						</div>
						<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span>Invoice Date:</label>
							<div class="mat-datepicker-container">
								<input id="invoice_date" [matDatepicker]="invoice_date_picker"
									(click)="invoice_date_picker.open()" formControlName="invoice_date" readonly
									class="form-control" matInput (dateChange)="invoiceDateChanged()">
								<mat-datepicker-toggle matSuffix [for]="invoice_date_picker"></mat-datepicker-toggle>
							</div>
							<mat-datepicker #invoice_date_picker></mat-datepicker>
							<small
								*ngIf="f.invoice_date.invalid && (modform.submitted || f.invoice_date.touched || f.invoice_date.dirty)"
								style="color: maroon;">
								<span *ngIf="f.invoice_date.errors?.required">
									Required
								</span>
								<span *ngIf="f.invoice_date.errors?.incorrect">
									{{customError.invoice_date}}
								</span>
							</small>
						</div>
						<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span>From Date:</label>
							<div class="mat-datepicker-container">
								<input id="fromDate" [matDatepicker]="fromDate_picker" (click)="fromDate_picker.open()"
									formControlName="fromDate" readonly class="form-control" matInput
									(dateChange)="calculateMonths()">
								<mat-datepicker-toggle matSuffix [for]="fromDate_picker"></mat-datepicker-toggle>
							</div>
							<mat-datepicker #fromDate_picker></mat-datepicker>
							<small
								*ngIf="f.fromDate.invalid && (modform.submitted || f.fromDate.touched || f.fromDate.dirty)"
								style="color: maroon;">
								<span *ngIf="f.fromDate.errors?.required">
									Required
								</span>
								<span *ngIf="f.fromDate.errors?.incorrect">
									{{customError.fromDate}}
								</span>
							</small>
						</div>
						<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span>To Date:</label>
							<div class="mat-datepicker-container">
								<input id="toDate" [matDatepicker]="toDate_picker" (click)="toDate_picker.open()"
									formControlName="toDate" readonly class="form-control" matInput
									(dateChange)="calculateMonths()">
								<mat-datepicker-toggle matSuffix [for]="toDate_picker"></mat-datepicker-toggle>
							</div>
							<mat-datepicker #toDate_picker></mat-datepicker>
							<small *ngIf="f.toDate.invalid && (modform.submitted || f.toDate.touched || f.toDate.dirty)"
								style="color: maroon;">
								<span *ngIf="f.toDate.errors?.required">
									Required
								</span>
								<span *ngIf="f.toDate.errors?.incorrect">
									{{customError.toDate}}
								</span>
							</small>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span>Months:</label>
							<input type="text" class="form-control" id="total_months" formControlName="total_months"
								placeholder="Total Months" />
							<small
								*ngIf="f.total_months.invalid && (modform.submitted || f.total_months.touched || f.total_months.dirty)"
								style="color: maroon;">
								<span *ngIf="f.total_months.errors?.required">
									Required
								</span>
								<span *ngIf="f.total_months.errors?.pattern">
									Invalid
								</span>
								<span *ngIf="f.total_months.errors?.incorrect">
									{{customError.total_months}}
								</span>
							</small>
						</div>
					</div>
					<div class="row mb-lg">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span>Due Date:</label>
							<div class="mat-datepicker-container">
								<input id="due_date" [matDatepicker]="due_date_picker" (click)="due_date_picker.open()"
									formControlName="due_date" readonly class="form-control" matInput>
								<mat-datepicker-toggle matSuffix [for]="due_date_picker"></mat-datepicker-toggle>
							</div>
							<mat-datepicker #due_date_picker></mat-datepicker>
							<small
								*ngIf="f.due_date.invalid && (modform.submitted || f.due_date.touched || f.due_date.dirty)"
								style="color: maroon;">
								<span *ngIf="f.due_date.errors?.required">
									Required
								</span>
								<span *ngIf="f.due_date.errors?.incorrect">
									{{customError.due_date}}
								</span>
							</small>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span>Amount:</label>
							<input type="text" class="form-control" id="amount" formControlName="amount"
								placeholder="Amount" />
							<small *ngIf="f.amount.invalid && (modform.submitted || f.amount.touched || f.amount.dirty)"
								style="color: maroon;">
								<span *ngIf="f.amount.errors?.required">
									Required
								</span>
								<span *ngIf="f.amount.errors?.pattern">
									Invalid
								</span>
								<span *ngIf="f.amount.errors?.incorrect">
									{{customError.amount}}
								</span>
							</small>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span>Currency:</label>
							<ng-select id="Currency" [items]="currencyList" bindLabel="name" bindValue="name"
								[clearable]="true" placeholder="Currency" formControlName="Currency">
							</ng-select>
							<small
								*ngIf="f.Currency.invalid && (modform.submitted || f.Currency.touched || f.Currency.dirty)"
								style="color: maroon;">
								<span *ngIf="f.Currency.errors?.required">
									Required
								</span>
								<span *ngIf="f.Currency.errors?.incorrect">
									{{customError.Currency}}
								</span>
							</small>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div class="row">
								<div [ngClass]="{'col-md-12 pr-0':!showNoOfVessels,'col-md-6 pr-0':showNoOfVessels}">
									<label><span style="color: #b40a09;">*&nbsp;</span>Company:</label>
									<ng-select [items]="companyListObs$ | async" bindLabel="company_name"
										[clearable]="true" formControlName="company_id_id" id="company_id_id"
										[trackByFn]="trackByFn" [minTermLength]="minLengthTerm" [loading]="compLoading"
										typeToSearchText="Type to search" [typeahead]="compInput$"
										(change)="companyChanged();showHideNoOfVessel();">
									</ng-select>
									<small
										*ngIf="f.company_id_id.invalid && (modform.submitted || f.company_id_id.touched || f.company_id_id.dirty)"
										style="color: maroon;">
										<span *ngIf="f.company_id_id.errors?.required">
											Required
										</span>
										<span *ngIf="f.company_id_id.errors?.incorrect">
											{{customError.company_id_id}}
										</span>
									</small>
								</div>
								<div class="col-md-6 pl-0" *ngIf="showNoOfVessels">
									<label><span style="color: #b40a09;">*&nbsp;</span>No. of Vessels:</label>
									<input type="text" class="form-control" id="no_of_vessels" formControlName="no_of_vessels"
										placeholder="No. of Vessels" />
									<small
										*ngIf="f.no_of_vessels.invalid && (modform.submitted || f.no_of_vessels.touched || f.no_of_vessels.dirty)"
										style="color: maroon;">
										<span *ngIf="f.no_of_vessels.errors?.required">
											Required
										</span>
										<span *ngIf="f.no_of_vessels.errors?.pattern">
											Invalid
										</span>
										<span *ngIf="f.no_of_vessels.errors?.incorrect">
											{{customError.no_of_vessels}}
										</span>
									</small>
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-lg">
						<div class="col-lg-3 col-sm-3 col-sm-12 col-xs-12">
							<label class="control-label">Fleet:</label>
							<ng-select [items]="fleetListObs$ | async" bindLabel="com_name" [clearable]="true"
								formControlName="fleet" id="fleet" [trackByFn]="trackByFnFleet"
								[minTermLength]="minLengthTermFleet" [loading]="fleetLoading"
								typeToSearchText="Type to search" [typeahead]="fleetInput$" (change)="fleetChanged()">
							</ng-select>
							<small *ngIf="f.fleet.invalid && (modform.submitted || f.fleet.touched || f.fleet.dirty)"
								style="color: maroon;">
								<span *ngIf="f.fleet.errors?.incorrect">
									{{customError.fleet}}
								</span>
							</small>
						</div>
						<div class="col-lg-3 col-sm-3 col-sm-12 col-xs-12">
							<label class="control-label">Owner:</label>
							<ng-select [items]="owners" bindLabel="ownername" bindValue="id" [clearable]="true"
								placeholder="Select" id="owner" name="owner" formControlName="owner"
								(change)="ownerChanged()">
							</ng-select>
							<small *ngIf="f.owner.errors && (modform.submitted || f.owner.touched || f.owner.dirty)"
								style="color: maroon;">
								<span *ngIf="f.owner.errors?.incorrect">
									{{customError.owner}}
								</span>
							</small>
						</div>
						<!-- <div class="col-lg-3 col-sm-3 col-sm-12 col-xs-12">
							<label class="control-label">Subscription Date:</label>
							<div class="mat-datepicker-container">
								<input id="Subscription_Date" [matDatepicker]="subDatePicker"
									(click)="subDatePicker.open()" formControlName="Subscription_Date" readonly
									class="form-control" matInput (change)="subsDateChanged()">
								<mat-datepicker-toggle matSuffix [for]="subDatePicker"></mat-datepicker-toggle>
							</div>
							<mat-datepicker #subDatePicker></mat-datepicker>
							<small
								*ngIf="f.Subscription_Date.errors && (modform.submitted || f.Subscription_Date.touched || f.Subscription_Date.dirty)"
								style="color: maroon;">
								<span *ngIf="f.Subscription_Date.errors?.incorrect">
									{{customError.Subscription_Date}}
								</span>
							</small>
						</div> -->
						<div class="col-lg-3 col-sm-3 col-sm-12 col-xs-12">
							<label class="control-label">Subscription Status:</label>
							<ng-select [items]="subscriptionStatusList" [clearable]="true" placeholder="Select"
								id="Subscription_Status" name="Subscription_Status"
								formControlName="Subscription_Status" (change)="subsStatusChanged()">
							</ng-select>
							<small
								*ngIf="f.Subscription_Status.errors && (modform.submitted || f.Subscription_Status.touched || f.Subscription_Status.dirty)"
								style="color: maroon;">
								<span *ngIf="f.Subscription_Status.errors?.incorrect">
									{{customError.Subscription_Status}}
								</span>
							</small>
						</div>
						<div class="col-lg-3 col-sm-3 col-sm-12 col-xs-12" *ngIf="!showNoOfVessels">
							<label class="control-label">Search Vessel:</label>
							<input id="searchVesselId" placeholder="Search Vessel" [formControl]="searchControl"
								class="form-control" />
						</div>
					</div>
					<div class="row mb-lg" *ngIf="savedVessels.length">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<hr
								style="margin-top: 10px;margin-bottom: 10px;border: 0;border-top: 1px solid #8394a97d;" />
							<h4>Saved Vessels</h4>
							<table class="table">
								<thead>
									<tr class="table_head">
										<th>#</th>
										<th>Vessel Name</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let v of savedVessels;let i = index;" class="table_td">
										<td>{{ i+1 }}</td>
										<td>{{v.vesselName}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="row mb-lg" *ngIf="!showNoOfVessels">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<hr
								style="margin-top: 10px;margin-bottom: 10px;border: 0;border-top: 1px solid #8394a97d;" />
							<h4>Select Vessels</h4>
							<div class="table-responsive">
								<cdk-virtual-scroll-viewport itemSize="50" class="infinite-viewport">
									<table class="table">
										<thead>
											<tr class="table_head">
												<th style="width: 10% !important;">
													<div class="checkbox c-checkbox" style="width: 100% !important;">
														<label>
															<input type="checkbox" id="checkAll" [(ngModel)]="checkAll"
																[ngModelOptions]="{standalone: true}"
																(change)="checkUncheckAll(checkAll)" />
															<span class="fa fa-check"></span>
														</label>
													</div>
												</th>
												<th style="width: 11% !important;">Company</th>
												<th style="width: 11% !important;">Fleet</th>
												<th style="width: 11% !important;">Vessel Name</th>
												<th style="width: 13% !important;">Email</th>
												<th style="width: 11% !important;">Flagstate</th>
												<th style="width: 11% !important;">Owner</th>
												<th style="width: 11% !important;">Subscription Date</th>
												<th style="width: 11% !important;">Subscription Status</th>
											</tr>
										</thead>
										<tbody>
											<tr *cdkVirtualFor="let vess of vessellist; let i = index" class="table_td">
												<td>
													<div class="checkbox c-checkbox" style="width: 100% !important;">
														<label>
															<input type="checkbox" id="check{{i}}"
																[(ngModel)]="vess.checked"
																[ngModelOptions]="{standalone: true}"
																(change)="vesselSelected(vess.id,vess.checked)" />
															<span class="fa fa-check"></span>
														</label>
													</div>
												</td>
												<td class="text-uppercase">{{vess.MainCompanyName}}</td>
												<td class="text-uppercase">{{vess.companyName}}</td>
												<td class="text-uppercase">{{vess.vesselName}}</td>
												<td>{{vess.emailId}}</td>
												<td>{{vess.flagstate}}</td>
												<td>{{vess.ownername}}</td>
												<td>{{fixDate(vess.Subscription_Date)}}</td>
												<td>{{vess.Subscription_Status}}</td>
											</tr>
											<tr *ngIf="!vessellist.length &amp;&amp; !isLoading">
												<td colspan="9" class="text-center text-bold">Data Not Found.</td>
											</tr>
											<tr *ngIf="isLoading">
												<td colspan="9" class="text-center text-bold"><i aria-hidden="true"
														class="fa fa-spinner fa-spin"></i>
													Loading more vessels.
												</td>
											</tr>
											<tr *ngIf="!isLoading">
												<td colspan="9" class="text-center text-bold">
													<button title="Load More" type="button"
														(click)="getVesselList(false)"
														class="btn btn-sm btn-primary">Load More</button>
												</td>
											</tr>
										</tbody>
									</table>
								</cdk-virtual-scroll-viewport>
							</div>
						</div>
					</div>
					<div class="row mb-lg">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
							<hr
								style="margin-top: 10px;margin-bottom: 10px;border: 0;border-top: 1px solid #8394a97d;" />
							<button *ngIf="moduleAccess == 'Full'" type="submit"
								[disabled]="showLoader || !modform.dirty" id="btnSubmit" ripple=""
								class="mr mb-sm btn btn-sm btn-success">
								<span class="mr-sm">
									<i *ngIf="showLoader" aria-hidden="true" class="fa fa-spinner fa-spin"></i>
									<i *ngIf="!showLoader" aria-hidden="true" class="fa fa-save"></i>
								</span>
								<span>Save</span>
							</button>
							<button type="button" id="btnCancel" class="mr mb-sm btn btn-sm btn-default"
								[routerLink]="['/app/billing']">Cancel</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
<div *ngIf="moduleAccess !=='Full' && moduleAccess != 'Readonly'">
	<div class="text-center">
		<h4>You don&apos;t have access to this section, please contact administrator</h4>
	</div>
</div>