<form *ngIf="step==1" role="form" [ngStyle]="{'background-image':'url(../assets/img/cube_bg.png)','height':ScreenHeight+'px','background-repeat': 'round'}" class="mb-lg" [formGroup]="emailForm" #emailform="ngForm" (ngSubmit)="submitEmail()">
	<div class="container container-sm animated fadeInDown">
		<div class="center-block mt100">
			<div style="margin-left: 10%;background-color:white;" class="col-sm-10">
				<h2 style="margin-top: 20px;">Forgot your password?</h2>
				<div style="line-height: 2;" class="mt-lg fs_14">Please enter your registered email address.<br/>We will send an OTP for verification.</div>
				<div class="mt20">
					<div class="row">
						<div class="col-md-8">
							<div class="form-group has-feedback">
								<input id="email" formControlName="email" placeholder="Your email" class="form-control" />
								<span class="fa fa-envelope form-control-feedback text-muted"></span>
							</div>
							<small *ngIf="f1.email.errors && (emailform.submitted || f1.email.touched || f1.email.dirty)" style="color: maroon;">
								<span *ngIf="f1.email.errors.required">
									Please enter email.
								</span>
								<span *ngIf="f1.email.errors.pattern">
									Please enter valid email! Eg. demo&#64;mail.com
								</span>
							</small>
						</div>
						<div class="col-md-4">
							<button id="sendResteId" [disabled]="emailForm.invalid || loadershow" type="submit" class="btn btn-danger btn-block"><i *ngIf="loadershow" aria-hidden="true" class="fa fa-spinner fa-spin"></i> Get OTP</button>
						</div>
					</div>
				</div>
				<div *ngIf="resetPasswordError" class="col-sm-12">
					<div class="text-center">
						<h5 class="text-danger">{{resetPasswordError}}</h5>
						<!-- <div class="text-danger">Please check the email address, or contact our technical support team at support&#64;3cubeservices.com</div> -->
					</div>
				</div>
				<div class="col-sm-12 text-center text-black text-bold mt-lg">Powered By<a href="http://3cubeservices.com/" target="_blank">3CUBE</a> © {{year}}</div>
			</div>
		</div>
	</div>
</form>
<form *ngIf="step==2" role="form" [ngStyle]="{'background-image':'url(../assets/img/cube_bg.png)','height':ScreenHeight+'px','background-repeat': 'round'}" class="mb-lg" [formGroup]="otpForm" #otpform="ngForm" (ngSubmit)="submitOTP()">
	<div class="container container-sm animated fadeInDown">
		<div class="center-block mt100">
			<div style="margin-left: 10%;background-color:white;" class="col-sm-10">
				<h2 style="margin-top: 20px;">Forgot your password?</h2>
				<div style="line-height: 2;" class="mt-lg fs_14">Please enter the OTP received on your registered email address.</div>
				<div class="mt20">
					<div class="row">
						<div class="col-md-8">
							<div class="form-group has-feedback">
								<input id="otp" formControlName="otp" placeholder="Enter OTP" class="form-control" />
								<span class="fa fa-lock form-control-feedback text-muted"></span>
							</div>
							<small *ngIf="f2.otp.errors && (otpform.submitted || f2.otp.touched || f2.otp.dirty)" style="color: maroon;">
								<span *ngIf="f2.otp.errors.required">
									Please enter OTP.
								</span>
								<span *ngIf="f2.otp.errors.pattern">
									OTP is 4 digits numeric.
								</span>
							</small>
						</div>
						<div class="col-md-4">
							<button id="sendResteId" [disabled]="otpForm.invalid || loadershow" type="submit" class="btn btn-danger btn-block"><i *ngIf="loadershow" aria-hidden="true" class="fa fa-spinner fa-spin"></i> Verify</button>
						</div>
					</div>
				</div>
				<div *ngIf="resetPasswordError" class="col-sm-12">
					<div class="text-center">
						<!-- <h5 class="text-danger">{{resetPasswordError}}</h5> -->
						<div class="text-danger">Invalid OTP</div>
					</div>
				</div>
				<div class="col-sm-12 text-center text-black text-bold mt-lg">Powered By<a href="http://3cubeservices.com/" target="_blank">3CUBE</a> © {{year}}</div>
			</div>
		</div>
	</div>
</form>
<form *ngIf="step==3" role="form" [ngStyle]="{'background-image':'url(../assets/img/cube_bg.png)','height':ScreenHeight+'px','background-repeat': 'round'}" class="mb-lg" [formGroup]="recoverForm" #recform="ngForm" (ngSubmit)="submitForm()">
	<div class="container container-sm animated fadeInDown">
		<div class="center-block mt100">
			<div style="margin-left: 10%;background-color:white;" class="col-sm-8">
				<h2 class="text-center" style="margin-top: 20px;">Reset your password</h2>
				<div style="line-height: 2;" class="mt-lg fs_14 text-center">Please choose a new password</div>
				<div class="mt20">
					<div class="row">
						<div class="col-md-12">
							<div class="form-group has-feedback">
								<input id="password" placeholder="Password" type="password" formControlName="password" class="form-control" />
								<span class="fa fa-lock form-control-feedback text-muted"></span>
								<small *ngIf="f3.password.errors && (recform.submitted || f3.password.touched || f3.password.dirty)" style="color: maroon;">
									<span *ngIf="f3.password.errors.required">
										Please enter something.
									</span>
									<div [ngClass]="f3.password.errors.minlengtherror?'ErrorColor':'Suucesscolor'">
										Password Must have 8 characters long.
									</div>
									<div [ngClass]="f3.password.errors.capitalerror?'ErrorColor':'Suucesscolor'">
										Password Must Have 1 Capital letter
									</div>
									<div [ngClass]="f3.password.errors.numericerror?'ErrorColor':'Suucesscolor'">
										Password must have 1 Numeric value
									</div>
								</small>
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group has-feedback">
								<input id="confirmPassword" placeholder="Confirm Password" type="password" formControlName="confirmPassword" class="input-filter form-control" />
								<span class="fa fa-lock form-control-feedback text-muted"></span>
								<small *ngIf="f3.confirmPassword.errors && (recform.submitted || f3.confirmPassword.touched || f3.confirmPassword.dirty)" style="color: maroon;">
									<span *ngIf="f3.confirmPassword.errors.required">
										Please enter something.
									</span>
								</small>
								<small *ngIf="recform.errors && (recform.submitted || f3.confirmPassword.touched || f3.confirmPassword.dirty)">
									<p *ngIf="recform.errors.passwordNotMatch" style="color:maroon" role="alert">Password and Confirm Password not matched!<i style="font-size: 22px;color: red;" class="fa fa-times faa-ring animated"></i></p>
								</small>
							</div>
						</div>
						<div class="col-md-6 col-md-offset-3">
							<button id="resetSendId" [disabled]="recoverForm.invalid || loadershow" type="submit" class="btn btn-danger btn-block"><i *ngIf="loadershow" aria-hidden="true" class="fa fa-spinner fa-spin"></i> Change Password</button>
						</div>
					</div>
				</div>
				<div *ngIf="resetPasswordError" class="col-sm-12">
					<div class="text-center">
						<h5 class="text-danger">{{resetPasswordError}}</h5>
					</div>
				</div>
				<div class="col-sm-12 text-center text-black text-bold mt-lg">Powered By 3CUBE © {{year}}</div>
			</div>
		</div>
	</div>
</form>
<form *ngIf="step==4" role="form" [ngStyle]="{'background-image':'url(../assets/img/cube_bg.png)','height':ScreenHeight+'px','background-repeat': 'round'}" class="mb-lg">
	<div class="container container-sm animated fadeInDown">
		<div class="center-block mt100">
			<div style="margin-left: 10%;background-color:white;" class="col-sm-8">
				<h2 class="text-center" style="margin-top: 20px;">
					Your password is updated successfully. <a [routerLink]="['/login']">Click here</a> to login.
				</h2>
				<div class="col-sm-12 text-center text-black text-bold mt-lg">Powered By 3CUBE © {{year}}</div>
			</div>
		</div>
	</div>
</form>