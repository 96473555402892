import * as i0 from '@angular/core';
import { Injectable, EventEmitter, forwardRef, Component, Input, Output, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
function NgToggleComponent_ng_container_4_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 7);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngStyle", ctx_r1.labelStyle);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.labelChecked, " ");
  }
}
function NgToggleComponent_ng_container_4_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngStyle", ctx_r2.labelStyle);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", ctx_r2.labelUnchecked, " ");
  }
}
function NgToggleComponent_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, NgToggleComponent_ng_container_4_span_1_Template, 2, 2, "span", 5)(2, NgToggleComponent_ng_container_4_span_2_Template, 2, 2, "span", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.toggled);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.toggled);
  }
}
const _c0 = a0 => ({
  "ng-toggle-focused": a0
});
class NgToggleConfig {}
NgToggleConfig.ɵfac = function NgToggleConfig_Factory(t) {
  return new (t || NgToggleConfig)();
};
NgToggleConfig.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NgToggleConfig,
  factory: NgToggleConfig.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgToggleConfig, [{
    type: Injectable
  }], null, null);
})();
const DEFAULT_COLOR_CHECKED = '#0099CC';
const DEFAULT_COLOR_UNCHECKED = '#e0e0e0';
const DEFAULT_LABEL_CHECKED = '';
const DEFAULT_LABEL_UNCHECKED = '';
const DEFAULT_SWITCH_COLOR = '#fff';
const DISABLED_COLOR = '#dbdbdb';
const DISABLED_BUTTON_COLOR = 'silver';
let nextUniqueId = 0;
class NgToggleComponent {
  constructor(config, _elementRef) {
    this.config = config;
    this._elementRef = _elementRef;
    this.value = this.config.value || true;
    this.name = this.config.name || '';
    this.disabled = this.config.disabled || false;
    this.height = this.config.height || 25;
    this.width = this.config.width || 45;
    this.margin = this.config.margin || 2;
    this.fontSize = this.config.fontSize || undefined;
    this.speed = this.config.speed || 300;
    this.color = this.config.color;
    this.switchColor = this.config.switchColor;
    this.labels = this.config.labels || true;
    this.fontColor = this.config.fontColor || undefined;
    this.values = this.config.values || {
      checked: true,
      unchecked: false
    };
    this.id = '';
    this.ariaLabel = null;
    this.ariaLabelledby = null;
    this.cssColors = false;
    this.change = new EventEmitter();
    this.valueChange = new EventEmitter();
    this.onChange = _ => {};
    this.onTouch = () => {};
    this._uniqueId = 'ng-toggle-' + ++nextUniqueId;
    this.id = this.id || this._uniqueId;
    this.ariaLabel = this.ariaLabel || this.name || this.id;
  }
  ngOnInit() {
    this.setToogle();
  }
  onInput(value) {
    this.value = value;
    this.onTouch();
    this.onChange(this.value);
  }
  writeValue(value) {
    this.value = value;
    this.setToogle();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  setToogle() {
    const value = this.value;
    let index = Object.values(this.values).findIndex(el => el == value);
    if (index > -1) this.toggled = Object.keys(this.values)[index] == 'checked' ? true : false;
  }
  ngOnChanges(changes) {
    for (const propName in changes) {
      const chng = changes[propName];
      if (propName == 'value') this.writeValue(chng.currentValue);
    }
  }
  get coreStyle() {
    return {
      width: px(this.width),
      height: px(this.height),
      transition: `all ${this.speed}ms`,
      backgroundColor: this.cssColors ? null : this.disabled ? this.colorDisabled : this.colorCurrent,
      borderRadius: px(Math.round(this.height / 2))
    };
  }
  get buttonRadius() {
    const radius = this.height - this.margin * 2;
    return radius > 0 ? radius : 0;
  }
  get distance() {
    return px(this.width - this.height + this.margin);
  }
  get buttonStyle() {
    const transition = `all ${this.speed}ms`;
    const margin = px(this.margin);
    const transform = this.toggled ? translate(this.distance, margin) : translate(margin, margin);
    let background = this.switchColor ? this.switchColorCurrent : null;
    background = this.disabled ? this.switchColorDisabled : background;
    return {
      width: px(this.buttonRadius),
      height: px(this.buttonRadius),
      transition,
      transform,
      background
    };
  }
  get labelStyle() {
    return {
      lineHeight: px(this.height),
      fontSize: this.fontSize ? px(this.fontSize) : null,
      color: this.fontColor ? this.fontColorCurrent : null
    };
  }
  get colorChecked() {
    let {
      color
    } = this;
    if (!isObject(color)) {
      return color || DEFAULT_COLOR_CHECKED;
    }
    return get(color, 'checked', DEFAULT_COLOR_CHECKED);
  }
  get colorUnchecked() {
    return get(this.color, 'unchecked', DEFAULT_COLOR_UNCHECKED);
  }
  get colorDisabled() {
    return get(this.color, 'disabled', DISABLED_COLOR);
  }
  get colorCurrent() {
    return this.toggled ? this.colorChecked : this.colorUnchecked;
  }
  get labelChecked() {
    return get(this.labels, 'checked', DEFAULT_LABEL_CHECKED);
  }
  get labelUnchecked() {
    return get(this.labels, 'unchecked', DEFAULT_LABEL_UNCHECKED);
  }
  get switchColorChecked() {
    return get(this.switchColor, 'checked', DEFAULT_SWITCH_COLOR);
  }
  get switchColorUnchecked() {
    return get(this.switchColor, 'unchecked', DEFAULT_SWITCH_COLOR);
  }
  get switchColorDisabled() {
    return get(this.switchColor, 'disabled', DISABLED_BUTTON_COLOR);
  }
  get switchColorCurrent() {
    if (!isObject(this.switchColor)) {
      return this.switchColor || DEFAULT_SWITCH_COLOR;
    }
    return this.toggled ? this.switchColorChecked : this.switchColorUnchecked;
  }
  get fontColorChecked() {
    return get(this.fontColor, 'checked', DEFAULT_SWITCH_COLOR);
  }
  get fontColorUnchecked() {
    return get(this.fontColor, 'unchecked', DEFAULT_SWITCH_COLOR);
  }
  get fontColorDisabled() {
    return get(this.fontColor, 'disabled', DEFAULT_SWITCH_COLOR);
  }
  get fontColorCurrent() {
    if (!isObject(this.fontColor)) {
      return this.fontColor || DEFAULT_SWITCH_COLOR;
    }
    if (this.disabled) {
      return this.fontColorDisabled;
    }
    return this.toggled ? this.fontColorChecked : this.fontColorUnchecked;
  }
  get label() {
    if (this.ariaLabelledby) {
      return this.ariaLabelledby;
    }
    return this.ariaLabel ? null : `${this._uniqueId}-label`;
  }
  toggle(event) {
    const toggled = !this.toggled;
    this.toggled = toggled;
    this.value = this.getValue(toggled);
    this.onTouch();
    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }
  getValue(key) {
    return key === true ? this.values['checked'] : this.values['unchecked'];
  }
  onFocus(event) {
    if (!this.focused && event.relatedTarget) {
      this.focused = true;
    }
  }
  onFocusout(event) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget)) {
      this.focused = false;
      this.onTouch();
    }
  }
}
NgToggleComponent.ɵfac = function NgToggleComponent_Factory(t) {
  return new (t || NgToggleComponent)(i0.ɵɵdirectiveInject(NgToggleConfig), i0.ɵɵdirectiveInject(i0.ElementRef));
};
NgToggleComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NgToggleComponent,
  selectors: [["ng-toggle"]],
  inputs: {
    value: "value",
    name: "name",
    disabled: "disabled",
    height: "height",
    width: "width",
    margin: "margin",
    fontSize: "fontSize",
    speed: "speed",
    color: "color",
    switchColor: "switchColor",
    labels: "labels",
    fontColor: "fontColor",
    values: "values",
    id: "id",
    ariaLabel: ["aria-label", "ariaLabel"],
    ariaLabelledby: ["aria-labelledby", "ariaLabelledby"],
    ariaDescribedby: ["aria-describedby", "ariaDescribedby"]
  },
  outputs: {
    change: "change",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NgToggleComponent),
    multi: true
  }]), i0.ɵɵNgOnChangesFeature],
  decls: 5,
  vars: 16,
  consts: [[1, "ng-toggle-switch", 3, "for"], ["type", "checkbox", "role", "checkbox", 1, "ng-toggle-switch-input", 3, "checked", "disabled", "change", "focusin", "focusout"], [1, "ng-toggle-switch-core", 3, "ngClass", "ngStyle"], [1, "ng-toggle-switch-button", 3, "ngStyle"], [4, "ngIf"], ["class", "ng-toggle-switch-label ng-toggle-left", 3, "ngStyle", 4, "ngIf"], ["class", "ng-toggle-switch-label ng-toggle-right", 3, "ngStyle", 4, "ngIf"], [1, "ng-toggle-switch-label", "ng-toggle-left", 3, "ngStyle"], [1, "ng-toggle-switch-label", "ng-toggle-right", 3, "ngStyle"]],
  template: function NgToggleComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "label", 0)(1, "input", 1);
      i0.ɵɵlistener("change", function NgToggleComponent_Template_input_change_1_listener($event) {
        return ctx.toggle($event);
      })("focusin", function NgToggleComponent_Template_input_focusin_1_listener($event) {
        return ctx.onFocus($event);
      })("focusout", function NgToggleComponent_Template_input_focusout_1_listener($event) {
        return ctx.onFocusout($event);
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(2, "div", 2);
      i0.ɵɵelement(3, "div", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(4, NgToggleComponent_ng_container_4_Template, 3, 2, "ng-container", 4);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("for", ctx.id);
      i0.ɵɵattribute("id", ctx.label);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("checked", ctx.value)("disabled", ctx.disabled);
      i0.ɵɵattribute("id", ctx.id)("name", ctx.name)("aria-label", ctx.ariaLabel)("aria-labelledby", ctx.label)("aria-describedby", ctx.ariaDescribedby)("aria-checked", ctx.toggled);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(14, _c0, ctx.focused))("ngStyle", ctx.coreStyle);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngStyle", ctx.buttonStyle);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.labels);
    }
  },
  dependencies: [i2.NgClass, i2.NgIf, i2.NgStyle],
  styles: ["label[_ngcontent-%COMP%]{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}.ng-toggle-switch[_ngcontent-%COMP%]{display:inline-block;position:relative;vertical-align:middle;-webkit-user-select:none;user-select:none;font-size:10px;cursor:pointer}.ng-toggle-switch[_ngcontent-%COMP%]   .ng-toggle-switch-input[_ngcontent-%COMP%]{opacity:0;position:absolute;width:1px;height:1px}.ng-toggle-switch[_ngcontent-%COMP%]   .ng-toggle-switch-label[_ngcontent-%COMP%]{position:absolute;top:0;font-weight:600;color:#fff;z-index:1}.ng-toggle-switch[_ngcontent-%COMP%]   .ng-toggle-switch-label.ng-toggle-left[_ngcontent-%COMP%]{left:10px}.ng-toggle-switch[_ngcontent-%COMP%]   .ng-toggle-switch-label.ng-toggle-right[_ngcontent-%COMP%]{right:10px}.ng-toggle-switch[_ngcontent-%COMP%]   .ng-toggle-switch-core[_ngcontent-%COMP%]{display:block;position:relative;box-sizing:border-box;outline:0;margin:0;transition:border-color .3s,background-color .3s;-webkit-user-select:none;user-select:none}.ng-toggle-switch[_ngcontent-%COMP%]   .ng-toggle-switch-core[_ngcontent-%COMP%]   .ng-toggle-switch-button[_ngcontent-%COMP%]{display:block;position:absolute;overflow:hidden;top:0;left:0;border-radius:100%;background-color:#fff;z-index:2}.ng-toggle-switch.disabled[_ngcontent-%COMP%]{pointer-events:none;opacity:.6}.ng-toggle-focused[_ngcontent-%COMP%]{box-shadow:0 0 4px 3px #999}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgToggleComponent, [{
    type: Component,
    args: [{
      selector: 'ng-toggle',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NgToggleComponent),
        multi: true
      }],
      template: "<label class=\"ng-toggle-switch\" [for]=\"id\" [attr.id]=\"label\">\r\n  <input\r\n    type=\"checkbox\"\r\n    class=\"ng-toggle-switch-input\"\r\n    [checked]=\"value\"\r\n    [disabled]=\"disabled\"\r\n    (change)=\"toggle($event)\"\r\n    (focusin)=\"onFocus($event)\"\r\n    (focusout)=\"onFocusout($event)\"\r\n    [attr.id]=\"id\"\r\n    [attr.name]=\"name\"\r\n    [attr.aria-label]=\"ariaLabel\"\r\n    [attr.aria-labelledby]=\"label\"\r\n    [attr.aria-describedby]=\"ariaDescribedby\"\r\n    [attr.aria-checked]=\"toggled\"\r\n    role=\"checkbox\"\r\n  >\r\n  <div\r\n    class=\"ng-toggle-switch-core\"\r\n    [ngClass]=\"{'ng-toggle-focused': focused}\"\r\n    [ngStyle]=\"coreStyle\"\r\n  >\r\n    <div\r\n      class=\"ng-toggle-switch-button\"\r\n      [ngStyle]=\"buttonStyle\">\r\n    </div>\r\n  </div>\r\n  <ng-container *ngIf=\"labels\">\r\n    <span\r\n      class=\"ng-toggle-switch-label ng-toggle-left\"\r\n      [ngStyle]=\"labelStyle\"\r\n      *ngIf=\"toggled\"\r\n    >\r\n      {{labelChecked}}\r\n    </span>\r\n    <span\r\n      class=\"ng-toggle-switch-label ng-toggle-right\"\r\n      [ngStyle]=\"labelStyle\"\r\n      *ngIf=\"!toggled\"\r\n    >\r\n      {{labelUnchecked}}\r\n    </span>\r\n  </ng-container>\r\n</label>",
      styles: ["label{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}.ng-toggle-switch{display:inline-block;position:relative;vertical-align:middle;-webkit-user-select:none;user-select:none;font-size:10px;cursor:pointer}.ng-toggle-switch .ng-toggle-switch-input{opacity:0;position:absolute;width:1px;height:1px}.ng-toggle-switch .ng-toggle-switch-label{position:absolute;top:0;font-weight:600;color:#fff;z-index:1}.ng-toggle-switch .ng-toggle-switch-label.ng-toggle-left{left:10px}.ng-toggle-switch .ng-toggle-switch-label.ng-toggle-right{right:10px}.ng-toggle-switch .ng-toggle-switch-core{display:block;position:relative;box-sizing:border-box;outline:0;margin:0;transition:border-color .3s,background-color .3s;-webkit-user-select:none;user-select:none}.ng-toggle-switch .ng-toggle-switch-core .ng-toggle-switch-button{display:block;position:absolute;overflow:hidden;top:0;left:0;border-radius:100%;background-color:#fff;z-index:2}.ng-toggle-switch.disabled{pointer-events:none;opacity:.6}.ng-toggle-focused{box-shadow:0 0 4px 3px #999}\n"]
    }]
  }], function () {
    return [{
      type: NgToggleConfig
    }, {
      type: i0.ElementRef
    }];
  }, {
    value: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    margin: [{
      type: Input
    }],
    fontSize: [{
      type: Input
    }],
    speed: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    switchColor: [{
      type: Input
    }],
    labels: [{
      type: Input
    }],
    fontColor: [{
      type: Input
    }],
    values: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input,
      args: ['aria-label']
    }],
    ariaLabelledby: [{
      type: Input,
      args: ['aria-labelledby']
    }],
    ariaDescribedby: [{
      type: Input,
      args: ['aria-describedby']
    }],
    change: [{
      type: Output
    }],
    valueChange: [{
      type: Output
    }]
  });
})();
const isObject = value => {
  return typeof value === 'object';
};
const has = (object, key) => {
  return isObject(object) && object.hasOwnProperty(key);
};
const get = (object, key, defaultValue) => {
  return has(object, key) ? object[key] : defaultValue;
};
const px = value => {
  return `${value}px`;
};
const translate = (x, y) => {
  return `translate(${x}, ${y})`;
};
class NgToggleModule {
  static forRoot(config = {}) {
    return {
      ngModule: NgToggleModule,
      providers: [{
        provide: NgToggleConfig,
        useValue: config
      }]
    };
  }
}
NgToggleModule.ɵfac = function NgToggleModule_Factory(t) {
  return new (t || NgToggleModule)();
};
NgToggleModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgToggleModule
});
NgToggleModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [NgToggleConfig],
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgToggleModule, [{
    type: NgModule,
    args: [{
      declarations: [NgToggleComponent],
      imports: [CommonModule],
      exports: [NgToggleComponent],
      providers: [NgToggleConfig]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-toogle
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgToggleComponent, NgToggleConfig, NgToggleModule, get, has, isObject, px, translate };

