import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';

import { Component, OnInit, ViewChild, NgZone, HostListener, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterOutlet } from "@angular/router";
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { password_validation } from '../shared/constants';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { MessagingService } from '../firebase/messaging.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Howl } from 'howler';

@Component({
	selector: 'app-link-number',
	templateUrl: './link-number.component.html',
	styleUrl: './link-number.component.css'
})
export class LinkNumberComponent implements OnInit {
	calledMobileNumber: any = null;
	vessellist: any[] = [];
	selectedVessel: any = null;
	saveContactLoader = false;
	fetchingVessels = false;

	constructor(private afMessaging: AngularFireMessaging, private _snackBar: MatSnackBar, private messagingService: MessagingService, private changeRef: ChangeDetectorRef, private observer: BreakpointObserver, private toastr: ToastrService, private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private zone: NgZone, private fb: FormBuilder) { }

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.calledMobileNumber = routeParams["number"];
			this.getVessels();
		});
	}

	getVessels() {
		this.fetchingVessels = true;
		this.apiService.fetchVessels().subscribe({
			next: (data: any) => {
				this.fetchingVessels = false;
				this.vessellist = data;
			}, error: (error) => {
				this.fetchingVessels = false;
				this.vessellist = [];
			}
		});
	}

	saveContact() {
		if (!this.selectedVessel) {
			this.toastr.error("Please select vessel", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		if (!this.calledMobileNumber) {
			this.toastr.error("No number to link", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		let payload = {
			number: this.calledMobileNumber,
			vessel_id: this.selectedVessel
		}
		this.saveContactLoader = true;
		this.apiService.saveContact(payload).subscribe({
			next: (data: any) => {
				this.saveContactLoader = false;
				this.toastr.success("Number linked to vessel", 'Success', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
				this.router.navigate(['/app/eventList']);
			}, error: (error) => {
				this.saveContactLoader = false;
				this.toastr.error("Something went wrong", 'Error', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
		});
	}
}
