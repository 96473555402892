import { Component, OnInit } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Howl } from 'howler';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
	playAlertNotify = new Howl({
		src: ['../assets/files/sound2.mp3']
	});
	constructor(private _snackBar: MatSnackBar, private router: Router, private afMessaging: AngularFireMessaging, private toastr: ToastrService) { }
	ngOnInit() {
		/* this.afMessaging.messages.subscribe((message) => {
			this._snackBar.open(message.notification?.body!, "View mail", {
				horizontalPosition: "end",
				verticalPosition: "bottom",
				duration: 3 * 1000,
			}).onAction().subscribe(() => {
				let url = message.fcmOptions?.link;
				if (url) {
					let path = url.split("/app/")[1];
					this.router.navigate(["/app/" + path]);
				}
			});
		}); */
	}

	testNotification() {
		let message = {
			"from": "251343964324",
			"messageId": "5dee9a8c-e2f4-45b0-8af6-fc95876baf46",
			"notification": {
				"title": "New Email Arrived",
				"body": "Hi, You got new email from Yogesh Kapase"
			},
			"fcmOptions": {
				"link": "https://hc-staging-microservice.rma-3cubemedicare.com/app/mailbox/folder/inbox/764324"
			}
		}
		this._snackBar.open(message.notification?.body, "View mail", {
			horizontalPosition: "end",
			verticalPosition: "bottom",
			duration: 3 * 1000,
		}).onAction().subscribe(() => {
			let url = message.fcmOptions?.link;
			if (url) {
				let path = url.split("/app/")[1];
				this.router.navigate(["/app/" + path]);
			}
		});
	}
}
