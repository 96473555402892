import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, debounceTime } from 'rxjs';
import { ApiService } from '../../api.service';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-billing-list',
	templateUrl: './billing-list.component.html',
	styleUrl: './billing-list.component.css'
})
export class BillingListComponent implements OnInit, AfterViewInit, OnDestroy {
	constructor(private activeRoute: ActivatedRoute, private apiService: ApiService, private toastr: ToastrService, private router: Router,) { }
	breadcrumbList = [
		{
			path: "/app/eventList",
			name: "Home"
		},
		{
			path: "/app/billing",
			name: "Invoice"
		}
	];
	recordList: any = [];
	isListFinished: boolean = false;
	isLoading: boolean = false;
	selectedflagId: any = [];
	moduleAccess = JSON.parse(localStorage.getItem("userAccess")!).Invoice;
	searchControl = new FormControl("");
	formCtrlSub!: Subscription;
	tableparams = {
		page: 0,
		limit: 20,
	};
	File_Storage_URL = environment.File_Storage_URL;
	currencyList: any = [];
	pdfDownloadLoader: any = null;

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.getList(false);
			this.getCurrencyList();
		});
	}

	ngAfterViewInit() {
		this.formCtrlSub = this.searchControl.valueChanges.pipe(debounceTime(1000)).subscribe(newValue => {
			this.tableparams.page = 0;
			this.isListFinished = false;
			this.recordList = [];
			this.getList(true);
		});
	}

	getList(isSearch: boolean) {
		if (!this.isListFinished && !this.isLoading) {
			let params = {
				per_page: this.tableparams.limit,
				page: this.tableparams.page,
				search: this.searchControl.value
			};
			this.isLoading = true;
			this.apiService.getBillingList(params).subscribe((data: any) => {
				this.isLoading = false;
				let records: any = data;
				for (let i = 0; i < records.length; i++) {
					records[i].paid_unpaid = records[i].paid_unpaid ? true : false;
					records[i].paid_unpaid_temp = records[i].paid_unpaid;
				}
				if (records.length == this.tableparams.limit) {
					this.tableparams.page += 1;
				} else {
					this.isListFinished = true;
				}
				if (isSearch) {
					this.recordList = [];
				}
				this.recordList = this.recordList.concat(records);
			}, (error) => {
				this.isLoading = false;
				this.isListFinished = true;
			});
		}
	}

	getCurrencyList() {
		this.apiService.currencyList().subscribe((data: any) => {
			this.currencyList = data;
		}, (error) => {
			this.currencyList = [];
			if (error.status == 401) { return }
		});
	}

	getCurrencyIcon(currency: any) {
		let len = this.currencyList.length;
		for (let i = 0; i < len; i++) {
			if (this.currencyList[i].name == currency) {
				return this.currencyList[i].icon;
			}
		}
		return "";
	}

	deleteRecord(Id: any, index: number) {
		Swal.fire({
			title: 'Warning !!!',
			text: 'Are you sure you want to delete invoice?',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: 'rgb(221, 107, 85)',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.apiService.deleteBilling(Id).subscribe(data => {
					this.recordList.splice(index, 1);
					this.recordList = [...this.recordList];
					Swal.fire('Success', 'Invoice Deleted Successfully.', 'success');
				}, (error) => {
					this.toastr.error("Unable to delete invoice.", 'Error', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	ngOnDestroy() {
		this.formCtrlSub.unsubscribe();
	}

	clearDelete() {
		this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
			this.router.navigate(['/app/billing']);
		});
	}

	showIsPaidLoader: any = null;
	changeIsPaid(po: any, index: number) {
		let isPaidStr = "";
		if (po.paid_unpaid) {
			isPaidStr = "Paid";
		} else {
			isPaidStr = "Unpaid";
		}
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to change status to <strong>' + isPaidStr + '</strong>?',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Continue',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#f23022'
		}).then((result) => {
			if (result.value) {
				this.showIsPaidLoader = index;
				this.apiService.paid_unpaid_invoice({ id: po.id, status: po.paid_unpaid ? 1 : 0 }).subscribe(data => {
					this.showIsPaidLoader = null;
					this.recordList[index].paid_unpaid_temp = po.paid_unpaid;
					Swal.fire('Success', 'Updated successfully.', 'success');
				}, (error) => {
					this.showIsPaidLoader = null;
					if (error.status == 401) { return }
					Swal.fire('Error', "Something went wrong", 'error');
					po.paid_unpaid = po.paid_unpaid_temp;
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				po.paid_unpaid = po.paid_unpaid_temp;
			}
		})
	}

	getBillPdf(invoice: any) {
		let data: any = {
			"id": invoice.id,
			"currency": invoice.Currency
		};
		this.pdfDownloadLoader = invoice.id;
		this.apiService.getBillPdf(data).subscribe((res: any) => {
			let pdfData: any = res.body;
			this.pdfDownloadLoader = null;
			let blob = new Blob([pdfData], { type: "application/pdf" });
			let fileName = invoice.Invoice_no + "_" + invoice.CompanyName + ".pdf";
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.pdfDownloadLoader = null;
			if (error.status == 400) {
				const decodedString = String.fromCharCode(...new Uint8Array(error.error));
				this.toastr.error(decodedString, 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
			} else {
				this.toastr.error("Unable to download file", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
			}
		});
	}
}