import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";

@Component({
	selector: 'app-recover',
	templateUrl: './recover.component.html',
	styleUrl: './recover.component.css'
})
export class RecoverComponent implements OnInit {
	recoverForm!: FormGroup;
	year: number = new Date().getFullYear();
	ScreenHeight!: number;
	loadershow: boolean = false;
	forgotDiv=true;
	resetDiv=false;
	resetPasswordError=null;
	constructor(private apiService: ApiService,private router: Router,private formBuilder: FormBuilder) {
		
	}

	ngOnInit(): void {
		this.recoverForm = this.formBuilder.group({
			email: [null, [Validators.required,Validators.pattern(/^[a-zA-Z0-9]+[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z.]{2,5}$/)]]
		});
		setTimeout(() => {
			this.ScreenHeight=(window.innerHeight);
		}, 0);
	}

	get f() { return this.recoverForm.controls; }

	submitForm() {
		if(this.recoverForm.invalid){
			return;
		}
		this.loadershow = true;
		this.apiService.passwordReset(this.f.email.value).subscribe(
			data => {
				this.loadershow = false;
				this.forgotDiv=false;
				this.resetDiv=true;
			},
			(error)=>{
				this.resetPasswordError=error;
				this.loadershow = false;
				setTimeout(() => {
					this.resetPasswordError=null;
				}, 10000);
				setTimeout(() => {
					this.f.email.setValue(null);
				}, 6000);
			}
			);
	}
}
