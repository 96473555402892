import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';

import { Component, OnInit, ViewChild, NgZone, HostListener, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterOutlet } from "@angular/router";
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { password_validation } from '../shared/constants';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { MessagingService } from '../firebase/messaging.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit, AfterViewChecked {
	title = 'material-responsive-sidenav';
	@ViewChild(MatSidenav) sidenav!: MatSidenav;
	isMobile = true;
	isCollapsed = true;
	folloUpList: any = [];
	userTypeName: string | null = localStorage.getItem('userTypeName');
	userName: string = localStorage.getItem('firstName') + " " + localStorage.getItem('lastName');
	videoChannelAccess: string = "";
	channel: any;
	DispalylastReplyDetails = false;
	lastReplyDetails: any = { id: null, uniqueId: null };
	chatNotiList: any = [];
	message: any;
	subscription: any;
	notificationViewedsubscription!: Subscription;
	currYear: any = (new Date()).getFullYear();
	projectVersion: any = environment.version;
	followUpRefresh!: Subscription;
	followupLoader: boolean = false;
	doneLoader: any = null;
	displayToken!: string;
	globalEmergencyDefaultText = "Please call the senior doctor";
	handoverNotes: any = null;
	public tools2: object = {
		items: ['Bold', 'Italic']
	};
	handoverMaxLength = 150;
	handoverList: any = [];
	emergencyNoteForm!: FormGroup;
	deleteHandoverLoader: any = null;
	editHandoverLoader: any = null;
	createHandoverLoader: boolean = false;
	fetchHandoverLoader: boolean = false;
	sendHandoverLoader: boolean = false;
	followupcnt: number = 0;

	constructor(private messagingService: MessagingService, private changeRef: ChangeDetectorRef, private observer: BreakpointObserver, private toastr: ToastrService, private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private zone: NgZone, private fb: FormBuilder) {
		this.followUpRefresh = this.apiService.getFollowUpRefreshEvent().subscribe(() => {
			this.caseFollowUpList();
		})
		this.emergencyNoteForm = this.fb.group({
			emergencyNote: [this.globalEmergencyDefaultText, Validators.required],
		})
	}

	ngAfterViewChecked(): void {
		this.changeRef.detectChanges();
	}

	toggleMenu() {
		if (this.isMobile) {
			this.sidenav.toggle();
			this.isCollapsed = false; // On mobile, the menu can never be collapsed
		} else {
			this.sidenav.open(); // On desktop/tabs, the menu can never be fully closed
			this.isCollapsed = !this.isCollapsed;
		}
	}

	submitEmergencyNote() {
		var payload = {
			notes: this.emergencyNoteForm.get('emergencyNote')?.value,
		}
		this.apiService.postGlobalEmergencyNote(payload).subscribe((res) => {
			this.toastr.success("Emergency Note saved successfully.", 'Success', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true,
			});

			this.emergencyNoteForm.setValue({
				emergencyNote: this.globalEmergencyDefaultText,
			});

			this.closeEmergencyModel();
		}, (err) => {
			this.toastr.error("Something went wrong.", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		})
	}

	openEmergencyModel() {
		$("#GlobalEmergencyModal").modal("show");
	}

	closeEmergencyModel() {
		$("#GlobalEmergencyModal").modal("hide");
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.observer.observe(['(max-width: 800px)']).subscribe((screenSize) => {
				if (screenSize.matches) {
					this.isMobile = true;
				} else {
					this.isMobile = false;
				}
			});
			this.caseFollowUpList();
			this.messagingService.requestPermission().subscribe((token) => {
				localStorage.setItem('deviceToken', token);
				let userEmail: any = localStorage.getItem('userEmail');
				let fireString: any = localStorage.getItem('fireString');
				this.messagingService.register(userEmail, fireString, token)
			}, (error) => { });
		});

	}

	ngOnDestroy() {
		try {
			this.subscription.unsubscribe();
		} catch (error) { }
		try {
			this.notificationViewedsubscription.unsubscribe();
		} catch (error) { }
		try {
			this.followUpRefresh.unsubscribe();
		} catch (error) { }
	}

	logout() {
		this.apiService.logoutUser();
	}

	getFollowUpCount() {
		/* this.apiService.getFollowupCount().subscribe(
		(data:any) => {
		this.followupcnt = data.count;
		},
		(error) => {
		
		}
		); */
	}

	caseFollowUpList() {
		/* this.followupLoader = true;
		let params = {
		limit: 10,
		page: 0,
		search: "",
		type: "pending",
		}
		this.apiService.caseFollowUpList(params).subscribe(data => {
		this.followupLoader = false;
		this.folloUpList = data;
		}, (error) => {
		this.followupLoader = false;
		this.folloUpList = [];
		});
		this.getFollowUpCount(); */
	}

	doneFollowup(event_id: any, id: any, index: any) {
		Swal.fire({
			title: '',
			text: 'Are you sure you want to change the status?',
			showCancelButton: true,
			confirmButtonColor: '#DD6B55',
			confirmButtonText: 'Yes',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.doneLoader = index;
				this.apiService.doneFollowUp({ eventId: event_id, id: id }).subscribe(data => {
					this.doneLoader = null;
					this.toastr.success("Saved successfully", 'Success', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
					this.caseFollowUpList();
				}, (error) => {
					this.doneLoader = null;
					this.toastr.error("Something went wrong", 'Error', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}

	closeHandoverModel() {
		$("#handoverModal").modal("hide");
	}

	createHandover() {
		if (!this.handoverNotes) {
			this.toastr.error("Please enter something", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			$("#new_handover_notes").focus();
			return;
		}
		this.createHandoverLoader = true;
		this.apiService.createHandover({ handover_notes: this.handoverNotes }).subscribe(data => {
			this.createHandoverLoader = false;
			this.getHandoverList();
			this.handoverNotes = null;
			this.toastr.success("Added successfully", 'Success', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		}, (error) => {
			this.createHandoverLoader = false;
			this.toastr.error("Something went wrong", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	updateHandover(index: number) {
		if (!this.handoverList[index].handover_notes) {
			this.toastr.error("Please enter something", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			$("#handover_notes" + index).focus();
			return;
		}
		this.editHandoverLoader = index;
		this.apiService.updateHandover({ handover_notes: this.handoverList[index].handover_notes, eventid:this.handoverList[index].eventid }, this.handoverList[index].id).subscribe(data => {
			this.editHandoverLoader = null;
			this.getHandoverList();
			this.toastr.success("Updated successfully", 'Success', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		}, (error) => {
			this.editHandoverLoader = null;
			this.toastr.error("Something went wrong", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	openHandoverModel() {
		$("#handoverModal").modal("show");
		this.getHandoverList();
	}

	deleteHandover(index: number) {
		Swal.fire({
			title: '',
			text: 'Are you sure you want to delete handover note?',
			showCancelButton: true,
			confirmButtonColor: '#DD6B55',
			confirmButtonText: 'Yes',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.deleteHandoverLoader = index;
				this.apiService.deleteHandover(this.handoverList[index].id).subscribe(data => {
					this.deleteHandoverLoader = null;
					this.toastr.success("Notes deleted successfully", 'Success', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
					this.getHandoverList();
				}, (error) => {
					this.deleteHandoverLoader = null;
					this.toastr.error("Something went wrong", 'Error', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}

	toggleEditHandover(index: number) {
		if (this.handoverList[index].isEdit == undefined) {
			this.handoverList[index].isEdit = false;
		}
		this.handoverList[index].isEdit = !this.handoverList[index].isEdit;
	}

	getHandoverList() {
		this.fetchHandoverLoader = true;
		this.apiService.getAllHandovers().subscribe((data: any) => {
			this.fetchHandoverLoader = false;
			data = data.sort((a: any, b: any) => b.id - a.id);
			this.handoverList = data;
			for (let i = 0; i < this.handoverList.length; i++) {
				this.handoverList[i].isEdit = false;
			}
		}, (error) => {
			this.fetchHandoverLoader = false;
			this.handoverList = [];
		});
	}

	sendHandover() {
		Swal.fire({
			title: '',
			text: 'Are you sure you want to send handover?',
			showCancelButton: true,
			confirmButtonColor: '#DD6B55',
			confirmButtonText: 'Yes',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.sendHandoverLoader = true;
				this.apiService.sendHandover().subscribe(data => {
					this.sendHandoverLoader = false;
					Swal.fire('Success', 'Handover sent successfully', 'success');
					this.getHandoverList();
				}, (error) => {
					this.sendHandoverLoader = false;
					Swal.fire('Error', 'Something went wrong.', 'error');
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}

	GetDateStringFromPickerDate1(date: any) {
		if (date == "" || date == null || date == undefined) {
			return null;
		}
		var dateStr = date.toString();
		var dateObj = new Date(dateStr);
		var month = dateObj.getMonth() + 1;
		month = this.checkZero(month);
		var day = dateObj.getDate();
		day = this.checkZero(day);
		var year = dateObj.getFullYear();
		var hour = dateObj.getHours();
		hour = this.checkZero(hour);
		var min = dateObj.getMinutes();
		min = this.checkZero(min);
		var newDate = day + "-" + month + "-" + year + " " + hour + ":" + min;
		return newDate;
	}

	checkZero(data: any) {
		if (data != undefined && data.toString().length == 1) {
			data = "0" + data;
		}
		return data;
	}

	downloadSMRFiles() {
		var links = [
			'../assets/files/SMR.pdf'
		];
		links.forEach((link) => {
			var a = document.createElement('a');
			a.href = link;
			a.download = link.substring(link.lastIndexOf('/') + 1);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		});
	}
}
