import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';

import { Component, OnInit, ViewChild, NgZone, HostListener, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterOutlet } from "@angular/router";
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { password_validation } from '../shared/constants';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { MessagingService } from '../firebase/messaging.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Howl } from 'howler';

@Component({
	selector: 'app-view-call',
	templateUrl: './view-call.component.html',
	styleUrl: './view-call.component.scss'
})
export class ViewCallComponent implements OnInit {
	callId: any = null;
	data: any = null;
	fetchingDetails = false;

	constructor(private toastr: ToastrService, private apiService: ApiService, private activeRoute: ActivatedRoute) { }

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.callId = routeParams["id"];
			this.getDetails();
		});
	}

	getDetails() {
		this.fetchingDetails = true;
		this.apiService.callList({ id: this.callId }).subscribe({
			next: (res: any) => {
				this.fetchingDetails = false;
				this.data = res[0];
			}, error: (error) => {
				this.fetchingDetails = false;
				this.data = null;
			}
		});
	}

	unlinkCase(case_id: any, callId: any) {
		Swal.fire({
			title: 'Please confirm',
			icon: 'question',
			text: 'Are you sure you want to unlink case?',
			showCancelButton: true,
			confirmButtonColor: '#DD6B55',
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.value) {
				let payload = {
					"type_data": "remove",
					"from_data": callId,
					"to_data": [case_id]
				}
				this.apiService.calls_event(payload).subscribe({
					next: (data: any) => {
						this.toastr.success("Unlinked successfully", 'Success', {
							timeOut: 3000,
							tapToDismiss: true,
							closeButton: true
						});
						this.getDetails();
					}, error: (error) => {
						this.toastr.error("Something went wrong", 'Error', {
							timeOut: 3000,
							tapToDismiss: true,
							closeButton: true
						});
					}
				});
			}
		})
	}

	splitAgentName(name: string) {
		try {
			return name.split("+").join(" ");
		} catch (error) {
			return name;
		}
	}
}
