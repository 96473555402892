import { Component, OnInit, NgZone, OnDestroy, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from "@angular/router";
import { MessagingService } from '../firebase/messaging.service';
import { async } from 'rxjs/internal/scheduler/async';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
	user: any = { email: '', password: '' };
	year: number = new Date().getFullYear();
	ScreenHeight: number = 0;
	loadershow: boolean = false;
	loginErrorshow: boolean = false;
	checkCapsLock: boolean = false;
	loginFormErr: string = "";
	sessionExpsubscription!: Subscription;
	version = environment.version;
	/* apiKeys = [
		{ service_name: "masters service", key: "masters_api_url" },
		{ service_name: "mailbox", key: "mailbox_api_url" },
		{ service_name: "users", key: "users_api_url" },
		{ service_name: "case", key: "case_api_url" },
		{ service_name: "auth", key: "auth_api_url" },
		{ service_name: "File Upload", key: "file_upload_api_url" },
		{ service_name: "activity_logs", key: "activity_logs_api_url" }
	] */
	@ViewChild('bgVideo') bgVideo!: ElementRef;
	data: any = null;
	step: number = 1;
	showQR = false;
	qrToShow: any;
	auth: any = { otp: null };
	qrStep = 1;

	constructor(private toastr: ToastrService,private route: ActivatedRoute, private renderer: Renderer2, private apiService: ApiService, private router: Router, private messagingService: MessagingService, private zone: NgZone) { }
	ngAfterViewInit(): void {
		const media = this.bgVideo.nativeElement;
		this.renderer.setStyle(media, 'width', '100vw');
		this.renderer.setStyle(media, 'height', '100vh');
		this.renderer.setStyle(media, 'object-fit', 'cover');
		this.renderer.setStyle(media, 'position', 'fixed');
		this.renderer.setStyle(media, 'left', '0');
		this.renderer.setStyle(media, 'right', '0');
		this.renderer.setStyle(media, 'top', '0');
		this.renderer.setStyle(media, 'bottom', '0');
		this.renderer.setStyle(media, 'z-index', '-1');
		var isPlaying = media.currentTime > 0 && !media.paused && !media.ended && media.readyState > media.HAVE_CURRENT_DATA;
		if (!isPlaying) {
			media.muted = true;
			try {
				media.play();
			} catch (error) { }
		}
	}

	ngOnInit(): void {
		if (localStorage.getItem('tokenKey') != null && localStorage.getItem('tokenKey') != undefined) {
			this.gotoDashboard();
		}
		setTimeout(() => {
			this.ScreenHeight = (window.innerHeight);
		}, 0);
		this.sessionExpsubscription = this.apiService.getSessionExpMessage().subscribe(() => {
			this.loginErrorshow = true;
			this.loginFormErr = "Session Expired.";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		})
	}

	submitForm(user: any) {
		this.loadershow = true;
		this.apiService.loginAPI(user).subscribe((data: any) => {
			this.loadershow = false;
			this.data = data;
			if (this.data.enable2fa && this.data.first_login) {
				this.step = 2;
				this.showQR = true;
				this.generateQR();
			} else if (this.data.enable2fa && !this.data.first_login) {
				this.step = 2;
				this.showQR = false;
				this.qrStep = 2;
			} else {
				this.proceedLogin();
			}
		}, (error) => {
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Email-Id Or Password is incorrect.";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		}
		);
	}

	next() {
		this.qrStep = 2;
	}

	generateQR() {
		this.loadershow = true;
		let data: any = {
			id: this.data.main_user,
			user: this.data.emailId
		};
		this.apiService.generate_qr(data).subscribe((data: any) => {
			this.loadershow = false;
			this.createImageFromBlob(data);
		}, (error: any) => {
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Something went wrong";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		});
	}

	createImageFromBlob(image: Blob) {
		let reader = new FileReader();
		reader.addEventListener("load", () => {
			this.qrToShow = reader.result;
		}, false);
		if (image) {
			reader.readAsDataURL(image);
		}
	}

	verifyOTP() {
		if (!this.auth.otp) {
			this.toastr.warning("Please enter OTP", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		this.loadershow = true;
		let data: any = {
			id: this.data.main_user,
			otp: this.auth.otp
		};
		this.apiService.verifyGoogleAuthOTP(data).subscribe((data: any) => {
			this.loadershow = false;
			this.proceedLogin();
		}, (error: any) => {
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Invalid OTP";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		});
	}

	ngOnDestroy() {
		this.sessionExpsubscription.unsubscribe();
	}

	gotoDashboard() {
		const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/app/eventList';
		this.router.navigateByUrl(returnUrl);
	}

	proceedLogin() {
		localStorage.setItem('tokenKey', this.data["token"]);
		localStorage.setItem('refreshToken', this.data["refreshtoken"]);
		localStorage.setItem('OwnerId', this.data["userId"]);
		localStorage.setItem('userTypeName', this.data["userTypeName"]);
		localStorage.setItem('firstName', this.data["firstName"]);
		localStorage.setItem('lastName', this.data["lastName"]);
		localStorage.setItem('userAccess', this.data["userAccess"]);
		localStorage.setItem('userEmail', this.data["email"]);
		localStorage.setItem('userSign', this.data["user_sign"]);
		localStorage.setItem('click_to_call_token', this.data["click_to_call_token"]);
		localStorage.setItem('fireString', this.data["fireString"] ? this.data["fireString"] : "FirePass@88");
		/* let servicesList: any = this.data["servicesList"];
		for (let i = 0; i < servicesList.length; i++) {
			for (let j = 0; j < this.apiKeys.length; j++) {
				if (servicesList[i].service_name == this.apiKeys[j].service_name) {
					localStorage.setItem(this.apiKeys[j].key, servicesList[i].domain_url);
				}
			}
		} */
		this.gotoDashboard();
	}
}