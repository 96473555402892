<ol class="breadcrumb">
	<li class="breadcrumb-item" *ngFor="let item of breadcrumbList; let i = index"
		[class.active]="i===breadcrumbList.length-1">
		<a [routerLink]="item.path" *ngIf="i!==breadcrumbList.length-1">
			<b>{{ item.name }}</b>
		</a>
		<span *ngIf="i===breadcrumbList.length-1"><b>{{ item.name }}</b></span>
	</li>
</ol>
<div class="panel panel-default" *ngIf="moduleAccess=='Full' || moduleAccess == 'Readonly'">
	<div class="panel-body">
		<div class="app-view-header headerstyle">
			<div class="col-sm-4">Invoice</div>
			<div class="col-sm-4">
				<input id="searchBilling" placeholder="Search" [formControl]="searchControl" class="form-control" />
			</div>
			<div class="col-sm-4">
				<button *ngIf="moduleAccess=='Full'" class="btn btn-sm btn-success pull-right"
					[routerLink]="['/app/create-bill']">Create Invoice</button>
			</div>
		</div>
		<hr style="margin-top: 10px;margin-bottom: 10px;border: 0;border-top: 1px solid #8394a97d;" />
		<div class="table-responsive">
			<cdk-virtual-scroll-viewport itemSize="50"
				(scrolledIndexChange)="$event+7>=recordList.length?getList(false):null" class="infinite-viewport">
				<table class="table">
					<thead>
						<tr class="table_head">
							<th style="width: 10%;">Invoice No.</th>
							<th style="width: 10%;">Invoice Date</th>
							<th style="width: 15%;">Company</th>
							<th style="width: 15%;text-align: right;">Amount</th>
							<th style="width: 10%;">Due Date</th>
							<th style="width: 10%;">From Date</th>
							<th style="width: 10%;">To Date</th>
							<th style="width: 10%;">Total Months</th>
							<th style="width: 10%;">Action</th>
						</tr>
					</thead>
					<tbody>
						<tr *cdkVirtualFor="let item of recordList; let i = index" class="table_td">
							<td class="lable_overflow_table">{{item.Invoice_no}}</td>
							<td class="lable_overflow_table">{{item.Invoice_date}}</td>
							<td class="lable_overflow_table">{{item.CompanyName}}</td>
							<td class="lable_overflow_table" style="text-align: right;">
								<span>
									<strong *ngIf="item.Currency=='SGD'">S</strong>
									<i [ngClass]="getCurrencyIcon(item.Currency) + ' mr-sm'"></i>
								</span>
								<span class="mr-sm">{{ item.Amount }}</span>
								<ng-toggle [(ngModel)]="item.paid_unpaid"
									[color]="{ unchecked: '#bfcbd9', checked: '#2494f2' }"
									[fontColor]="{checked: '#ffffff',unchecked: '#000000'}"
									[switchColor]="{checked: '#4caf50',unchecked: '#f23022'}"
									[labels]="{'unchecked': 'Unpaid','checked': 'Paid'}" [width]="70"
									(change)="changeIsPaid(item,i)"></ng-toggle>
								<i *ngIf="showIsPaidLoader == i" class="fa fa-spinner fa-spin ml-sm"
									aria-hidden="true"></i>
							</td>
							<td class="lable_overflow_table">{{item.due_date}}</td>
							<td class="lable_overflow_table">{{item.fromDate}}</td>
							<td class="lable_overflow_table">{{item.toDate}}</td>
							<td class="lable_overflow_table">{{item.total_months}}</td>
							<td>
								<button *ngIf="moduleAccess=='Full'" class="btn btn-xs btn-primary mr-sm"
									id="editBtn{{i}}" title="Edit" [routerLink]="['/app/edit-bill',item.id]">
									<i class="fa fa-pencil" aria-hidden="true"></i>
								</button>
								<button *ngIf="moduleAccess=='Full'" class="btn btn-xs btn-danger mr-sm"
									id="deleteBtn{{i}}" title="Delete Invoice" (click)="deleteRecord(item.id,i)">
									<i class="fa fa-times" aria-hidden="true"></i>
								</button>
								<button *ngIf="moduleAccess=='Full'" class="btn btn-xs btn-success mr-sm" title="Download Invoice"
									id="btnInvoice{{item.id}}" (click)="getBillPdf(item)">
									<i *ngIf="pdfDownloadLoader != item.id" class="fa fa-cloud-download"
										aria-hidden="true"></i>
									<i *ngIf="pdfDownloadLoader == item.id" class="fa fa-spinner fa-spin"
										aria-hidden="true"></i>
								</button>
							</td>
						</tr>
						<tr *ngIf="!recordList.length && !isLoading">
							<td colspan="9" class="text-center text-bold">Data Not Found.</td>
						</tr>
						<tr *ngIf="isLoading">
							<td colspan="9" class="text-center text-bold">
								<i aria-hidden="true" class="fa fa-spinner fa-spin"></i>
								Loading more invoices.
							</td>
						</tr>
						<tr *ngIf="!isLoading && !isListFinished">
							<td colspan="9" class="text-center text-bold">
								<button title="Load More" (click)="getList(false)" class="btn btn-primary">
									Load More
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</cdk-virtual-scroll-viewport>
		</div>
	</div>
</div>
<div *ngIf="moduleAccess !=='Full' && moduleAccess != 'Readonly'">
	<div class="text-center">
		<h4>You don&apos;t have access to this section, please contact administrator</h4>
	</div>
</div>