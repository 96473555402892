import { Subscription, ReplaySubject, Subject, Observable, fromEvent, of } from 'rxjs';
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, Inject, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { ApiService } from '../api.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ToastrService } from 'ngx-toastr';
import { FileQueueObject, FileuploadService } from '../fileupload.service';
import * as _ from 'lodash';
declare var $: any;
import { debounceTime, map, distinctUntilChanged, filter, take, takeUntil } from "rxjs/operators";
import * as FileSaver from 'file-saver';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-eventlist',
	templateUrl: './eventlist.component.html',
	styleUrl: './eventlist.component.scss',
})
export class EventlistComponent implements OnInit, AfterViewInit {
	moduleAccess: any;
	unreadCnt: number = 0;
	unlinkedCnt: number = 0;
	alleventcnt: number = 0;
	openEventcnt: number = 0;
	followupcnt: number = 0;
	improving_cnt: number = 0;
	emergency_cnt: number = 0;
	code_blue_cnt: number = 0;
	unassigned_cnt: number = 0;
	not_replied_cnt: number = 0;
	routine_cnt: number = 0;
	marked_for_closure_cnt: number = 0;
	my_cases_cnt: number = 0;
	daily_handover_cnt: number = 0;
	reviewer_notes_cnt: number = 0;
	eventlist: any = [];
	isListFinished: boolean = false;
	isLoading: boolean = false;
	userTypeName: string | null = localStorage.getItem('userTypeName');
	tableparams: any = {
		search: "",
		page: 0,
		limit: 20,
		category: "Open",
		flag: null,
		column: "",
		sortType: "",
		isReplied: false,
		myCases: false,
		doctor: null,
		tags: [],
		mark_for_closure: false
	};

	formCtrlSub!: Subscription;
	searchControl = new FormControl();
	excelDownloadLoader = false;

	selectedMessage: any;
	channel: any;

	getOneDayBeforeDate() {
		var d = new Date();
		d.setDate(d.getDate() - 1);
		return d;
	}
	analyticsPayload: any = {
		fromDate: this.getOneDayBeforeDate(),
		toDate: new Date(),
		case_status: null,
		doctor_id: null
	}
	analyticsError: String = "";
	showLoader = false;
	statusList = [
		{ id: "Open", name: "Open" },
		{ id: "Reopen", name: "Reopen" },
		{ id: "Repatriation", name: "Repatriation" },
		{ id: "Closed", name: "Closed" }
	];
	tagMaster: any = [];
	flagList = [
		{ id: 5, name: "V. ICU", class: "fa fa-square mr-sm text-primary" },
		{ id: 1, name: "Unassigned", class: "fa fa-flag mr-sm text-black" },
		{ id: 4, name: "Close Watch", class: "fa fa-flag mr-sm text-danger" },
		{ id: 2, name: "T. Onboard", class: "fa fa-flag mr-sm text-warning" },
		{ id: 3, name: "Improving", class: "fa fa-flag mr-sm text-success" }
	];
	showIncomplete = true;

	constructor(private toastr: ToastrService, private apiService: ApiService, private router: Router, private activeRoute: ActivatedRoute, private location: Location, public uploader: FileuploadService, @Inject(DOCUMENT) private document: any) {
		this.apiService.getCallRefreshEvent().subscribe(() => {
			this.refreshCallList();
		});
	}

	loadScript(scriptUrl: string) {
		return new Promise(resolve => {
			const head = this.document.getElementsByTagName('head')[0];
			let themeLink = this.document.getElementById(scriptUrl) as HTMLScriptElement;
			if (themeLink) {
				themeLink.src = scriptUrl;
			} else {
				const scriptElement = document.createElement('script');
				scriptElement.src = scriptUrl;
				scriptElement.onload = resolve;
				head.appendChild(scriptElement);
			}
		});
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			if (routeParams["search"] != undefined) {
				this.tableparams.search = routeParams["search"];
				this.searchControl.setValue(routeParams["search"]);
				this.tableparams.page = 0;
				this.tableparams.tags = [];
				this.tableparams.mark_for_closure = false;
				this.tableparams.doctor = null;
				this.isListFinished = false;
				this.eventlist = [];
			}
			this.moduleAccess = JSON.parse(localStorage.getItem("userAccess")!).Events;
			this.loadMailCounts();
			this.allEventCount();
			this.all_triage_code_count();
			this.getEventList();
			this.fetchTags();
			this.getDoctorList("");
			this.getFollowUpCount();
			this.getCallCount();
			this.getCallTypeList();
		});
	}

	ngAfterViewInit(): void {
		this.formCtrlSub = this.searchControl.valueChanges.pipe(debounceTime(1000)).subscribe(newValue => {
			if (newValue) {
				this.router.navigate(['/app/eventList', newValue]);
			} else {
				this.router.navigate(['/app/eventList']);
			}
		});
	}

	tabClasses: any = [{ class: "active" }, { class: "" }, { class: "" }, { class: "" }, { class: "" }];
	getTabClass(tabNum: any) {
		return this.tabClasses[tabNum].class;
	};
	setActiveTab(tabNum: any) {
		for (var i = 0; i < this.tabClasses.length; i++) {
			this.tabClasses[i].class = "";
		};
		this.tabClasses[tabNum].class = "active";
		if (tabNum == 0) {

		} else if (tabNum == 1) {
			if (!this.formCtrlSubFollowup) {
				this.formCtrlSubFollowup = this.searchControlFollowup.valueChanges.pipe(debounceTime(1000)).subscribe(newValue => {
					this.tableparamsFollowup.page = 0;
					this.isListFinishedFollowup = false;
					this.folloUpList = [];
					this.getFollowUpList(true);
				});
				this.getFollowUpCount();
			}
		} else if (tabNum == 2) {
			this.refreshHandoverList();
		} else if (tabNum == 3) {
			if (!this.formCtrlSubQC) {
				this.formCtrlSubQC = this.searchControlQC.valueChanges.pipe(debounceTime(1000)).subscribe(newValue => {
					this.tableparamsQC.skip = 0;
					this.isListFinishedQC = false;
					this.qcList = [];
					this.getQCList(true);
				});
			}
		} else if (tabNum == 4) {
			this.getCallCount();
			if (!this.formCtrlSubCall) {
				this.formCtrlSubCall = this.searchControlCall.valueChanges.pipe(debounceTime(1000)).subscribe(newValue => {
					this.refreshCallList();
				});
			}
		}
	};
	getTabPaneClass(tabNum: any) {
		return "tab-pane " + this.tabClasses[tabNum].class;
	}

	fetchTags() {
		this.apiService.getTagSuggestions().subscribe((data: any) => {
			this.tagMaster = data;
		}, (error) => { });
	}

	loadMailCounts() {
		this.apiService.getMailBoxCount().subscribe((data: any) => {
			this.unreadCnt = data["unreadCount"];
			this.unlinkedCnt = data["unlinkCount"];
		}, (error) => { });
	}

	getFollowUpCount() {
		this.apiService.getFollowupCount().subscribe((data: any) => {
			this.followupcnt = data.count;
		}, (error) => { });
	}

	allEventCount() {
		this.apiService.allEventCount().subscribe((data: any) => {
			this.alleventcnt = data["allCase"];
			this.openEventcnt = data["OpenCase"];
		}, (error) => { });
	}

	all_triage_code_count() {
		this.apiService.all_triage_code_count().subscribe((data: any) => {
			this.improving_cnt = data["Improving"];
			this.code_blue_cnt = data["Code Blue"];
			this.unassigned_cnt = data["Not Assigned"];
			this.emergency_cnt = data["Code Red"];
			this.routine_cnt = data["Routine"];
			this.not_replied_cnt = data["Not replied"];
			this.marked_for_closure_cnt = data["Marked for closuer"];
			this.my_cases_cnt = data["My cases"];
			this.daily_handover_cnt = data["Daily handover"];
			this.reviewer_notes_cnt = data["Reviewer notes"];
		}, (error) => { });
	}

	fixDate(dateString: any) {
		var date = new Date(dateString.replace(/-/g, "/"));
		const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		var day = date.getDate();
		var month = monthNames[date.getMonth()];
		var year = date.getFullYear();
		var hour = date.getHours();
		var minutes = date.getMinutes() + "";
		var seconds = date.getSeconds();
		var ampm = hour >= 12 ? 'pm' : 'am';
		hour = hour % 12;
		hour = hour ? hour : 12;
		minutes = parseInt(minutes) < 10 ? '0' + minutes : minutes;
		var strTime = hour + ':' + minutes + ' ' + ampm;

		day = this.checkZero(day);
		month = this.checkZero(month);
		hour = this.checkZero(hour);
		minutes = this.checkZero(minutes);
		seconds = this.checkZero(seconds);

		let dateStr = day + ", " + month + " " + year + " " + strTime;
		return dateStr;
	}

	set_color(eve: any) {
		if (eve.flag === 4) { return { color: '#c71b09', 'border-color': '#c71b09', 'font-weight': 'bold' }; }
		else if (eve.flag === 2) { return { color: '#e76e1e', 'border-color': '#e76e1e', 'font-weight': 'bold' }; }
		else if (eve.flag === 5) { return { color: '#3f51b5', 'border-color': '#3f51b5', 'font-weight': 'bold' }; }
		else if (eve.flag === 3) { return { color: '#1b8d08', 'border-color': '#1b8d08', 'font-weight': 'bold' }; }
		else if (eve.flag === 1) { return { color: '#363f45', 'border-color': '#363f45', 'font-weight': 'bold' }; }
		else { return { color: '#363f45', 'border-color': '#363f45', 'font-weight': 'bold' }; }
	}

	flagOpenEventsOld(flag: any) {
		this.tableparams.flag = flag;
		this.tableparams.column = "";
		this.tableparams.sortType = "";
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList();
	}

	flagOpenEvents() {
		this.setActiveTab(0);
		this.tableparams.column = "";
		this.tableparams.sortType = "";
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList();
	}

	toggleNotReplied() {
		this.setActiveTab(0);
		this.tableparams.column = "";
		this.tableparams.sortType = "";
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList();
	}

	toggleMyCases() {
		this.tableparams.column = "";
		this.tableparams.sortType = "";
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList();
	}

	sortTableBy(sorby: any) {
		var sortType = "";
		this.tableparams.sortType == "asc" ? sortType = "desc" : sortType = "asc";
		this.tableparams.column = sorby;
		this.tableparams.sortType = sortType;
		this.tableparams.flag = null;
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList();
	}

	searchByTags() {
		this.isListFinished = false;
		this.tableparams.page = 0;
		this.eventlist = [];
		this.getEventList();
	}

	markForClosureChange() {
		this.tableparams.column = "";
		this.tableparams.sortType = "";
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList();
	}

	searchByDoctor() {
		this.isListFinished = false;
		this.tableparams.page = 0;
		this.eventlist = [];
		this.getEventList();
	}

	getEventList() {
		if (!this.isListFinished && !this.isLoading) {
			let params: any = {
				category: this.tableparams.category,
				limit: this.tableparams.limit,
				page: this.tableparams.page
			}
			if (this.tableparams.isReplied) {
				params["isReplied"] = 1;
			}
			if (this.tableparams.myCases) {
				params["myCases"] = 1;
			}
			if (this.tableparams.column != "") {
				params["column"] = this.tableparams.column;
				params["sortType"] = this.tableparams.sortType;
			}
			if (this.tableparams.search != "") {
				params["search"] = this.tableparams.search;
			}
			if (this.tableparams.flag != null) {
				params["flag"] = this.tableparams.flag;
			}
			if (this.tableparams.doctor != null) {
				params["doctor"] = this.tableparams.doctor;
			}
			if (this.tableparams.mark_for_closure) {
				params["mark_for_closure"] = this.tableparams.mark_for_closure;
			}
			this.isLoading = true;
			this.apiService.eventList(params, this.tableparams.tags).subscribe((data: any) => {
				this.isLoading = false;
				let events: any = data;
				if (events.length == this.tableparams.limit) {
					this.tableparams.page += 1;
				} else {
					this.tableparams.page += 1;
					this.isListFinished = true;
				}
				this.eventlist = this.eventlist.concat(events);
				for (var i = 0; i < this.eventlist.length; i++) {
					if (this.eventlist[i].symptoms !== null && typeof (this.eventlist[i].symptoms) === 'string') {
						try {
							this.eventlist[i].symptomsText = JSON.parse(this.eventlist[i].symptoms);
							this.eventlist[i].symptomsText = this.eventlist[i].symptomsText.join();
						} catch (error) {
							this.eventlist[i].symptomsText = "";
						}
					} else {
						this.eventlist[i].symptomsText = "";
					}
				}
			}, (error) => {
				this.isLoading = false;
				this.isListFinished = true;
			});
		}
	}

	showRow(uncomplienceCases: any[]) {
		try {
			if (this.tableparams.myCases) {
				if (this.showIncomplete) {
					if (uncomplienceCases.length) {
						return true;
					} else {
						return false;
					}
				} else {
					return true;
				}
			} else {
				return true;
			}
		} catch (error) {
			return true;
		}
	}

	checkZero(data: any) {
		if (data != undefined && data.toString().length == 1) {
			data = "0" + data;
		}
		return data;
	}

	openModal() {
		$("#dataModal").modal("show");
	}

	closeModal() {
		$("#dataModal").modal("hide");
	}

	clearDataModal() {
		this.analyticsPayload.fromDate = this.getOneDayBeforeDate();
		this.analyticsPayload.toDate = new Date();
		this.analyticsPayload.case_status = null;
		this.analyticsPayload.doctor_id = null;
		this.analyticsError = "";
	}

	getEventData() {
		let payload: any = {};
		if (this.analyticsPayload.fromDate) {
			payload.from = this.getDateTimeStringFromDateTimePicker("from");
		} else {
			this.analyticsError = "Select From Date Time.";
			return;
		}
		if (this.analyticsPayload.toDate) {
			payload.to = this.getDateTimeStringFromDateTimePicker("to");
		} else {
			this.analyticsError = "Select To Date Time.";
			return;
		}
		if (this.analyticsPayload.case_status && this.analyticsPayload.case_status.length) {
			payload.case_status = this.analyticsPayload.case_status;
		}
		if (this.analyticsPayload.doctor_id && this.analyticsPayload.doctor_id.length) {
			payload.doctor_id = this.analyticsPayload.doctor_id;
		}
		this.analyticsError = "";
		this.showLoader = true;
		this.apiService.eventsData(payload).subscribe(data => {
			this.showLoader = false;
			this.closeModal();
			this.clearDataModal();
			Swal.fire('Success', data.toString(), 'success');
		}, (error) => {
			this.showLoader = false;
			this.analyticsError = "Something went wrong.";
		});
	}

	getDateTimeStringFromDateTimePicker(type: any) {
		if (type == "from") {
			if (this.analyticsPayload.fromDate) {
				var date = new Date(this.analyticsPayload.fromDate);
			} else {
				return null;
			}
		} else {
			if (this.analyticsPayload.toDate) {
				var date = new Date(this.analyticsPayload.toDate);
			} else {
				return null;
			}
		}
		var month = date.getMonth() + 1;
		var day = date.getDate();
		var year = date.getFullYear();
		var hours = date.getHours();
		var minutes = date.getMinutes();
		var seconds = date.getSeconds();
		var ampm = hours >= 12 ? 'pm' : 'am';
		hours = hours % 12;
		hours = hours ? hours : 12;
		var dayStr = day < 10 ? '0' + day : day;
		var monthStr = month < 10 ? '0' + month : month;
		var hoursStr = hours < 10 ? '0' + hours : hours;
		var secondsStr = seconds < 10 ? '0' + seconds : seconds;
		var minutesStr = minutes < 10 ? '0' + minutes : minutes;
		var fromStr = year + "-" + monthStr + "-" + dayStr + " " + hoursStr + ':' + minutesStr + ":" + secondsStr + ' ' + ampm;
		return fromStr;
	}

	doctorList: any = [];
	getDoctorList(search: any) {
		var params: any = { page: 0, limit: 100 };
		if (search != "") {
			params["search"] = search;
		}
		this.apiService.userList(params).subscribe(data => {
			let docList: any = data;
			docList = docList.filter((obj: any) => obj.userType == 'Doctor');
			this.doctorList = docList;
		}, (error) => { });
	}

	doctorCustomSearch(term: string, item: any) {
		term = term.toLocaleLowerCase();
		let isNameMatched = false;
		if (item.doct_name != null) {
			isNameMatched = item.doct_name.toLocaleLowerCase().indexOf(term) > -1;
		}
		let isTypeMatched = false;
		return isNameMatched || isTypeMatched;
	}

	searchFunctionDoctor(event: any) {
		this.getDoctorList(event["term"]);
	}

	ngOnDestroy() {
		try {
			this.formCtrlSub.unsubscribe();
		} catch (error) { }
		try {
			this.formCtrlSubFollowup.unsubscribe();
		} catch (error) { }
		try {
			this.formCtrlSubQC.unsubscribe();
		} catch (error) { }
		try {
			this.formCtrlSubCall.unsubscribe();
		} catch (error) { }
		try {
			this.formCtrlSubCallCase.unsubscribe();
		} catch (error) { }
	}

	GetDateStringFromPickerDate1(date: any) {
		if (date == "" || date == null || date == undefined) {
			return null;
		}
		var dateStr = date.toString();
		var dateObj = new Date(dateStr);
		var month = dateObj.getMonth() + 1;
		month = this.checkZero(month);
		var day = dateObj.getDate();
		day = this.checkZero(day);
		var year = dateObj.getFullYear();
		var hour = dateObj.getHours();
		hour = this.checkZero(hour);
		var min = dateObj.getMinutes();
		min = this.checkZero(min);
		var newDate = day + "-" + month + "-" + year + " " + hour + ":" + min;
		return newDate;
	}

	GetDateStringFromReviewerNoteDate(date: any) {
		if (date == "" || date == null || date == undefined) {
			return null;
		}
		var dateStr = date.toString();
		dateStr = dateStr.replace("T", " ");
		dateStr = dateStr.replace(/-/g, "/");
		var dateObj = new Date(dateStr);
		var month = dateObj.getMonth() + 1;
		month = this.checkZero(month);
		var day = dateObj.getDate();
		day = this.checkZero(day);
		var year = dateObj.getFullYear();
		var hour = dateObj.getHours();
		hour = this.checkZero(hour);
		var min = dateObj.getMinutes();
		min = this.checkZero(min);
		var newDate = day + "-" + month + "-" + year + " " + hour + ":" + min;
		return newDate;
	}

	folloUpList: any = [];
	isListFinishedFollowup: boolean = false;
	isLoadingFollowup: boolean = false;
	tableparamsFollowup = {
		page: 0,
		limit: 20,
		type: "pending"
	};
	formCtrlSubFollowup!: Subscription;
	searchControlFollowup = new FormControl("");
	doneLoader: any = null;
	typeList = [
		{ id: "all", name: "All" },
		{ id: "pending", name: "Pending" }
	]

	searchByType() {
		this.isListFinishedFollowup = false;
		this.tableparamsFollowup.page = 0;
		this.folloUpList = [];
		this.getFollowUpList(true);
	}

	getFollowUpList(isSearch) {
		if (!this.isListFinishedFollowup && !this.isLoadingFollowup) {
			let params = {
				limit: this.tableparamsFollowup.limit,
				page: this.tableparamsFollowup.page,
				search: this.searchControlFollowup.value,
				type: this.tableparamsFollowup.type
			}
			this.isLoadingFollowup = true;
			this.apiService.caseFollowUpList(params).subscribe(data => {
				this.isLoadingFollowup = false;
				let folloUpList: any = data;
				for (let index = 0; index < folloUpList.length; index++) {
					if (folloUpList[index].updated_at != null) {
						folloUpList[index].updated_at = this.formatDate(folloUpList[index].updated_at);
					}
				}
				if (folloUpList.length == this.tableparamsFollowup.limit) {
					this.tableparamsFollowup.page += 1;
				} else {
					this.isListFinishedFollowup = true;
				}
				if (isSearch) {
					this.folloUpList = [];
				}
				this.folloUpList = this.folloUpList.concat(folloUpList);
			}, (error) => {
				this.isLoadingFollowup = false;
				this.isListFinishedFollowup = true;
			});
		}
	}

	doneFollowup(event_id, id, index) {
		Swal.fire({
			title: '',
			text: 'Are you sure you want to change the status?',
			showCancelButton: true,
			confirmButtonColor: '#DD6B55',
			confirmButtonText: 'Yes',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.doneLoader = index;
				this.apiService.doneFollowUp({ eventId: event_id, id: id }).subscribe(data => {
					this.doneLoader = null;
					this.toastr.success("Saved successfully", 'Success', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
					this.apiService.sendFollowUpRefreshEvent();
					this.folloUpList[index].follow_up_done_by = localStorage.getItem("firstName") + " " + localStorage.getItem("lastName");
					this.folloUpList[index].updated_at = this.formatDate(new Date());
					this.getFollowUpCount();
				}, (error) => {
					this.doneLoader = null;
					this.toastr.error("Something went wrong", 'Error', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}

	formatDate(dateVal) {
		var newDate = new Date(dateVal);
		var sMonth = this.padValue(newDate.getMonth() + 1);
		var sDay = this.padValue(newDate.getDate());
		var sYear = newDate.getFullYear();
		var sHour = newDate.getHours();
		var sMinute = this.padValue(newDate.getMinutes());
		var sAMPM = "AM";
		var iHourCheck = parseInt(sHour.toString());
		if (iHourCheck > 12) {
			sAMPM = "PM";
			sHour = iHourCheck - 12;
		} else if (iHourCheck === 0) {
			sHour = 12;
		}
		sHour = this.padValue(sHour);
		return sDay + "-" + sMonth + "-" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
	}

	padValue(value) {
		return (value < 10) ? "0" + value : value;
	}

	qcList: any = [];
	isListFinishedQC: boolean = false;
	isLoadingQC: boolean = false;
	tableparamsQC = {
		skip: 0,
		limit: 20
	};
	formCtrlSubQC!: Subscription;
	searchControlQC = new FormControl("");

	getQCList(isSearch) {
		if (!this.isListFinishedQC && !this.isLoadingQC) {
			let params = {
				limit: this.tableparamsQC.limit,
				skip: this.tableparamsQC.skip,
				search: this.searchControlQC.value
			}
			this.isLoadingQC = true;
			this.apiService.qcList(params).subscribe((data: any) => {
				this.isLoadingQC = false;
				let qcList: any = data;
				if (qcList.length == this.tableparamsQC.limit) {
					this.tableparamsQC.skip += this.tableparamsQC.limit;
				} else {
					this.isListFinishedQC = true;
				}
				if (isSearch) {
					this.qcList = [];
				}
				this.qcList = this.qcList.concat(qcList);
			}, (error) => {
				this.isLoadingQC = false;
				this.isListFinishedQC = true;
			});
		}
	}

	handoverList: any = [];
	isListFinishedHandOver: boolean = false;
	isLoadingHandOver: boolean = false;
	deleteHandoverLoaderI: any = null;
	deleteHandoverLoaderJ: any = null;
	tableparamsHandOver = {
		skip: 0,
		limit: 20
	};
	getTeamsHandover() {
		if (!this.isListFinishedHandOver && !this.isLoadingHandOver) {
			let params = {
				limit: this.tableparamsHandOver.limit,
				skip: this.tableparamsHandOver.skip
			}
			this.isLoadingHandOver = true;
			this.apiService.teamsHandoverList(params).subscribe((data: any) => {
				this.isLoadingHandOver = false;
				let hoList: any = data;
				if (hoList.length == this.tableparamsHandOver.limit) {
					this.tableparamsHandOver.skip += this.tableparamsHandOver.limit;
				} else {
					this.isListFinishedHandOver = true;
				}
				this.handoverList = this.handoverList.concat(hoList);
			}, (error) => {
				this.isLoadingHandOver = false;
				this.isListFinishedHandOver = true;
			});
		}
	}

	refreshHandoverList() {
		this.tableparamsHandOver.skip = 0;
		this.isListFinishedHandOver = false;
		this.handoverList = [];
		this.getTeamsHandover();
	}

	deleteHandover(i: number, j: number) {
		Swal.fire({
			title: '',
			text: 'Are you sure you want to delete handover note?',
			showCancelButton: true,
			confirmButtonColor: '#DD6B55',
			confirmButtonText: 'Yes',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.deleteHandoverLoaderI = i;
				this.deleteHandoverLoaderJ = j;
				this.apiService.deleteHandover(this.handoverList[i].notes[j].id).subscribe(data => {
					this.deleteHandoverLoaderI = null;
					this.deleteHandoverLoaderJ = null;
					this.toastr.success("Notes deleted successfully", 'Success', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
					this.refreshHandoverList();
				}, (error) => {
					this.deleteHandoverLoaderI = null;
					this.deleteHandoverLoaderJ = null;
					this.toastr.error("Something went wrong", 'Error', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}

	callList: any = [];
	callCounts: any = {};
	unlinked_calls_count: any = null;
	isListFinishedCall: boolean = false;
	isLoadingCall: boolean = false;
	tableparamsCall: any = {
		offset: 0,
		limit: 20,
		direction: null,
		call_type: null,
	};
	formCtrlSubCall!: Subscription;
	searchControlCall = new FormControl("");
	directionList = [
		{ val: "ClickToCall", name: "Outgoing" },
		{ val: "inbound", name: "Incoming" }
	];
	callTypeList = [
		/* Incoming */
		{ val: "call_answered_by_agent", name: "Answered", dir: "inbound" },
		{ val: "call_missed_by_agent", name: "Missed", dir: "inbound" },

		/* Outgoing */
		{ val: "call_hangup_by_agent_click_to_call", name: "Answered", dir: "ClickToCall" }
	]
	callTypeListDropdown: any[] = [];

	getCallTypeList() {
		let val = this.tableparamsCall.direction;
		if (!val) {
			val = "inbound";
		}
		this.callTypeListDropdown = this.callTypeList.filter((ct) => ct.dir == val);
	}

	getCallCount() {
		this.apiService.get_tata_tele_call_count().subscribe((data: any) => {
			this.callCounts = data;
		}, (error) => {
			this.callCounts = {};
		});
		this.apiService.get_tata_tele_unlinked_count().subscribe((data: any) => {
			this.unlinked_calls_count = data.unlinked_count;
		}, (error) => {
			this.unlinked_calls_count = null;
		});
	}

	totalCallsClicked() {
		this.setActiveTab(4);
		this.refreshCallList();
	}

	answeredCallsClicked() {
		this.setActiveTab(4);
		this.tableparamsCall.call_type = "call_answered_by_agent";
		this.call_typeFilterChanged();
	}

	missedCallsClicked() {
		this.setActiveTab(4);
		this.tableparamsCall.call_type = "call_missed_by_agent";
		this.call_typeFilterChanged();
	}

	outgoingCallsClicked() {
		this.setActiveTab(4);
		this.tableparamsCall.direction = "ClickToCall";
		this.directionFilterChanged();
	}

	refreshCallList() {
		this.tableparamsCall.offset = 0;
		this.tableparamsCall.direction = null;
		this.tableparamsCall.call_type = null;
		this.isListFinishedCall = false;
		this.callList = [];
		this.getCallList(true);
	}

	directionFilterChanged() {
		this.tableparamsCall.offset = 0;
		this.tableparamsCall.call_type = null;
		this.isListFinishedCall = false;
		this.callList = [];
		this.getCallTypeList();
		this.getCallList(true);
	}

	call_typeFilterChanged() {
		this.tableparamsCall.offset = 0;
		/* this.tableparamsCall.direction = null; */
		this.isListFinishedCall = false;
		this.callList = [];
		this.getCallList(true);
	}

	getCallList(isSearch) {
		if (!this.isListFinishedCall && !this.isLoadingCall) {
			let params: any = {
				limit: this.tableparamsCall.limit,
				offset: this.tableparamsCall.offset
			}
			if (this.searchControlCall.value) {
				params["searchval"] = this.searchControlCall.value;
			}
			if (this.tableparamsCall.direction != null) {
				params["direction"] = this.tableparamsCall.direction;
			}
			if (this.tableparamsCall.call_type != null) {
				params["call_type"] = this.tableparamsCall.call_type;
			}
			this.isLoadingCall = true;
			this.apiService.callList(params).subscribe((data: any) => {
				this.isLoadingCall = false;
				let callList: any = data;
				for (let i = 0; i < callList.length; i++) {
					try {
						callList[i].call_type = callList[i].call_type.toLowerCase();
					} catch (error) { }
					try {
						callList[i].connected_event = callList[i].connected_event == null ? [] : callList[i].connected_event;
					} catch (error) {

					}
				}
				if (callList.length == this.tableparamsCall.limit) {
					this.tableparamsCall.offset += 1;
				} else {
					this.isListFinishedCall = true;
				}
				if (isSearch) {
					this.callList = [];
				}
				this.callList = this.callList.concat(callList);
			}, (error) => {
				this.isLoadingCall = false;
				this.isListFinishedCall = true;
			});
		}
	}

	splitAgentName(name: string) {
		try {
			return name.split("+").join(" ");
		} catch (error) {
			return name;
		}
	}

	linkCallId: any = null;
	linkLoader = false;
	tableparamsCasesForCall = {
		page: 0,
		limit: 20,
		category: "Open"
	};
	eventlistForCalls: any = [];
	isListFinishedForCalls: boolean = false;
	isLoadingForCalls: boolean = false;
	formCtrlSubCallCase!: Subscription;
	searchControlCallCase = new FormControl("");
	selectedCases: any[] = [];

	openLinkCallCaseModal(call: any) {
		this.linkCallId = call.id;
		$("#linkCallModal").modal("show");
		if (!this.formCtrlSubCallCase) {
			this.formCtrlSubCallCase = this.searchControlCallCase.valueChanges.pipe(debounceTime(1000)).subscribe(newValue => {
				this.refreshEventListForCall();
			});
		}
	}

	clearLinkCallCaseModal() {
		this.linkCallId = null;
		this.selectedCases = [];
		for (let i = 0; i < this.eventlistForCalls.length; i++) {
			this.eventlistForCalls[i].checked = false;
		}
		$("#linkCallModal").modal("hide");
	}

	refreshEventListForCall() {
		this.tableparamsCasesForCall.page = 0;
		this.isListFinishedForCalls = false;
		this.eventlistForCalls = [];
		this.getEventListForLinkCall(true);
	}

	getEventListForLinkCall(isSearch) {
		if (!this.isListFinishedForCalls && !this.isLoadingForCalls) {
			let params: any = {
				category: this.tableparamsCasesForCall.category,
				limit: this.tableparamsCasesForCall.limit,
				page: this.tableparamsCasesForCall.page
			}
			if (this.searchControlCallCase.value) {
				params["search"] = this.searchControlCallCase.value;
			}
			this.isLoadingForCalls = true;
			this.apiService.eventList(params, []).subscribe((data: any) => {
				this.isLoadingForCalls = false;
				let events: any = data;
				for (let i = 0; i < events.length; i++) {
					events[i].checked = this.selectedCases.indexOf(events[i].id) >= 0;
				}
				if (events.length == this.tableparamsCasesForCall.limit) {
					this.tableparamsCasesForCall.page += 1;
				} else {
					this.tableparamsCasesForCall.page += 1;
					this.isListFinishedForCalls = true;
				}
				if (isSearch) {
					this.eventlistForCalls = [];
				}
				this.eventlistForCalls = this.eventlistForCalls.concat(events);
				for (var i = 0; i < this.eventlistForCalls.length; i++) {
					if (this.eventlistForCalls[i].symptoms !== null && typeof (this.eventlistForCalls[i].symptoms) === 'string') {
						try {
							this.eventlistForCalls[i].symptomsText = JSON.parse(this.eventlistForCalls[i].symptoms);
							this.eventlistForCalls[i].symptomsText = this.eventlistForCalls[i].symptomsText.join();
						} catch (error) {
							this.eventlistForCalls[i].symptomsText = "";
						}
					} else {
						this.eventlistForCalls[i].symptomsText = "";
					}
				}
			}, (error) => {
				this.isLoadingForCalls = false;
				this.isListFinishedForCalls = true;
			});
		}
	}

	caseSelected(id: any, checked: any) {
		if (checked) {
			this.selectedCases.push(id);
		} else {
			try {
				this.selectedCases.splice(this.selectedCases.indexOf(id), 1);
			} catch (error) { }
		}
	}

	linkCallCase() {
		this.selectedCases = [...new Set(this.selectedCases)];
		let payload = {
			"type_data": "add",
			"from_data": this.linkCallId,
			"to_data": this.selectedCases
		}
		this.linkLoader = true;
		this.apiService.calls_event(payload).subscribe((data: any) => {
			this.linkLoader = false;
			this.toastr.success("Linked successfully", 'Success', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.clearLinkCallCaseModal();
			this.refreshCallList();
		}, (error) => {
			this.toastr.error("Something went wrong", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.linkLoader = false;
		});
	}

	unlinkCase(case_id: any, callId: any) {
		Swal.fire({
			title: 'Please confirm',
			icon: 'question',
			text: 'Are you sure you want to unlink case?',
			showCancelButton: true,
			confirmButtonColor: '#DD6B55',
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.value) {
				let payload = {
					"type_data": "remove",
					"from_data": callId,
					"to_data": [case_id]
				}
				this.apiService.calls_event(payload).subscribe((data: any) => {
					this.toastr.success("Unlinked successfully", 'Success', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
					this.refreshCallList();
				}, (error) => {
					this.toastr.error("Something went wrong", 'Error', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			}
		})
	}

	vessellist: any[] = [];
	selectedNumber: any = null;
	selectedVessel: any = null;
	saveContactLoader = false;
	fetchingVessels = false;
	getVessels() {
		this.fetchingVessels = true;
		this.apiService.fetchVessels().subscribe((data: any) => {
			this.fetchingVessels = false;
			this.vessellist = data;
		}, (error) => {
			this.fetchingVessels = false;
			this.vessellist = [];
			this.toastr.error("Something went wrong", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	openVesselListModal(customer_no_with_prefix: any, caller_id_number: any) {
		this.selectedNumber = {
			customer_no_with_prefix: customer_no_with_prefix,
			caller_id_number: caller_id_number
		};
		$("#vesselModal").modal("show");
		if (!this.vessellist.length) {
			this.getVessels();
		}
	}

	clearVesselModal() {
		this.selectedNumber = null;
		this.selectedVessel = null;
		this.saveContactLoader = false;
		$("#vesselModal").modal("hide");
	}

	saveContact() {
		if (!this.selectedVessel || !this.selectedNumber) {
			this.toastr.error("Please select vessel", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		let number = this.selectedNumber.caller_id_number;
		if (this.selectedNumber.customer_no_with_prefix) {
			number = this.selectedNumber.customer_no_with_prefix + number;
		}

		let payload = {
			number: number,
			vessel_id: this.selectedVessel
		}
		this.saveContactLoader = true;
		this.apiService.saveContact(payload).subscribe((data: any) => {
			this.saveContactLoader = false;
			this.toastr.success("Saved successfully", 'Success', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.clearVesselModal();
			this.refreshCallList();
		}, (error) => {
			this.saveContactLoader = false;
			this.toastr.error("Something went wrong", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	unlinkVesselLoader = false;
	unlinkVesselFromNumber(vessel_id: any, customer_no_with_prefix: any, caller_id_number: any) {
		Swal.fire({
			title: 'Please confirm',
			icon: 'question',
			text: 'Are you sure you want to unlink vessel?',
			showCancelButton: true,
			confirmButtonColor: '#DD6B55',
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.value) {
				let number = caller_id_number;
				if (customer_no_with_prefix) {
					number = customer_no_with_prefix + number;
				}
				let payload = {
					"vessel_id": vessel_id,
					"number": number
				}
				this.unlinkVesselLoader = true;
				this.apiService.unlinkVesselNumber(payload).subscribe((data: any) => {
					this.unlinkVesselLoader = false;
					this.toastr.success("Unlinked successfully", 'Success', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
					this.refreshCallList();
				}, (error) => {
					this.toastr.error("Something went wrong", 'Error', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
					this.unlinkVesselLoader = false;
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	summarizeLoader: any = null;
	summarizeCall(id: any) {
		Swal.fire({
			title: 'Please confirm',
			icon: 'question',
			text: 'Are you sure you want to summarize this call?',
			showCancelButton: true,
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.value) {
				this.summarizeLoader = id;
				this.apiService.summarizeCall(id).subscribe({
					next: (data: any) => {
						this.summarizeLoader = null;
						this.refreshCallList();
						let msg = "Summarization request sent successfully";
						if (typeof data == "string") {
							msg = data;
						} else {
							try {
								if (typeof data.status == "string") {
									msg = data.status;
								}
							} catch (error) { }
						}
						this.toastr.success(msg, 'Success', {
							timeOut: 3000,
							tapToDismiss: true,
							closeButton: true
						});

					}, error: (error) => {
						let msg = "Something went wrong";
						if (typeof error.error == "string") {
							msg = error.error;
						} else {
							try {
								if (typeof error.error.status == "string") {
									msg = error.error.status;
								}
							} catch (error) { }
						}
						this.toastr.error(msg, 'Error', {
							timeOut: 3000,
							tapToDismiss: true,
							closeButton: true
						});
						this.summarizeLoader = null;
					}
				});
			}
		})
	}

	summaryData: any = null;
	viewSummary(data: any) {
		this.summaryData = data;
		$("#summaryModalEventList").modal("show");
	}

	closeSummaryModal() {
		this.summaryData = null;
		$("#summaryModalEventList").modal("hide");
	}
}
