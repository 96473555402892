import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { environment } from '../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AppInterceptorService implements HttpInterceptor {
	isRefreshingToken = false;
	
	constructor(private router: Router,private apiService: ApiService) {}
	
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.apiService.checkTokenExpiry();
		let headers:any = {"x-api-key":environment.API_KEY};
		if(req.url.indexOf("backend-content-storage.s3.amazonaws.com")>=0){
			let token:any = localStorage.getItem('tokenKey');
			if(token){
				headers["x-auth-token"] = token;
			}
		}else if(req.url.indexOf("refresh")==-1){
			let token:any = localStorage.getItem('tokenKey');
			if(token){
				headers["x-auth-token"] = token;
				headers["Authorization"] = token;
			}
		}else{
			let refreshToken:any = localStorage.getItem('refreshToken');
			if(refreshToken){
				headers["refresh_token"] = refreshToken;
			}
		}
		const httpRequest = req.clone({
			setHeaders:headers
		})
		return next.handle(httpRequest).pipe( tap(() => {},(err: any) => {
			if (err instanceof HttpErrorResponse) {
				if(err.status == 401){
					if(req.url.indexOf("refresh") >=0){
						this.apiService.logoutUser();
					}else if(req.url.indexOf("login") >=0){

					}else{
						this.apiService.getRefreshToken();
					}
				}
			}
		}));
	}
}
