import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MessagingService } from './firebase/messaging.service';
import { SafePipe } from './safe.pipe';
import { HeadnavbarComponent } from './headnavbar/headnavbar.component';
import { ToastrModule } from 'ngx-toastr';
import { EventlistComponent } from './eventlist/eventlist.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedPipesModule } from './shared-pipes.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { AddEventComponent } from './add-event/add-event.component';
/* import { AddMailFolderComponent } from './add-mail-folder/add-mail-folder.component'; */
import { CreateEventComponent } from './create-event/create-event.component';
import { EventallComponent } from './eventall/eventall.component';
import { RecoverComponent } from './recover/recover.component';
import { RefreshComponent } from './refresh/refresh.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SetupComponent } from './question-master-lazy/setup/setup.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppInterceptorService } from './app-interceptor.service';
import { Router, UrlSerializer } from '@angular/router';
import { UrlSerializerService } from './url-serializer.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DATETIME_FORMATS, MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { TraceService, createErrorHandler } from '@sentry/angular-ivy';
import { ResetPasswordNewComponent } from './reset-password-new/reset-password-new.component';
import { BillingListComponent } from './billing/billing-list/billing-list.component';
import { BillingCreateComponent } from './billing/billing-create/billing-create.component';
import { NgToggleModule } from 'ng-toggle-button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavbarComponent } from './navbar/navbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
	declarations: [
		AppComponent,
		SafePipe,
		LoginComponent,
		HeadnavbarComponent,
		EventlistComponent,
		AddEventComponent,
		/* AddMailFolderComponent, */
		CreateEventComponent,
		EventallComponent,
		RecoverComponent,
		RefreshComponent,
		ResetPasswordComponent,
		SetupComponent,
		ResetPasswordNewComponent,
		BillingListComponent,
		BillingCreateComponent,
		NavbarComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		ToastrModule.forRoot(),
		NgSelectModule,
		SharedPipesModule,
		BrowserAnimationsModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		MatRadioModule,
		MatAutocompleteModule,
		ScrollingModule,
		NgbModule,
		MatDatepickerModule,
		MatDatetimepickerModule,
		MatNativeDateModule,
		MatNativeDatetimeModule,
		NgToggleModule.forRoot(),
		MatTooltipModule,
		MatToolbarModule,
		MatMenuModule
	],
	providers: [
		{
			provide: ErrorHandler,
			useValue: createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => { },
			deps: [TraceService],
			multi: true,
		},
		MessagingService,
		{
			provide: UrlSerializer,
			useClass: UrlSerializerService
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppInterceptorService,
			multi: true
		},
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
		{
			provide: MAT_DATETIME_FORMATS,
			useValue: {
				parse: {},
				display: {
					dateInput: {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
					},
					monthInput: {
						month: 'long',
					},
					datetimeInput: {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
						hour: '2-digit',
						minute: '2-digit',
					},
					timeInput: {
						hour: '2-digit',
						minute: '2-digit',
					},
					monthYearLabel: {
						year: 'numeric',
						month: 'short',
					},
					dateA11yLabel: {
						year: 'numeric',
						month: 'long',
						day: 'numeric',
					},
					monthYearA11yLabel: {
						year: 'numeric',
						month: 'long',
					},
					popupHeaderDateLabel: {
						weekday: 'short',
						month: 'short',
						day: '2-digit',
					},
				},
			}
		},
		MatNativeDatetimeModule
	],
	exports: [
		SafePipe
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
